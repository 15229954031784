<template>
  <div>&nbsp;</div>
</template>

<script>

import { HmacSHA256 } from 'crypto-js';

export default {
  name: "LogoutComponent",

  computed: {
      // ...mapGetters(['getUser']),
      isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
      user : function(){ return this.$store.getters.getUser},
      token : function(){ return this.$store.getters.getToken},
      website : function(){ return this.$store.getters.getWebsite}
  },

  mounted: function () {
    this.$nextTick(function () {

        var logoutUrl = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/home';

        var isPushing = true;
        var hasMessage = true;

        if (this.isLoggedIn) {
          if (this.user.genebase_login) {
            logoutUrl = process.env.VUE_APP_API_MASTER_URL_JITAN + 'auth/logout?region_id=' + this.$route.params.region_id + '&website_id=' + this.$route.params.website_id;
            isPushing = false;
            hasMessage = false;

          }
          else {

            if (this.$route.params.sso) {

              // Do nothing.

            }

            else {

              hasMessage = false;
              isPushing = false;              

              var d = new Date();
              var now = Math.floor(d.getTime() / 1000);

              const data = this.user.wordpress_user_id + '.' + now;
              const key = process.env.VUE_APP_HASH_SECRET;
              const hmacResult = this.user.wordpress_user_id + '.' + HmacSHA256(data, key).toString() + '.' + now;

              logoutUrl = '//' + (this.regionId == 'MPUS' ? this.website.fqdn.replace('www', 'beta2022') : this.website.fqdn) + '/sso?f=logout&h=' + hmacResult + '&r=' + encodeURIComponent(window.location.href);

            }
            
          }

        }

        this.$store.dispatch('setUser', {});
        this.$store.dispatch('setActivation', {});
        this.$store.dispatch('setToken', null);

        if (isPushing) {
          this.$router.push(logoutUrl);
        }
        else {
          window.location.href = logoutUrl;
        }

        if (hasMessage) {
          this.$message.success('Thank you. You have logged out successfully.', 4.0);
        }

    });
  },

  data() {
    return {
      regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
      websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
    }
  }

};
</script>