<template>
    <div>
        
        <a-row>

            <a-col class="app-payment">
                
                <FormPayment />

            </a-col>
            
        </a-row>
    </div>
</template>


<script>
    
    // import ActivateStep from './ActivateStep.vue'
    import FormPayment from './forms/FormPayment.vue'

    export default {
        name: 'PaymentComponent',
        components: {
            FormPayment
        }
    }
</script>

<style>
.app-payment {
  max-width:480px;
  margin:0 auto;
  padding:30px 20px 0;
}
.app-payment.wide {
  max-width:920px;
}
</style>