<template>
    <div></div>
</template>

<script>
    
    export default {
        name: 'HeaderGvus',
        head: {
            script: [
                {
                    src: 'https://www.googletagmanager.com/gtag/js?id=AW-826425191',
                    async: true
                },
                {
                    inner: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-826425191');"
                },
                {
                    inner: "gtag('event', 'conversion', { 'send_to': 'AW-826425191/x3Y4CIrk7csBEOf-iIoD', 'transaction_id': ''});"
                }
            ]
        },
    }
</script>