<template>
    <a-card :bordered="false" v-if="!isPendingTestEmpty">

        <div class="card-title" v-if="!isPendingTestEmpty">{{ t('Tests in Progress') }} <a-spin :spinning="isPendingTestLoading" /></div>

        <div v-if="isPendingTestReady">
                
            <div class="empty-wrapper" v-if="isPendingTestEmpty" style="display:none;">
                <a-empty>
                    <span slot="description">   
                        <div class="empty-title">{{ t('You have no tests in progress.') }}</div>
                    </span>
                </a-empty>
            </div>

            <div class="pending-wrapper" v-if="!isPendingTestEmpty">

                <div :data-order_id="item.client_order_id" v-for="item in pendingTestList" :key="item.client_order_id" class="order-list">

                    <div class="left">

                        <!-- <span class="circ"><font-awesome-icon icon="user-secret" /></span> -->

                        <div class="left-icon">

                            <img :src="item.acct_console_icon" />

                            <!-- <a-avatar slot="avatar">
                                <a-icon type="deployment-unit" size="large" />
                            </a-avatar> -->

                        </div>

                    </div>
                    
                    <div class="right">

                        <div class="right-content">
                            
                            <h4><span v-if="item.is_relationship">{{ item.collection_category }} </span>{{ item.label }}</h4>
                            
                            <div slot="description">
                                <div class="client-order-id">{{ t('Test ID') }}: {{ item.client_order_full_id }}</div>

                                <span class="issue-tag" :data-recollection_order_id="item.client_order_id" style="display:none;">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="triangle-exclamation" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-triangle-exclamation fa-fw decorative"><path fill="currentColor" d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z" class=""></path></svg>
                                    {{ t('Sample Issue') }}
                                </span>
                                <span class="replaced-pill" :data-replaced_order_id="item.client_order_id" style="display:none;">Replaced</span>

                                <span :data-recollection_order_id="item.client_order_id" class="action-wrapper" style="display:none;">
                                    <a-button :data-order_id="item.client_order_id" type="primary" @click="handleReplaceKitClick" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                        {{ t('Replace Kit') }}
                                    </a-button>
                                </span>

                                <!--
                                <div class="actions">   
                                    <a-button :data-key="key" type="primary"  @click="item.visible = !item.visible">
                                        View More
                                        <a-icon type="right" v-show="!item.visible" />
                                        <a-icon type="down" v-show="item.visible" />
                                    </a-button>
                                </div>
                                -->
                            </div>

                            <div :data-steps_order_id="item.client_order_id" class="steps-wrapper">
                                <a-steps v-if="item.test_status_code == 'waiting'" size="small" labelPlacement="vertical" :direction="stepsDirection">
                                    <a-step status="finish" title="Test Activated" />
                                    <a-step status="wait" title="Sample Received" />
                                    <a-step status="wait" title="Test in Progress" />
                                    <a-step status="wait" title="Results Available" />
                                </a-steps>
                                <a-steps v-if="item.test_status_code == 'isolation' || item.test_status_code == 'testing'" size="small" labelPlacement="vertical" :direction="stepsDirection">
                                    <a-step status="finish" title="Test Activated" />
                                    <a-step status="finish" title="Sample Received" />
                                    <a-step status="finish" title="Test in Progress" />
                                    <a-step status="wait" title="Results Available" />
                                </a-steps>
                            </div>

                            <div class="console-message">

                                <div :data-recollection_order_id="item.client_order_id" class="recollection-wrapper" style="display:none;">
                                    <div v-html="completeReplacementKitText"></div>
                                </div>

                                <div :data-replaced_order_id="item.client_order_id" class="recollection-wrapper" style="display:none;">
                                    {{ t('Your recollection kit is on its way. Please follow the instructions provided in the kit carefully when recollecting your sample.') }}
                                </div>                        
                                
                                <div :data-report_order_id="item.client_order_id" class="console-message-wrapper" style="display:none;"></div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        
        </div>
        
    </a-card>
</template>

<script>    
    
    import axiosUserInstance from '../../apiUser'
    import moment from 'moment'

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

  export default {

    name: 'PendingOrders',

    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
        token : function(){ return this.$store.getters.getToken},
        website : function(){ return this.$store.getters.getWebsite},
        completeReplacementKitText() {
            return t('There is an issue with %s samples. Please complete the kit replacement form.', this.whoseSample);
        }
    },

    mounted: function() {
        this.getPendingOrderList();
        this.$nextTick(function () {
            this.onChangeScreensize();
        })
    },

    created() {
        window.addEventListener("resize", this.onChangeScreensize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onChangeScreensize);
    },

    methods: {

        onChangeScreensize() {
            var collapsedWidth = 560;
            this.stepsDirection = window.innerWidth <= collapsedWidth ? 'vertical' : 'horizontal';
        },

        async getPendingOrderList() {

            // console.log('checking pending list.');

            this.pendingTestList = [];
            
            this.isPendingTestEmpty = true;

            this.isPendingTestLoading = true;

            let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/reports/pending', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('res');
            // console.log(res);

            this.isPendingTestLoading = false;
            this.isPendingTestReady = true;

            let list = Object.keys(res.data.reports).map(key => {
                return res.data.reports[key];
            });

            list.forEach(element => {
                this.pendingTestList.push({ ...element, visible: false});
            });

            var numOrders = this.pendingTestList.length;
    
            // console.log('pendingTestList');
            // console.log(this.pendingTestList);
            // console.log('numOrders: ' + numOrders);

            if (numOrders > 0) {

                this.isPendingTestEmpty = false;

                for (var i=0; i<numOrders; i++) {

                    this.isOrderExpanded[i] = false;

                    let res2 = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.pendingTestList[i].client_order_id + '/patients', {
                        headers: { 'Authorization': 'Bearer ' + this.token }
                    });

                    var patients = res2.data.patients;

                    // console.log('patients');
                    // console.log(patients);

                    if (patients.length > 0) {

                        var relations = [];
                        var is_recollection_required = false;
                        var is_recollection_sample_kit_received_on = false;
                        var is_recollection_info_submitted_on = false;

                        for (var k=0; k<patients.length; k++) {
                            if (patients[k].is_recollection_required === true) {
                                is_recollection_required = true;
                                if (patients[k].component_label == 'Individual 1') {
                                    relations.push('your');
                                }
                                else {
                                    var who = 'the ' + patients[k].component_label;
                                    relations.push(who.replace(' 1', "'s"));
                                }
                            }
                            else {
                                if (patients[k].recollection_info_submitted_on !== null) {
                                    is_recollection_info_submitted_on = true;
                                }
                                if (patients[k].recollection_sample_kit_received_on !== null) {
                                    is_recollection_sample_kit_received_on = true;
                                }
                            }
                        }

                        // console.log('relations');
                        // console.log(relations);
                        // console.log(relations.length);

                    }

                    // console.log('is_recollection_required -> ' + (is_recollection_required ? 'true' : 'false'));
                    // console.log('is_recollection_info_submitted_on -> ' + (is_recollection_info_submitted_on ? 'true' : 'false'));
                    // console.log('is_recollection_sample_kit_received_on -> ' + (is_recollection_sample_kit_received_on ? 'true' : 'false'));

                    // var numReports = this.pendingTestList[i].reports.length;

                    // console.log('numReports: ' + numReports);

                    let res3 = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.pendingTestList[i].client_order_id + '/console-vars', {
                        headers: { 'Authorization': 'Bearer ' + this.token }
                    });

                    if (res3.data.console_vars) {

                        var console_vars = res3.data.console_vars;

                        if (relations.length == 0) {
                            document.querySelectorAll('[data-steps_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                element.style.display = 'block';
                            });
                        }
                        else {
                            if (relations.length == 1) {
                                this.whoseSample = relations[0];
                            }
                            else if (relations.length == 2) {
                                this.whoseSample = relations.join(' ' + t('and') + ' ');
                            }
                            else {
                                const l = relations.length;
                                const lastItem = relations[l - 1];
                                const remainingItems = relations.slice(0, l - 1);
                                this.whoseSample = remainingItems.join(', ') + ', ' + t('and') + ' ' + lastItem;
                            }
                        }

                        if (is_recollection_required === true) {

                            document.querySelectorAll('[data-recollection_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                element.style.display = 'inline-block';
                            });

                            document.querySelectorAll('[data-replaced_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                element.style.display = 'none';
                            });

                            document.querySelectorAll('[data-steps_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                element.style.display = 'none';
                            });

                        }

                        else {

                            if (is_recollection_sample_kit_received_on === true) {

                                if (console_vars) {

                                    document.querySelectorAll('[data-report_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                        element.style.display = 'block';
                                        element.innerHTML = console_vars.message;
                                    });

                                }

                            }
            
                            else {

                                if (is_recollection_info_submitted_on === true) {

                                    document.querySelectorAll('[data-steps_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                        element.style.display = 'none';
                                    });

                                    document.querySelectorAll('[data-recollection_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                        element.style.display = 'none';
                                    });
                                    
                                    document.querySelectorAll('[data-replaced_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) {
                                        element.style.display = 'block';
                                    });

                                }

                                else if (console_vars) {

                                    document.querySelectorAll('[data-report_order_id="' + this.pendingTestList[i].client_order_id + '"]').forEach(function(element) { 
                                        element.style.display = 'block';
                                        var pretext = '';

                                        if (console_vars.expected_results_date.is_visible == true) {

                                            pretext = '<div class="pretext-section"><strong>Last updated:</strong> ' + moment().format('MMMM DD YYYY, hh:mm A') + ' Pacific Standard Time</div>' + (console_vars.expected_results_date.received_on !== null ? '<div class="pretext-section"><strong>Date samples received:</strong> ' + moment(console_vars.expected_results_date.received_on).format('MMM DD, YYYY') + '</div>' : '') + '<div class="pretext-section"><strong>Current status:</strong> <span style="white-space:pre-wrap;">' + console_vars.status_label + '</span></div>' + (console_vars.expected_results_date.received_on !== null ? '<div class="pretext-section"><strong>Estimated completion date:</strong> On or before 11:00PM Pacific Standard Time (PST) ' + (console_vars.expected_results_date.type == 'range' ? moment(console_vars.expected_results_date.date[1]).format('MMM DD, YYYY') : moment(console_vars.expected_results_date.date).format('MMM DD, YYYY')) + '*</div>' : '') + (console_vars.return_tracking.tracking_no !== null ? '<div class="pretext-section"><strong>Track your return shipment:</strong> ' + console_vars.return_tracking.tracking_url + '</div>' : '') + (console_vars.expected_results_date.received_on !== null ? '<div class="pretext-section">* The date provided is an estimation. Poor sample quality can result in increased testing time, recollection or no results. We will contact you if there are any problems with your sample.</div>' : '') + '<hr />';

                                        }

                                        element.innerHTML = pretext + console_vars.message;
                                    });

                                }

                            }

                        }

                        
                    }

                }

            }

            // setTimeout(this.getPendingOrderList, 6000);
            
        },

        async handleReplaceKitClick(e) {
            var orderId = e.target.getAttribute('data-order_id');
            window.location.href = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/account/replace-kit?orderId=' + orderId;
        },

        cancelAutoUpdate() {  
            clearInterval(this.getPendingOrderList);  
        }

    },
    
    data: function() {

        return {
            stepsDirection: 'horizontal',
            isPendingTestReady: false,
            isPendingTestEmpty: true,
            isPendingTestLoading: false,
            isRecollectionRequired: false,
            pendingTestList: [],
            isOrderExpanded: {},
            pendingTestHtml: '',
            whoseSample: '',
            regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
            websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
            t: t
        }

    },

    beforeDestroy() {  
        this.cancelAutoUpdate();
    }

}
</script> 

<style>

.order-list {
    display: flex;
    border:1px solid #d0d9de;
    background-color:#fff;
    border-radius:8px;
    padding: 30px;
    margin: 0 0 20px;
    align-items: top;
}
.order-list hr {
    border-top:1px solid #ccc;
    margin:10px 0 20px;
}
.order-list .left {
    width:150px;
    display: flex;
    align-items: flex-start;
    flex: 0 1 auto;
}
.order-list .break {
    flex-basis: 100%;
    height: 0;
}
.order-list .left .left-icon {
    margin-right: 16px;
}
.order-list .left .left-icon > img {
    width:120px;
}
.order-list .right .right-content {
    margin:0 0 10px;
    flex:5 0;
}
.order-list .right .actions {
    flex:1 0;
}
.order-list .right .actions.empty {
    height:0;
}
.order-list .right .right-content h4 {
    font-size:1.1rem;
    margin:0 0 2px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
}
.order-list .right .right-content .client-order-id {
    color: #333;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px;
}
.order-list .right .right-content .client-order-id.with-info {
    margin-bottom:0;
}
.order-list .right .right-content .sub-info {
    color: #666;
    margin-bottom:0 0 10px;
}
.order-list .right {
    flex: 1 0;
    max-width:80%;
    display:flex;
    flex-wrap:wrap;
}
.order-list .description {
    margin-bottom:20px;
}
.order-list .right .console-message-wrapper,
.order-list .right .recollection-wrapper {
    background-color:#f5fafd;
    border:1px dotted #77c1cc;
    border-radius:8px;
    padding:20px;
    width:75%;
}
.order-list .right .console-message-wrapper p {
    margin:0;
}
.order-list .right .console-message {
    margin-bottom:30px;
}
.order-list .action-wrapper {
    margin-bottom:25px;
}
.steps-wrapper {
    margin:20px 0 30px;
    width:75%;
}
.circ {
    display: inline-block;
    background-color: #ccc;
    color: #fff;
    margin: 0 15px 15px 0;
    border-radius: 50%;
    font-size:34px;
    width:52px;
    height:52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    display:none;
}
.ant-steps-label-vertical .ant-steps-item-tail {
    padding:3.5px 0 3.5px 12px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    padding:0;
}
.order-list .actions .actions-spacer ul {
    list-style:none;
    margin:0;
    padding:0;
}
.order-list .actions .actions-spacer ul > li {
    list-style:none;
    padding:0;
    margin:0 0 10px;
}
.order-list .actions .actions-spacer ul > li:last-child {
    margin:0;
}
.pretext-section {
    margin:0 0 15px;
}
.ant-steps-small .ant-steps-item-title {
    line-height:18px;
}

@media only screen and (max-width:1208px) {
    .steps-wrapper,
    .order-list .right .console-message-wrapper,
    .order-list .right .recollection-wrapper {
        width:95%;
    }
    .order-list .right {
        max-width:90%;
    }
}

@media only screen and (max-width:990.99px) {
    .order-list {
        flex-direction:column;
    }
    .order-list .left {
        width:100%;
    }
    .order-list .right {
        width:100%; 
    }
    .order-list .right .console-message {
        margin-top:20px;
        margin-left:0;
        margin-right:0;
        text-align:left;
    }
}

@media only screen and (max-width:767.99px) {
    .order-list .left .left-content .sub-info .ant-divider {
        display: block;
        visibility: hidden;
        height: 1px;
    }
    .order-list .left {
        margin-bottom:20px;
    }
    .order-list .right {
        width:100%; 
        max-width:100%  ;
        flex-direction:column;
    }
    .pending-wrapper .steps-wrapper {
        margin-top:25px;
    }
    .steps-wrapper,
    .order-list .right .console-message-wrapper,
    .order-list .right .recollection-wrapper {
        width:100%;
    }
}

</style>