<template>

    <div>

        <div v-if="isOrderIdValid && !isLoading">
        
            <div v-if="!contactConfirmed">

                <h2 style="margin-bottom:30px;">{{ t('Appointment Scheduling Form %s', clientOrderId) }}</h2>

                <error v-if="error" :error="error" />

                <a-form-model
                    id="form-normal-contact-confirm"
                    :form="form"
                    class="contact-confirm-form"
                    layout="vertical"
                    @submit.prevent="handleSubmit"
                >
                
                    <p>{{ t('Dear %s, ', acctName) }}</p>
                    <p>{{ t('Thank you for choosing %s to perform your DNA Immigration test for your family. Our Scheduling staff will be coordinating DNA collection appointments with the Embassy/Consulate staff and our collectors.', companyName) }}</p>
                    <p style="margin-bottom:20px;">{{ t('Please complete the form below as soon as possible, and ensure that all information is accurate so that we can proceed with booking immediately:') }}</p>

                    <div :key="componentKey" style="margin-bottom:30px;">

                        <li v-for="(data, index) in this.patientList" :key="index">
                    
                            <div :class="'component-container ' + data.component_type_code">

                                <h3>{{ t('%s\'s Information', data.component_type_label) }}<span></span></h3>

                                <div class="component-content">

                                    <div>
                                        <a-row :gutter="8">
                                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                <a-form-item
                                                    :label="t('%s\'s First Name', data.component_type_label)"
                                                    required
                                                    :class="fieldHasError[data.individual_id + '-first_name']"
                                                >
                                                    <a-input                        
                                                        v-model="first_name[data.component_type_code + '-' + data.component_type_numbering]"
                                                        size="large"
                                                    />
                                                </a-form-item>
                                            </a-col>
                                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                <a-form-item
                                                    :label="t('%s\'s Last Name', data.component_type_label)"
                                                    required
                                                    :class="fieldHasError[data.individual_id + '-last_name']"
                                                >
                                                    <a-input                        
                                                        v-model="last_name[data.component_type_code + '-' + data.component_type_numbering]"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.last_name" :key="field">
                                                        <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>
                                                </a-form-item>
                                            </a-col>
                                        </a-row>

                                        <a-form-item
                                            :label="t('%s\'s Birth Date', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-birth_date']"
                                        >
                                            <dropdown-datepicker
                                                v-model="birth_date[data.component_type_code + '-' + data.component_type_numbering]"
                                                :dropdownClass="'birth_date-' + data.component_type_code + '-' + data.component_type_numbering"
                                                :maxYear="maxYear"
                                                :minYear="minYear"
                                                :daySuffixes="false"
                                                :default-date="birth_date[data.component_type_code + '-' + data.component_type_numbering]"
                                            />
                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Sex at Birth', data.component_type_label)"
                                            required
                                            v-if="data.gender == '-'"
                                            :class="fieldHasError[data.individual_id + '-gender']"
                                        >
                                            <a-radio-group v-model="gender[data.component_type_code + '-' + data.component_type_numbering]" class="ant-radio-lg">
                                                <span v-for="(item, index) in genderOptions" :key="index" style="margin-right:20px;">
                                                    <a-radio :value="index">{{ item }}</a-radio>
                                                </span>
                                            </a-radio-group>
                                            <div v-for="(errorMessage, field) in errors.gender" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Address Line 1', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-address_1']"
                                        >
                                            <a-input                        
                                                v-model="address_1[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.address_1" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Address Line 2 (optional)', data.component_type_label)"
                                            :class="fieldHasError[data.individual_id + '-address_2']"
                                        >
                                            <a-input                        
                                                v-model="address_2[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.address_2" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Current Location (Country)', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-country_id']"
                                        >
                                            <a-select
                                                show-search
                                                :placeholder="t('Select one')"
                                                :filter-option="filterOption"
                                                option-filter-prop="children"
                                                @change="updateStatesForCountry(data.component_type_code + '-' + data.component_type_numbering, country_id[data.component_type_code + '-' + data.component_type_numbering])"
                                                v-model="country_id[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            >
                                                <a-select-option v-for="data in countries" :key="data.key">
                                                    {{ data.value }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        
                                        <a-form-item
                                            v-if="states[data.component_type_code + '-' + data.component_type_numbering] && states[data.component_type_code + '-' + data.component_type_numbering].length > 0"
                                            :label="stateLabel[data.component_type_code + '-' + data.component_type_numbering]"
                                            :required="stateIdRequired[data.component_type_code + '-' + data.component_type_numbering]"
                                            :class="fieldHasError[data.individual_id + '-state_id']"
                                        >
                                            <a-select
                                                v-if="Object.keys(states[data.component_type_code + '-' + data.component_type_numbering]).length > 0"
                                                show-search
                                                :placeholder="t('Select one')"
                                                v-model="state_id[data.component_type_code + '-' + data.component_type_numbering]"
                                                :filter-option="filterOption"
                                                option-filter-prop="children"
                                                @change="updateStateCaption(data.component_type_code + '-' + data.component_type_numbering)"
                                                size="large"
                                            >
                                                <a-select-option
                                                    v-for="stateData in states[data.component_type_code + '-' + data.component_type_numbering]"
                                                    :value="stateData.key"
                                                    :key="stateData.key"
                                                >
                                                    {{ stateData.value }}
                                                </a-select-option>
                                            </a-select>

                                        </a-form-item>

                                        <a-form-item
                                            v-else-if="!stateHidden[data.component_type_code + '-' + data.component_type_numbering]"
                                            :label="stateLabel[data.component_type_code + '-' + data.component_type_numbering]"
                                            :required="stateRequired[data.component_type_code + '-' + data.component_type_numbering]"
                                            :class="fieldHasError[data.individual_id + '-state']"
                                        >
                                            <a-input                        
                                                v-model="state[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.state" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Current Location (City)', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-city']"
                                        >
                                            <a-input                        
                                                v-model="city[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.city" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                        </a-form-item>

                                        <a-form-item
                                            :label="zipCodeLabel[data.component_type_code + '-' + data.component_type_numbering]"
                                            :class="fieldHasError[data.individual_id + '-zip_code']"
                                        >
                                            <a-input                        
                                                v-model="zip_code[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.zip_code" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Phone Number (for scheduling appointment)', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-phone']"
                                        >
                                            <a-input                        
                                                v-model="phone[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.phone" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                        </a-form-item>

                                        <a-form-item
                                            :label="t('%s\'s Email (for scheduling appointment)', data.component_type_label)"
                                            required
                                            :class="fieldHasError[data.individual_id + '-email']"
                                        >
                                            <a-input                        
                                                v-model="email[data.component_type_code + '-' + data.component_type_numbering]"
                                                size="large"
                                            />
                                            <div v-for="(errorMessage, field) in errors.email" :key="field">
                                                <div v-if="field == data.component_type_code + '-' + data.component_type_numbering" class="ant-form-explain">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                        </a-form-item>

                                    </div>

                                </div>

                            </div>

                        </li>

                    </div>

                    <div style="margin-bottom:30px;">

                        <div class="component-container X">

                            <h3>{{ t('Primary Contact\'s Information') }}<span></span></h3>
                        
                            <div class="component-content">

                                <a-form-item
                                    :label="t('Who is the primary contact?')"
                                    required
                                    :class="fieldHasError['contact-type']"
                                >
                                    <a-radio-group v-model="contact_option" @change="contactOptionChanged">
                                        <div v-for="(item, index) in contactOptions" :key="index" style="margin-right:20px;margin-bottom:5px;">
                                            <a-radio :value="item.individual_id">
                                                {{ item.component_type_label }}
                                                <span v-if="item.variant == 'Representative'">
                                                    (<span v-if="item.first_name">{{ item.first_name }}</span><span v-if="item.last_name"> {{ item.last_name }}</span>
                                                </span>)
                                            </a-radio>
                                            
                                            <div v-if="((contact_option == '0' || item.variant == 'Representative') && contact_option == item.individual_id)" style="margin-top:10px;">
                                                <a-form-item
                                                    :label="t('Contact\'s Type')"
                                                    required
                                                    :class="fieldHasError['contact-type']"
                                                >
                                                    <a-input                        
                                                        v-model="contact_type"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.type" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>
                                                <a-row :gutter="8">
                                                    <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                        <a-form-item
                                                            :label="t('Contact\'s First Name')"
                                                            required
                                                            :class="fieldHasError['contact-first_name']"
                                                        >
                                                            <a-input                        
                                                                v-model="first_name['contact']"
                                                                size="large"
                                                            />
                                                            <div v-for="(errorMessage, field) in errors.first_name" :key="field">
                                                                <div v-if="field == 'contact'" class="ant-form-explain">
                                                                    {{ errorMessage }}
                                                                </div>
                                                            </div>
                                                        </a-form-item>
                                                    </a-col>
                                                    <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                        <a-form-item
                                                            :label="t('Contact\'s Last Name')"
                                                            required
                                                            :class="fieldHasError['contact-last_name']"
                                                        >
                                                            <a-input                        
                                                                v-model="last_name['contact']"
                                                                size="large"
                                                            />
                                                            <div v-for="(errorMessage, field) in errors.last_name" :key="field">
                                                                <div v-if="field == 'contact'" class="ant-form-explain">
                                                                    {{ errorMessage }}
                                                                </div>
                                                            </div>
                                                        </a-form-item>
                                                    </a-col>
                                                </a-row>

                                                <a-form-item
                                                    :label="t('Contact\'s Address Line 1')"
                                                    required
                                                    :class="fieldHasError['contact-address_1']"
                                                >
                                                    <a-input                        
                                                        v-model="address_1['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.address_1" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>
                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s Address Line 2 (Optional)')"
                                                    :class="fieldHasError['contact-address_2']"
                                                >
                                                    <a-input                        
                                                        v-model="address_2['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.address_2" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>
                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s Country')"
                                                    required
                                                    :class="fieldHasError['contact-country_id']"
                                                >
                                                    <a-select
                                                        show-search
                                                        :placeholder="t('Select one')"
                                                        :filter-option="filterOption"
                                                        option-filter-prop="children"
                                                        @change="updateStatesForCountry('contact', country_id['contact'])"
                                                        v-model="country_id['contact']"
                                                        size="large"
                                                    >
                                                        <a-select-option v-for="data in countries" :key="data.key">
                                                            {{ data.value }}
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-item>
                                        
                                                <a-form-item
                                                    v-if="states['contact'] && states['contact'].length > 0"
                                                    :label="t('Contact\'s State')"
                                                    :required="stateIdRequired['contact']"
                                                    :class="fieldHasError['contact-state_id']"
                                                >
                                                    <a-select
                                                        show-search
                                                        :placeholder="t('Select one')"
                                                        v-model="state_id['contact']"
                                                        :filter-option="filterOption"
                                                        option-filter-prop="children"
                                                        @change="updateStateCaption('contact')"
                                                        size="large"
                                                    >
                                                        <a-select-option v-for="selectData in states['contact']" :key="selectData.key">
                                                            {{ selectData.value }}
                                                        </a-select-option>
                                                    </a-select>

                                                </a-form-item>

                                                <a-form-item
                                                    v-else
                                                    :label="t('Contact\'s State')"
                                                    :required="stateRequired['contact']"
                                                    :class="fieldHasError['contact-state']"
                                                >
                                                    <a-input                        
                                                        v-model="state['contact']"
                                                        size="large"
                                                    />

                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s City')"
                                                    required
                                                    :class="fieldHasError['contact-city']"
                                                >
                                                    <a-input                        
                                                        v-model="city['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.city" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s Zip Code')"
                                                    :class="fieldHasError['contact-zip_code']"
                                                >
                                                    <a-input                        
                                                        v-model="zip_code['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.zip_code" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s Phone Number')"
                                                    required
                                                    :class="fieldHasError['contact-phone']"
                                                >
                                                    <a-input                        
                                                        v-model="phone['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.phone" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>

                                                <a-form-item
                                                    :label="t('Contact\'s Email')"
                                                    required
                                                    :class="fieldHasError['contact-email']"
                                                >
                                                    <a-input                        
                                                        v-model="email['contact']"
                                                        size="large"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.email" :key="field">
                                                        <div v-if="field == 'contact'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>

                                            </div>

                                        </div>
                                        
                                    </a-radio-group>
                                </a-form-item>

                            </div>

                        </div>

                    </div>

                    <div v-if="isDocumentReqeuired">
                        <h3>{{ t('Upload %s Document', docType) }}</h3>
                        <p style="margin-bottom:20px;">
                            {{ t('We require a copy of the official letter from %s requesting DNA testing.', docType) }}
                        </p>

                        <div v-if="errors.documents" class="ant-alert ant-alert-error ant-alert-banner" syle="padding:15px;margin-bottom:10px;">
                            {{ errors.documents }}
                        </div>

                        <div style="margin-bottom:30px;">
                            <a-upload-dragger
                                v-model="fileList"
                                name="file"
                                :multiple="true"
                                list-type="picture"
                                @change="handleUploadChange"
                                :before-upload="handleBeforeUpload"
                            >
                                <a-icon type="inbox" class="icon-size"></a-icon>
                                <p>{{ t('Click or drag a copy of your immigration letter to this area to upload the document(s).') }}</p>
                            </a-upload-dragger>

                        </div>
                    </div>

                    <div>
                        <p>{{ t('Check to ensure that all of the required information is correct before submitting this form:')  }}</p>

                    </div>

                    <a-form-item
                        :class="fieldHasError['checkbox_confirmed']"
                        style="margin-bottom:0;"
                    >

                        <div class="component-checkbox">

                            <div v-if="errors.checkbox_confirmed" class="ant-alert ant-alert-error ant-alert-banner" syle="padding:15px;margin-bottom:10px;">
                                {{ errors.checkbox_confirmed }}
                            </div>

                            <label v-for="(label, key) in checkboxOptions" :key="key" style="margin-bottom:10px;">

                                <span class="left">
                                    <a-checkbox
                                        v-model="selectedCheckboxes[key]"
                                        :id="'selected-box-' + key"
                                    />
                                </span>
                                <span class="right">
                                    <div>
                                        {{ label }}
                                    </div>
                                </span>
                            
                            </label>
                            
                        </div>

                    </a-form-item>

                    <p>{{ t('Please note that if any of the information provided above is incorrect, it may result in delays in scheduling your appointments.') }}</p>

                    <a-form-item class="centered" style="margin-bottom:30px;">
                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="individual-confirm-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button : ''">
                            {{ formSubmitting ? t('Submitting...') : t('Submit Form') }}
                        </a-button>
                    </a-form-item>

                </a-form-model>

            </div>

            <div v-else>

                <span class="centered" style="display:block;">

                    <div class="icon-success"><font-awesome-icon icon="check-circle" class="fa-8x" /></div>

                    <h2>{{ t('Submission Successful!') }}</h2>

                    <div class="payment-successful-message">
                        {{ t('Thank you. Our scheduling department will contact you for an appointment as soon as possible.') }}
                    </div>

                    <div style="text-align:center;margin-bottom:30px;">
                        <router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link>
                    </div>

                </span>

            </div>

        </div>

        <div v-else>

            <a-alert
                message="There is a problem..."
                :description="t(error)"
                type="error"
                show-icon
                v-if="isAlertDisplaying"
            />

        </div>

    </div>

</template>

<script>
    
    import axiosLegalInstance from '../../apiLegal'
    import axiosUserInstance from '../../apiUser'
    import Error from '../Error.vue'
    import moment from 'moment'
    import DropdownDatepicker from '../../../node_modules/vue-dropdown-datepicker/src/dropdown-datepicker.vue'

    import { Icon } from 'ant-design-vue';

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

    export default {
        name: 'FormContactConfirmComponent',
        components: {
            'a-icon': Icon,
            Error,
            DropdownDatepicker
        },
        computed: {
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            website : function(){ return this.$store.getters.getWebsite},
            token : function(){ return this.$store.getters.isAuthenticated ? this.$store.getters.getToken : null},

        },
        mounted: function() {
            this.$nextTick(function () {
                
            })
        },
        created() {
            this.getCountryListRequest();
            this.getInformationList();
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_contact_confirm' });
        },
        data() {  
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                orderId: this.$route.params.order_id ? this.$route.params.order_id : '',
                componentKey: 0,
                countriesWithStateIds: ['1', '14', '45', '96', '108', '208', '226'],
                states: {},
                countries: [],
                dynamicKeys: [],
                genderOptions: {'m': t('Male'), 'f': t('Female')},
                booleanOptions: {'y': t('Yes'), 'n': t('No')},
                isLoading: true,
                isAlertDisplaying: false,
                formSubmitting: false,
                isOrderIdValid: true,
                contactConfirmed: false,
                isPageReady: false,
                isDocumentReqeuired: true,
                maxYear: moment().year(),
                minYear: moment().year() - 121,
                stateLabel: {},
                zipCodeLabel: {},
                stateIdRequired: {},
                stateRequired: {},
                stateHidden: {},
                error: '',
                errors: {},
                fieldHasError: {},
                acctName: 'Sir/Madam',
                companyName: null,
                clientOrderId: null,
                docType: null,
                contact_option: null,
                contact_type: null,
                first_name: {},
                last_name: {},
                birth_date: {},
                gender: {},
                address_1: {},
                address_2: {},
                country_id: {},
                state_id: {},
                state: {},
                city: {},
                zip_code: {},
                phone: {},
                email: {},
                fileList: [],
                fileToBase64List: [],
                base64DataList: {},
                contactOptions: {},
                checkboxOptions: {
                    1: t('The correct legal names must be provided for all individuals'),
                    2: t('The current location must be provided for all individuals'),
                    3: t('The correct contact information must provided for all individuals so that we can contact them to schedule appointments.'),
                    4: t('Check to ensure that ALL pages of the immigration letter have been uploaded.'),
                    5: t('Check to ensure that all of the scanned pages of the immigration letter are clear and legible.')
                },
                selectedCheckboxes: {},
                contact: {},
                contactList: {},
                contactReset: {
                    'individual_id': 0,
                    'variant': 'New',
                    'product_set_component_id': null,
                    'component_type_label': 'Other (please define)',
                    'first_name': null,
                    'middle_name': null,
                    'last_name': null,
                    'address_1': null,
                    'address_2': null,
                    'city': null,
                    'state': null,
                    'state_id': null,
                    'country_id': null,
                    'zip_code': null,
                    'phone': null,
                    'email': null
                },
                t: t
            }   
        },
        methods: {
            
            setLoading(isLoading) {
                if (isLoading) {
                    // Disable the button and show a spinner
                    document.querySelector("#btn-pay").disabled = true;
                    document.querySelector("#spinner").classList.remove("hidden");
                    document.querySelector("#button-text").classList.add("hidden");
                }
                else {
                    document.querySelector("#btn-pay").disabled = false;
                    document.querySelector("#spinner").classList.add("hidden");
                    document.querySelector("#button-text").classList.remove("hidden");
                }
            },

            handleBeforeUpload(file) {
                // Create a new FileReader to read the file as base64
                const reader = new FileReader();

                // Use a Promise to resolve when the FileReader is done reading the file
                new Promise((resolve, reject) => {
                    reader.onload = () => {
                        // Get the base64 data from the FileReader result
                        const base64Data = reader.result.split(",")[1];
                        this.fileToBase64List[file.uid] = {
                            'name': file.name,
                            'file': base64Data
                        };
                        resolve(base64Data);
                    };

                    reader.onerror = (error) => {
                        reject(error);
                    };

                    // Start reading the file as Data URL (which includes base64 data)
                    reader.readAsDataURL(file);
                });

                return false;
            },

            handleUploadChange(files) {
                // files is an array of base64 data for each uploaded file
                this.fileList = files.fileList;
            },

            scrollToTop() {
                // Scroll to the top of the form
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // You can use 'auto' or 'smooth' scrolling behavior
                });
            },

            contactOptionChanged() {
                if (this.contact_option == '0') {
                    this.contact_type = null;
                    this.first_name['contact'] = null;
                    this.last_name['contact'] = null;
                    this.address_1['contact'] = null;
                    this.address_2['contact'] = null;
                    this.city['contact'] = null;
                    this.zip_code['contact'] = null;
                    this.phone['contact'] = null;
                    this.email['contact'] = null;
                    this.state['contact'] = null;
                    this.state_id['contact'] = null;
                    this.country_id['contact'] = null;
                }
                else {
                    if (this.contactOptions) {
                        
                        for (var k = 0; k < Object.keys(this.contactOptions).length; k++) {
                            
                            if (this.contactOptions[k].individual_id == this.contact_option) {
                                this.contact_type = this.contactOptions[k].component_type_label;

                                this.first_name['contact'] = this.contactList.first_name;
                                this.last_name['contact'] = this.contactList.last_name;
                                this.address_1['contact'] = this.contactList.address_1;
                                this.address_2['contact'] = this.contactList.address_2;
                                this.city['contact'] = this.contactList.city;
                                this.zip_code['contact'] = this.contactList.zip_code;
                                this.phone['contact'] = this.contactList.phone;
                                this.email['contact'] = this.contactList.email;
                                this.state['contact'] = this.contactList.state;
                                this.state_id['contact'] = this.contactList.state;
                                this.country_id['contact'] = this.contactList.country_id;

                                break;
                            }
                        }

                    }
                }
            },

            async handleSubmit() {
                
                this.formSubmitting = true;

                this.error = '';
                this.errors = {};
                this.fieldHasError = {};

                try {

                    let numChecked = Object.values(this.selectedCheckboxes).filter(checked => checked).length,
                        numTotal = Object.values(this.selectedCheckboxes).length;

                    console.log(numChecked);
                    console.log(numTotal);

                    if (numChecked < numTotal) {
                        this.error = t('Error found. Please correct the form below and try again.');
                        this.errors['checkbox_confirmed'] = t('All checkboxes must be checked.');
                        this.fieldHasError['checkbox_confirmed'] = 'has-error';

                        this.formSubmitting = false;

                        // Throw an error to stop further execution
                        throw new Error(this.errors['checkbox_confirmed']);
                    }

                    let uri = this.regionId + '/' + this.websiteId + '/iqr/information-confirmation/' + this.orderId;

                    let patients = {},
                        contact = {},
                        documents = {};

                    if (this.fileList.length > 0) {
                        for (var j = 0; j < this.fileList.length; j++) {
                            documents[j] = this.fileToBase64List[this.fileList[j].uid];
                        }
                    }

                    if (this.patientList) {
                        for (var k = 0; k < this.patientList.length; k++) {
                            let key = this.patientList[k].component_type_code + '-' + this.patientList[k].component_type_numbering;

                            if (this.state_id[key] != '') {
                                if (this.state_id[key] == this.state[key]) {
                                    for (var m = 0; m < Object.keys(this.states[key]).length; m++) {
                                        if (this.states[key][m].value == this.state[key]) {
                                            this.state_id[key] = this.states[key][m].key;
                                            break;
                                        }
                                    }
                                }
                            }

                            patients[k] = {
                                'product_set_component_id': this.patientList[k].product_set_component_id,
                                'individual_id': this.patientList[k].individual_id,
                                'first_name': this.first_name[key],
                                'middle_name': null,
                                'last_name':  this.last_name[key],
                                'gender': this.gender[key],
                                'birth_date': this.birth_date[key],
                                'address_1': this.address_1[key],
                                'address_2': this.address_2[key],
                                'city': this.city[key],
                                'state': this.state[key],
                                'state_id': this.state_id[key],
                                'country_id': this.country_id[key],
                                'zip_code': this.zip_code[key],
                                'phone': this.phone[key],
                                'email': this.email[key]
                            };

                        }
                    }

                    if (this.state_id['contact'] != '' && this.states['contact']) {
                        if (this.state_id['contact'] == this.state['contact']) {
                            for (var n = 0; n < Object.keys(this.states['contact']).length; n++) {
                                if (this.states['contact'][n].value == this.state['contact']) {
                                    this.state_id['contact'] = this.states['contact'][n].key;
                                    break;
                                }
                            }
                        }
                    }

                    contact = {
                        'individual_id': this.contact_option,
                        'type': this.contact_type,
                        'first_name': this.first_name['contact'],
                        'middle_name': null,
                        'last_name': this.last_name['contact'],
                        'address_1': this.address_1['contact'],
                        'address_2': this.address_2['contact'],
                        'city': this.city['contact'],
                        'state': this.state['contact'],
                        'state_id': this.state_id['contact'],
                        'country_id': this.country_id['contact'],
                        'zip_code': this.zip_code['contact'],
                        'phone': this.phone['contact'],
                        'email': this.email['contact'],
                    };

                    var formInputs = {
                        'patients': patients,
                        'contact': contact,
                        'documents': documents
                    };

                    // Send the files to the server using axios (adjust the API endpoint accordingly)
                    const response = await axiosLegalInstance.post(uri, formInputs, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    // Handle the server response (if needed)
                    console.log('Server Response:');
                    console.log(response);

                    this.formSubmitting = false;
                    this.contactConfirmed = true;

                }

                catch (e) {
                    
                    var numPatientErrors = 0,
                        numContactErrors = 0,
                        numDocumentErrors = 0;
                        
                    if (e && e.response && Object.prototype.hasOwnProperty.call(e.response, 'data')) {
                        numPatientErrors = 'patients' in e.response.data.errors ? Object.keys(e.response.data.errors.patients).length : 0;
                        numContactErrors = 'contact' in e.response.data.errors ? Object.keys(e.response.data.errors.contact).length : 0;
                        numDocumentErrors = 'documents' in e.response.data.errors ? Object.keys(e.response.data.errors.documents).length : 0;
                    }

                    console.log('numPatientErrors -> ' + numPatientErrors);
                    console.log('numContactErrors -> ' + numContactErrors);
                    console.log('numDocumentErrors -> ' + numDocumentErrors);

                    if (numPatientErrors > 0) {
                        for (const individualId in e.response.data.errors.patients) {
                            for (const field in e.response.data.errors.patients[individualId]) {
                                this.errors[field] = {};
                                this.fieldHasError[individualId + '-' + field] = '';
                            }
                        }
                        for (const individualId in e.response.data.errors.patients) {
                            for (const field in e.response.data.errors.patients[individualId]) {
                                this.errors[field] = e.response.data.errors.patients[individualId][field];
                                this.fieldHasError[individualId + '-' + field]  = 'has-error';
                            }
                        }
                    }
                    
                    if (numContactErrors > 0) {
                        for (const field in e.response.data.errors.contact) {
                            this.errors[field] = {};
                            this.fieldHasError['contact-' + field] = '';
                        }
                        for (const field in e.response.data.errors.contact) {
                            this.errors[field] = e.response.data.errors.contact[field];
                            this.fieldHasError['contact-' + field] = 'has-error';
                        }
                    }

                    if (numDocumentErrors > 0) {
                        this.errors['documents'] = e.response.data.errors.documents;
                        this.fieldHasError['documents'] = 'has-error';
                    }

                    this.scrollToTop();

                    console.log(this.errors);

                    // console.log('this.fieldHasError');
                    // console.log(this.fieldHasError);
                    this.formSubmitting = false;
                    this.error = t('Error found. Please correct the form below and try again.');

                }

            },

            async getInformationList() {

                try {
                    
                    let uri = this.regionId + '/' + this.websiteId + '/iqr/information-confirmation/' + this.orderId;

                    let res = await axiosLegalInstance.get(uri);

                    this.patientList = Object.keys(res.data.information.patients).map(key => {
                        return res.data.information.patients[key];
                    });

                    this.isLoading = false;

                    if (res.data.information.contact) {
                        this.contactList = res.data.information.contact;
                    }

                    if (res.data.information.company) {
                        this.companyName = res.data.information.company;
                    }

                    if (res.data.information.acct.first_name) {
                        this.acctName = res.data.information.acct.first_name;
                    }

                    if (res.data.information.document.uploaded_at) {
                        this.isDocumentReqeuired = false;
                    }

                    if (res.data.information.document.letter) {
                        this.docType = t(res.data.information.document.letter);
                    }

                    if (res.data.information.client_order_full_id) {
                        this.clientOrderId = ' for Order ID ' + res.data.information.client_order_full_id;
                    }

                    if (this.patientList) {
                        for (var i = 0; i < this.patientList.length; i++) {
                            let key = this.patientList[i].component_type_code + '-' + this.patientList[i].component_type_numbering;
                            this.$set(this.first_name, key, null);
                            this.$set(this.last_name, key, null);
                            this.$set(this.gender, key, null);
                            this.$set(this.birth_date, key, null);
                            this.$set(this.address_1, key, null);
                            this.$set(this.address_2, key, null);
                            this.$set(this.country_id, key, null);
                            this.$set(this.state, key, null);
                            this.$set(this.state_id, key, null);
                            this.$set(this.city, key, null);
                            this.$set(this.zip_code, key, null);
                            this.$set(this.phone, key, null);
                            this.$set(this.email, key, null);
                        }

                        for (var k = 0; k < this.patientList.length; k++) {
                            let key = this.patientList[k].component_type_code + '-' + this.patientList[k].component_type_numbering;
                            this.first_name[key] = this.patientList[k].first_name;
                            this.last_name[key] = this.patientList[k].last_name;
                            this.gender[key] = this.patientList[k].gender;
                            this.birth_date[key] = this.patientList[k].birth_date;
                            this.address_1[key] = this.patientList[k].address_1;
                            this.address_2[key] = this.patientList[k].address_2;
                            this.city[key] = this.patientList[k].city;
                            this.zip_code[key] = this.patientList[k].zip_code;
                            this.phone[key] = this.patientList[k].phone;
                            this.email[key] = this.patientList[k].email;
                            
                            if (this.patientList[k].country_id) {
                                this.country_id[key] = this.patientList[k].country_id;
                                
                                this.updateStatesForCountry(key, this.country_id[key]);
                                this.state_id[key] = this.patientList[k].state;  // Tricky.
                                this.state[key] = this.patientList[k].state;

                                this.stateLabel[key] = t('%s\'s State', this.patientList[k].component_type_label);
                                this.zipCodeLabel[key] = t('%s\'s Zip Code', this.patientList[k].component_type_label);
                                this.contactOptions[k] = this.patientList[k];
                                this.contactOptions[k].variant = 'Patient';
                            }
                            if (this.contact_option == null) {
                                this.contact_option = this.patientList[k].individual_id;
                            }
                        }
                    }

                    // Initialize checkboxes.
                    for (const key in this.checkboxOptions) {
                        this.$set(this.selectedCheckboxes, key, false);
                    }

                    // Initialize contact information.
                    this.$set(this.first_name, 'contact', null);
                    this.$set(this.last_name, 'contact', null);
                    this.$set(this.address_1, 'contact', null);
                    this.$set(this.address_2, 'contact', null);
                    this.$set(this.country_id, 'contact', null);
                    this.$set(this.state, 'contact', null);
                    this.$set(this.state_id, 'contact', null);
                    this.$set(this.city, 'contact', null);
                    this.$set(this.zip_code, 'contact', null);
                    this.$set(this.phone, 'contact', null);
                    this.$set(this.email, 'contact', null);

                    if (res.data.information.contact) {
                        if (res.data.information.contact.individual_id) {
                            this.contact_option = res.data.information.contact.individual_id;
                            
                            if (res.data.information.contact.variant != 'Patient') {

                                this.contact_type = res.data.information.contact.type;
                                this.first_name['contact'] = res.data.information.contact.first_name;
                                this.last_name['contact'] = res.data.information.contact.last_name;
                                this.address_1['contact'] = res.data.information.contact.address_1;
                                this.address_2['contact'] = res.data.information.contact.address_2;
                                this.city['contact'] = res.data.information.contact.city;
                                this.zip_code['contact'] = res.data.information.contact.zip_code;
                                this.phone['contact'] = res.data.information.contact.phone;
                                this.email['contact'] = res.data.information.contact.email;

                                if (res.data.information.contact.country_id) {

                                    this.country_id['contact'] = res.data.information.contact.country_id;

                                    this.updateStatesForCountry('contact', this.country_id['contact']);
                                    this.state_id['contact'] = res.data.information.contact.state;  // Tricky.
                                    this.state['contact'] = res.data.information.contact.state;

                                }

                                this.contactOptions[k] = res.data.information.contact;
                                this.contactOptions[k].variant = res.data.information.contact.variant;
                                this.contactOptions[k].component_type_label = res.data.information.contact.type;

                                k++;
                            }
                        }
                    }

                    this.contactOptions[k] = this.contactReset;

                }
                
                catch (e) {
                    this.isOrderIdValid = false;
                    this.isAlertDisplaying = true;
                    this.error = e.response.data.error;
                    // console.log(e.response);
                    // console.log(e.response.data.error);
                    console.log(this.errors);
                }

            },

            async getCountryListRequest() {

                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/countries/select-options');

                this.countries = res.data.options;

            },

            async updateStateCaption(key) {   
                this.state[key] = null;
                return true;

            },

            async updateStatesForCountry(key, countryId) {

                try {
                    let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/states/select-options?country_id=' + countryId);

                    this.$set(this.states, key, res.data.options); // same as this.$forceUpdate();

                    this.stateIdRequired[key] = false;
                    this.stateRequired[key] = false; // this is kept in case we need it in the future.
                    this.stateHidden[key] = true;

                    let label = '';
                    if (this.patientList) {
                        for (var k = 0; k < this.patientList.length; k++) {
                            let code = this.patientList[k].component_type_code + '-' + this.patientList[k].component_type_numbering;
                            if (code == key) {
                                label = this.patientList[k].component_type_label;
                                break;
                            }
                        }
                    }
                    if (this.states[key]) {
                        if (Object.keys(this.states[key]).length == 0) {
                            // this.stateRequired[key] = true;
                            // this.stateHidden[key] = true;
                        }
                        else {
                            this.stateIdRequired[key] = true;
                        }

                    }
                    if (countryId === '1') {
                       this.stateLabel[key] = t('%s\'s Current Location (Province)', label);
                       this.zipCodeLabel[key] = t('%s\'s Current Location (Postal Code)', label);
                    }
                    else if (countryId === '225') {
                        this.stateLabel[key] = t('%s\'s Current Location (County)', label);
                        this.zipCodeLabel[key] = t('%s\'s Current Location (Postcode)', label);
                    }
                    else {
                        this.stateLabel[key] = t('%s\'s Current Location (State)', label);
                        this.zipCodeLabel[key] = t('%s\'s Current Location (Zip Code)', label);
                    }
                    // this.$forceUpdate();
                } catch (e) {
                    this.$set(this.states, key, []);
                }

            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
        }
    }
</script>

<style>
button > svg {
    margin-right:10px;
}
.individual-confirm-form-button {
    margin:20px 0;
    width:100%;
}
.centered {
    text-align:center;
}
.icon-success {
    color:#3e8b27;
    margin:20px 0;
}
.hidden {
    display:none;
}
.upload-dragger {
    border: 2px dashed #d9d9d9;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    display:block;
    margin:0 0 20px;
}
.upload-dragger:hover {
    border-color:#1890ff;
}
.ant-upload {
    padding:20px;
}
.icon-size {
    font-size: 48px;
    color:#1890ff;
    margin-bottom:10px;
}
</style>