<template>
    <div>

        <a-row>

            <a-col class="app-signup" style="text-align:center;">
                <h2 style="margin-bottom:30px;">We'll be back soon!</h2>
                <p>Our website is scheduled for maintenance and upgrades on Thursday March 2, 2023 for 1 hour from 4:15 PM PST (7:15 PM EST) to 5:15 PM PST (8:15 PM EST).</p>
                <p>Please check back soon.</p>
            </a-col>
            
        </a-row>
    </div>
</template>


<script>

    export default {
        name: 'MainenanceComponent',
        components: {
        },
        computed: {
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
            }
        },
        mounted: function() {
        },
        methods: {
        }
    }
</script>