<template>
    <div>
        <ActivateStep step="collection-confirm" />
        <a-row>
            <a-col class="app-collection-confirm">

                <h2>Taking your sample</h2>
                <p style="margin-bottom:30px;">Please confirm the time and date when you will take your test sample.</p>

                <error v-if="error" :error="error" />

                <a-form-model
                    id="form-normal-collection-confirm"
                    :form="form"
                    class="collection-confirm-form"
                    layout="vertical"
                    @submit.prevent="handleTestConfirmSubmit"
                >

                    <a-form-item
                        label=""
                        required
                        class="is-collection"
                    >
                        
                        <a-radio-group
                            v-model="is_collection_now"
                            @change="checkSelectedOption"
                        >

                            <div class="option-container selected" data-option="1">
                                <a-radio :style="radioStyle" :value="1">
                                    <span class="option-title">I will take my sample now</span>
                                    <div class="current-date">{{ currentDateTime }}</div>
                                    <div class="warning" v-if="warning != '' && is_collection_now == 1"><strong>Warning: </strong> {{ warning }}</div>
                                </a-radio>
                            </div>

                            <div class="option-container" data-option="0">
                                <a-radio :style="radioStyle" :value="0">
                                    <span class="option-title">I will take my sample at a different time</span>
                                    <div class="new-date-time" v-show="is_collection_now == 0">
                                        <div class="new-date-caption">Date</div>
                                        <div class="new-date">
                                            <dropdown-datepicker
                                                v-model="new_date"
                                                :maxYear="maxYear"
                                                :minYear="minYear"
                                                :daySuffixes="false"
                                                display-format="mdy"
                                            />
                                        </div>
                                        <div class="new-date-caption">Time</div>
                                        <div class="new-date date-dropdowns">

                                            <select v-model="new_hour">
                                                <option value="">Hour</option>
                                                <option v-for="hour in 12" :value="parseInt(hour) < 10 ? '0' + hour : hour" :key="hour">
                                                    {{ String(hour).padStart(2, '0') }}
                                                </option>
                                            </select>

                                            <select v-model="new_minute">
                                                <option value="">Minute</option>
                                                <option v-for="(n, minute) in 60" :value="parseInt(minute) < 10 ? '0' + minute : minute" :key="minute">
                                                    {{ String(minute).padStart(2, '0') }}
                                                </option>
                                            </select>

                                            <select v-model="new_ampm">
                                                <option value="">AM/PM</option>
                                                <option v-for="ampm in ampms" :value="ampm" :key="ampm">
                                                    {{ ampm }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </a-radio>
                            </div>  
                            
                        </a-radio-group>

                    </a-form-item>

                    <a-form-item style="margin-bottom:30px;">
                        <a-button type="primary" html-type="submit" class="collection-confirm-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? 'Submitting...' : 'Activate Test Kit' }}
                        </a-button>
                    </a-form-item>

                </a-form-model>

            </a-col>
        </a-row>
    </div>
</template>


<script>

    import axios from 'axios'   
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'
    import moment from 'moment'
    import DropdownDatepicker from '../../node_modules/vue-dropdown-datepicker/src/dropdown-datepicker.vue'

    export default {
        name: 'CollectionConfirmComponent',
        components: {
            Error,
            ActivateStep,
            DropdownDatepicker
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            signup : function(){ return this.$store.getters.getSignup},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            this.validateCollectionDatetime();
            this.$nextTick(function () {
                console.log(this.currentDate);
                console.log(this.currentTime);
                this.reverseYearOrder();                
            })
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                error: '',
                errors: {},
                maxYear: moment().year() + 1,
                minYear: moment().year(),
                is_collection_now: 1,
                radioStyle: {
                    lineHeight: '26px',
                },
                new_date: '',
                new_hour: '',
                new_minute: '',
                new_ampm: '',
                fieldHasError: {},
                currentDate: moment().format('YYYY-MM-DD'),
                currentTime: moment().format('HH:mm:ss'),
                currentDateTime: moment().format('dddd, MMMM DD YYYY, hh:mm:ss A'),
                hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                ampms: ['AM', 'PM'],
                warning: ''
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_test_confirm' });
        },
        methods: {

            async reverseYearOrder() {
                const yearOptions = document.querySelector('#year');
                const options = Array.from(yearOptions.querySelectorAll('option'));
                const firstOption = options[0];
                options.shift();
                options.reverse();
                options.unshift(firstOption);
                options.forEach((o) => yearOptions.appendChild(o));
            },

            async validateCollectionDatetime() {

                try {
                    var formInputs = {};

                    if (this.is_collection_now == 1) {
                        formInputs = {
                            'collection_date': this.currentDate,
                            'collection_time': this.currentTime,
                            'product_id': this.activation.input.product_id,
                            'distributor_supply_id': this.activation.input.distributor_supply_id
                        }
                    }
                    else {
                        var newTime = '';
                        if (this.new_hour && this.new_minute && this.new_ampm) {
                            newTime = (this.new_ampm == 'AM' ? this.new_hour : parseInt(this.new_hour) + 12) + ':' + this.new_minute + ':00';
                        }
                        formInputs = {
                            'collection_date': this.new_date,
                            'collection_time': newTime,
                            'product_id': this.activation.input.product_id,
                            'distributor_supply_id': this.activation.input.distributor_supply_id
                        }
                    }

                    console.log(formInputs);
                    
                    let response = await axios.post(process.env.VUE_APP_API_UREG_URL_GFRONT + 'account/verify-collection-date-time', formInputs);

                    console.log(response);


                } catch(e) {

                    console.log(this.formSubmitting);
                    console.log(e.response.data.error);

                    this.warning = e.response.data.error;

                    if (this.formSubmitting) {
                        this.error = this.warning;
                    }

                }

            },

            async checkSelectedOption() {
                
                var elems = document.querySelectorAll(".option-container");

                [].forEach.call(elems, function(el) {
                    el.classList.remove('selected');
                });

                console.log('selected :' + this.is_collection_now);

                document.querySelectorAll('[data-option="' + this.is_collection_now  + '"]').forEach(function(element) {
                    element.classList.add('selected');
                });

            },
            
            async handleTestConfirmSubmit() {
                try {

                    this.formSubmitting = true; 

                    this.error = '';
                    this.errors = {};
                    this.fieldHasError = {};
                    
                    this.validateCollectionDatetime();

                    // this.$store.dispatch('setPatients', response.data.patients);

                    /*
                    if (this.isLoggedIn) {
                        this.$router.push('/' + this.regionId + '/' + this.websiteId + '/contact?via=activate');
                    }
                    else {
                        this.$router.push('/' + this.regionId + '/' + this.websiteId + '/signup?via=activate');
                    }
                    */

                    this.formSubmitting = false;
                    
                } catch(e) {

                    // console.log('error found.');
                    // console.log(e.response.data.errors.patients);
                    // console.log();

                    this.formSubmitting = false;
                    this.error = 'Error found. Please correct the form below and try again.';

                }
                
            },

        }        
    }
</script>

<style>

.app-collection-confirm {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-checkbox-inner {
  border:2px solid #1890ff;
}
.ant-checkbox-inner::after {
  top:43%;
  left:20%;
}
#form-normal-who .collection-confirm-form-button {
  margin:10px 0 5px;
  width: 100%;
}

</style>

<style scoped>
h4 {
    font-size:14px;
    font-weight:600;
}
.is-collection div {
    white-space:normal;
}
.is-collection .new-date-time {
    margin:0 20px;
}
.is-collection .option-title {
    font-weight:bold;
}
.is-collection .current-date {
    color:#888;
    margin:0 20px;
}
.is-collection .warning {
    margin:15px 20px 0;
    font-size:95%;
    line-height:150%;
}
.is-collection .option-container {
    background:#fff;
    padding:15px;
    margin:0 0 15px;
}
.is-collection .option-container.selected {
    background:#deefff;
}
.is-collection .new-date-caption {
    margin:15px 0 0;
    font-size:95%;
    font-weight:600;
}
</style>