<template>
    <a-layout-header class="app-header">
      <div v-if="$route.params.region_id">
        <div v-if="!isLoggedIn" class="app-header-wrapper justify">
          <div class="header-mid">
            <a :href="homeUrl">
              <img :src="logoUrl">
            </a>
          </div>
        </div>
        <div v-if="isLoggedIn" class="app-header-wrapper">
          <div class="header-left">
            <a :href="homeUrl" class="logo">
              <img :src="logoUrl">
            </a>
          </div>
          <div class="header-right">
            <!--
            <span v-if="$route.path != '/' + regionId + '/' + websiteId + '/account' && $route.path != '/' + regionId + '/' + websiteId + '/account/order' && $route.path != '/' + regionId + '/' + websiteId + '/account/result' && $route.path != '/' + regionId + '/' + websiteId + '/account/general' && $route.path != '/' + regionId + '/' + websiteId + '/account/security' && $route.path != '/' + regionId + '/' + websiteId + '/account/replace-kit'">
              You are currently logged in as <router-link class="name" :to="'/' + regionId + '/' + websiteId + '/account'"><span v-if="user.first_name !== null || user.last_name !== null">{{ this.user.first_name }} {{ this.user.last_name }}</span><span v-else>User</span></router-link>
              <a-divider type="vertical" />
              <a @click="handleNavLogoutClick">Logout</a>
            </span>
            -->

            <a-menu
              v-if="isLoggedIn"
              mode="horizontal"
              class="header-right loggedin-bar"
            >
              <a-menu-item key="activate" disabled class="menu-activate" v-if="$route.path != '/' + regionId + '/' + websiteId + '/activate'">
                <a-button type="danger" @click="handleActivateClick" class="btn-activate">
                  {{ t('Activate Kit') }}
                </a-button>
              </a-menu-item>
              <a-menu-item key="ancestry" disabled class="menu-ancestry" v-if="user.genebase_login">
                <a-button type="danger" @click="handleAncestryClick" class="btn-ancestry">
                  {{ t('Ancestry Database') }}
                </a-button>
              </a-menu-item>
              <a-menu-item key="support" class="menu-support" v-if="supportUrl">
                <a :href="supportUrl" target="_blank">
                  <img :src="icoHelp" class="ico-help" />
                </a>
              </a-menu-item>
              <a-sub-menu key="sub-menu-account">
                <template #title>
                  <img :src="icoAccount" class="ico-accoount" />
                  <span class="nav-first-name">{{user.first_name}}</span>
                </template>
                <a-menu-item key="edit-account" @click="handleHomeClick">{{ t('My Account') }}</a-menu-item>
                <a-menu-item key="logout" @click="handleLogoutClick">{{ t('Logout') }}</a-menu-item>
              </a-sub-menu>
              <a-menu-item key="cart" class="menu-cart" v-if="cartUrl">
                <a :href="cartUrl" target="_blank">
                  <img :src="icoCart" class="ico-cart" />
                  <span class="nav-shop">{{ t('Shop') }}</span>
                </a>
              </a-menu-item>
            </a-menu>
            
          </div>
        </div>
      </div>
    </a-layout-header>
</template>

<script>
  // import {mapGetters} from 'vuex'
  import axiosUserInstance from '../apiUser'

  // eslint-disable-next-line no-unused-vars
  import t from '../utils'

  export default {
    name: 'NavComponent',
    mounted: function() {
      this.getLogoRequest();
      this.getWebsiteRequest();
      this.$nextTick(function () {
        if (process.env.NODE_ENV == 'development') {
          document.getElementsByClassName('ant-layout-header')[0].style.backgroundColor = '#fff90061';
        }
        else {
          document.getElementsByClassName('ant-layout-header')[0].style.backgroundColor = '#ffffff';
        }
      })
    },
    methods: {
      async getLogoRequest() { 
          let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId +  '/websites/' + this.websiteId + '/logo');
          this.logoUrl = res.data.logo;
      },

      async getWebsiteRequest() {
        let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/websites/' + this.websiteId);
        this.supportUrl = res.data.website.urls.support;
        this.cartUrl = res.data.website.urls.shopping_cart;
        if (this.isLoggedIn) {
          this.homeUrl = '/' + this.regionId  + '/' + this.websiteId + '/account';
        }
        else {
          this.homeUrl = '//' + res.data.website.fqdn;
        }
      },

      handleActivateClick() {
        window.location.href = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/activate';
      },

      handleAncestryClick() {
        window.location.href = this.user.genebase_login;
      },

      handleHomeClick() {
        window.location.href = '/' + this.regionId + '/' + this.websiteId + '/account/';
      },

      handleLogoutClick() {
        window.location.href = '/' + this.regionId + '/' + this.websiteId + '/logout';
      },

    },
    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
    },
    data: function() {
      return {
        regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
        websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
        logoUrl: '',
        homeUrl: '',
        supportUrl: '',
        cartUrl: '',
        icoAccount: require('@/assets/icon-account.svg'),
        icoHelp: require('@/assets/icon-help.svg'),
        icoCart: require('@/assets/icon-cart.svg'),
        t: t
      }
    }
  }
</script> 

<style>
.login-env .header-right > span {
  display:none;
}
.staging {
  color:red;
  font-weight:bold;
  padding:0 10px;
  text-align:center;
}
.app-header-wrapper {
  display:flex;
  height:100%;
  margin-top:10px;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}
.app-header-wrapper.justify {
  justify-content:center;
}
.ant-menu {
  background:transparent;
  color:#1890ff;
  font-size:15px;
}
.ant-menu-inline .ant-menu-item {
  padding-top:0;
  padding-bottom:0;
}
.ant-menu-item > a, .ant-menu-horizontal > .ant-menu-item > a {
  color:#1890ff;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height:42px;
  line-height:42px;
  font-size:1.05rem;
}
.ant-menu-horizontal {
  border-bottom:0;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  color:initial;
  border-bottom-color:transparent;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color:#1890ff !important;
}
.header-left {
  max-width:110px;
  display:flex;
  flex-direction:row;
}
.header-left > a {
  max-height:38px;
  max-width:260px;
}
.header-left > a > img {
  display:block;
  height:100%;
}
.header-right {
  flex:1 0 auto;
  text-align:right;
}
.header-right .ant-menu {
  background:transparent; 
}
.header-right span .name {
  font-weight:bold;
}
.header-mid {
  margin-bottom:5px;
  max-height:38px ;
  text-align:center;
  margin-top:7px;
}
.header-mid > a > img {
  max-height:38px;
  display:block;
  margin-bottom:6px;
}
.header-mid > span {
  display:block;
}
.ant-menu-item-disabled.menu-activate,
.ant-menu-item-disabled.menu-ancestry {
  cursor:pointer;
}
.ant-menu-item-disabled.menu-support,
.ant-menu-item-disabled.menu-support > a {
  cursor:pointer;
  color:inherit !important;
}
.app-header {
  font-size:12px !important;
  padding:0 25px !important;
  top:1px;
  border-bottom:1px solid #ededed;
  z-index:9;
  color:#555;
  height:60px !important;
  line-height:100% !important;
}
.loggedin-bar {
  float:right;
  height:auto;
  padding-right:20px;
  line-height:62px !important;
  font-size:12px !important;
}
.menu-activate, .menu-ancestry, .menu-suppport, .menu-cart {
  padding:0 10px !important;
}
.header-right .ant-menu-submenu-title {
  padding:0 10px !important;
}
.ico-cart {
  vertical-align:middle;
  font-size:170%;
}
.ico-user {
  vertical-align:middle;
  margin-right:5px;
}
.ico-accoount, .ico-help {
  height:32px;
}
.ico-cart {
  height:28px;
}
.ico-accoount,
.ico-cart {
  margin-right:8px;
}
.nav-first-name,
.nav-shop {
  font-size:0.9rem;
}
.sub-menu-account {
  padding:0 10px;
}
@media (max-width: 767px) {
  .header-right {
    display:none;
  }
  .header-left .header-left-nav {
    display:none;
  }
}
</style>