import store from './vuex';

export default function t(key, ...args) {
  let currentIndex = 0;
  const tr = store.state.translation.translation;
  const translated = key in tr ? tr[key] : key + (store.state.translation.language == 'en-us' ? '' : ' ***');
  return translated.replace(/%s/g, () => {
    const value = args[currentIndex];
    currentIndex++;
    return typeof value !== 'undefined' ? value : '';
  });
}