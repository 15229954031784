import Vue from 'vue'
import Router from 'vue-router'
import CreateAccount from './components/CreateAccount.vue'
import Account from './components/Account.vue'
import CollectionConfirm from './components/CollectionConfirm.vue'
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import Logout from './components/Logout.vue'
import OrderConfirm from './components/OrderConfirm.vue'
import Activate from './components/Activate.vue'
import Signup from './components/Signup.vue'
import Contact from './components/Contact.vue'
import Terms from './components/Terms.vue'
import Payment from './components/Payment.vue'
import Privacy from './components/Privacy.vue'
import PasswordRequest from './components/PasswordRequest.vue'
import PasswordReset from './components/PasswordReset.vue'
import PasswordComplete from './components/PasswordComplete.vue'
import PhoneVerify from './components/PhoneVerify.vue'
import PhoneConfirm from './components/PhoneConfirm.vue'
import PhoneSkip from './components/PhoneSkip.vue'
import HealthInfo from './components/HealthInfo.vue'
import TestConfirm from './components/TestConfirm.vue'
import IndividualConfirm from './components/IndividualConfirm.vue'
import Who from './components/Who.vue'
import WhoCannot from './components/WhoCannot.vue'
import Setup from './components/Setup.vue'
import SetupComplete from './components/SetupComplete.vue'
import Maintenance from './components/Maintenance.vue'
import ContactConfirm from './components/ContactConfirm.vue'
// import Register from './components/Register.vue'
// import Forgot from './components/Forgot.vue'
// import Reset from './components/Reset.vue'

import store from './vuex'

// link: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

/*
const isLocalStorageEnabled = (to, from, next) => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, null);
    window.localStorage.removeItem(key);
    next()
    return
  } catch (e) {
    next('/' + to.params.region_id + '/' + to.params.website_id + '/warning')
  }
}
*/

const ifNotAuthenticated = (to, from, next) => {
  if (Object.keys(store.getters.getUser).length === 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/account')
}

const ifAuthenticated = (to, from, next) => {
  if (Object.keys(store.getters.getUser).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/login')
}

const ifActivated = (to, from, next) => {
  if (Object.keys(store.getters.getActivation).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/activate')
}

const ifSignedup = (to, from, next) => {
  if (Object.keys(store.getters.getSignup).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/activate')
}

const ifAuthenticatedOrSignedup = (to, from, next) => {
  if (Object.keys(store.getters.getUser).length !== 0 || Object.keys(store.getters.getSignup).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/login')
}

const ifContact = (to, from, next) => {
  if (Object.keys(store.getters.getContact).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/activate')
}

const ifPhone = (to, from, next) => {
  if (Object.keys(store.getters.getPhone).length !== 0) {
    next()
    return  
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/activate')
}

const ifClientOrder = (to, from, next) => {
  if (Object.keys(store.getters.getClientOrder).length !== 0) {
    next()
    return
  }
  next('/' + to.params.region_id + '/' + to.params.website_id + '/activate')
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:region_id/:website_id/home',
            name: 'Default Home (Temporary)',
            component: Home
        },
        {
            path: '/:region_id/:website_id/',
            name: 'Default (Temporary)',
            component: Home
        },
        {
            path: '/:region_id/:website_id/create-account',
            name: 'Create a New Account',
            component: CreateAccount
        },
        {
            path: '/:region_id/:website_id/activate',
            name: 'Activate',
            component: Activate
        },  
        {
            path: '/:region_id/:website_id/account',
            name: 'Account',
            component: Account,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/:region_id/:website_id/account/:section',
            name: 'Settings - Login',
            component: Account,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/:region_id/:website_id/account/:section/view/:id',
            name: 'Settings - Login',
            component: Account,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/:region_id/:website_id/order-confirmation/:token',
            name: 'OrderConfirm',
            component: OrderConfirm,
        },
        {
            path: '/:region_id/:website_id/login/:style/:token',
            name: 'Login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/:region_id/:website_id/login/:style',
            name: 'Login',
            component: Login
        },
        {
            path: '/:region_id/:website_id/login',
            name: 'Login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/:region_id/:website_id/logout',
            name: 'Logout',
            component: Logout,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/:region_id/:website_id/payment/:order_id',
            name: 'Payment',
            component: Payment
        },
        {
            path: '/:region_id/:website_id/signup/:style',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/:region_id/:website_id/signup',
            name: 'Signup',
            component: Signup,
            beforeEnter: ifNotAuthenticated && ifActivated
        },
        {
            path: '/:region_id/:website_id/test-confirm',
            name: 'TestConfirm',
            component: TestConfirm,
            beforeEnter: ifActivated
        },
        {
            path: '/:region_id/:website_id/individual-confirm',
            name: 'IndividualConfirm',
            component: IndividualConfirm,
            beforeEnter: ifActivated
        },
        {
            path: '/:region_id/:website_id/contact',
            name: 'Contact',
            component: Contact,
            beforeEnter: ifActivated
        },
        {
            path: '/:region_id/:website_id/phone-verify',
            name: 'PhoneVerify',
            component: PhoneVerify,
            beforeEnter: ifNotAuthenticated && ifActivated && ifSignedup && ifContact
        },
        {
            path: '/:region_id/:website_id/phone-confirm',
            name: 'PhoneConfirm',
            component: PhoneConfirm,
            beforeEnter: ifNotAuthenticated && ifActivated && ifSignedup && ifContact && ifPhone
        },
        {
            path: '/:region_id/:website_id/phone-skip',
            name: 'PhoneSkip',
            component: PhoneSkip,
            beforeEnter: ifNotAuthenticated && ifActivated && ifSignedup && ifContact
        },
        {
            path: '/:region_id/:website_id/health-info',
            name: 'HealthInfo',
            component: HealthInfo,
            beforeEnter: ifActivated
        },
        {
            path: '/:region_id/:website_id/collection-confirm',
            name: 'CollectionConfirm',
            component: CollectionConfirm,
            beforeEnter: ifActivated
        },
        {
            path: '/:region_id/:website_id/who',
            name: 'Who',
            component: Who,
            beforeEnter: ifActivated && ifAuthenticatedOrSignedup
        },
        {
            path: '/:region_id/:website_id/who-cannot',
            name: 'WhoCannot',
            component: WhoCannot,
            beforeEnter: ifActivated,
        },
        {
            path: '/:region_id/:website_id/setup',
            name: 'Setup',
            component: Setup,
            beforeEnter: ifActivated && ifContact && ifAuthenticatedOrSignedup
        },
        {
            path: '/:region_id/:website_id/setup-complete/:client_order_id',
            name: 'SetupComplete',
            component: SetupComplete
        },
        {
            path: '/:region_id/:website_id/setup-complete',
            name: 'SetupComplete',
            component: SetupComplete,
            beforeEnter: ifClientOrder
        },
        {
            path: '/:region_id/:website_id/terms',
            name: 'Terms',
            component: Terms
        },
        {
            path: '/:region_id/:website_id/privacy',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/:region_id/:website_id/password/request',
            name: 'PasswordRequest',
            component: PasswordRequest,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/:region_id/:website_id/password/reset/:code',
            name: 'PasswordReset',
            component: PasswordReset,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/:region_id/:website_id/password/complete',
            name: 'PasswordComplete',
            component: PasswordComplete,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/',
            name: 'Default Home (Non-specified)',
            component: Home
        },
        {
            path: '/:region_id/:website_id/maintenance',
            name: 'Maintenance',
            component: Maintenance,
        },
        {
            path: '/maintenance',
            name: 'Maintenance',
            component: Maintenance,
        },
        {
            path: '/:region_id/:website_id/contact-confirm/:order_id',
            name: 'ContactConfirm',
            component: ContactConfirm
        },
    ]
})
