<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash && $route.query.via == 'activate'" step="login" />
        
        <a-row>

            <a-col class="app-login wide">

                <HeaderSgus v-if="regionId == 'SGCA' || regionId == 'SGUS'" />
                <HeaderGvus v-else-if="regionId == 'GVUS' || regionId == 'GCUS'" />
                <HeaderDfus v-else-if="regionId == 'DFUS'" />
                <HeaderStus v-else-if="regionId == 'STUS'" />
                <HeaderGtca v-else-if="regionId == 'GTCA'" />
                <HeaderGtus v-else-if="regionId == 'GTUS'" />
                
                <error v-if="error" :error="error" />

                <div class="order-confirmation-container" v-if="confirm.full_id">
                    <h2>{{ t('Thank you for your order') }}</h2>

                    <p v-if="confirm.product_types && confirm.product_types.relationship == true">
                        {{ t('Next, create an account using your Order ID # and Password to register your test. You will need your account to track the status of your test and access your test results.') }}
                    </p>
                    <p v-else>
                        {{ t('Next, create an account to view your order details. Do not lose your Order ID #.') }}
                    </p>

                    <h3>{{ t('Your Order ID # is') }}: <span>{{ confirm.full_id }}</span></h3>

                    <p v-if="confirm.email">{{ t('A confirmation email has been sent to %s.', confirm.email) }}</p>

                    <a-divider />

                    <div class="order-confirmation-bottom">

                        <div class="left">
                            <div class="login-bordered">
                                <h3>{{ t('Account Login') }}</h3>
                                <FormLogin :full_id="confirm.full_id" :form_style="'order-confirmation'" />
                            </div>
                        </div>
                        <div class="right">
                            <h3>{{ t('Don\'t forget to log into your user account!') }}</h3>
                            <p>{{ t('Easily access and manage your account information, monitor your sample status, and view your results once they are ready!') }}</p>
                            <p><strong>{{ t('View Sample Status') }}</strong> - {{ t('Monitor the status of your test, from shipping and receiving to laboratory testing.') }}</p>
                            <p><strong>{{ t('Access Your Results') }}</strong> - {{ t('As soon as your results are ready, you can access them right away from your account.') }}</p>
                            <p><strong>{{ t('Manage Contact Information') }}</strong> - {{ t('Update your contact information whenever you need to.') }}</p>
                        </div>

                    </div>

                </div>

            </a-col>
            
        </a-row>
    </div>
</template>

<script>
    import axiosUserInstance from '../apiUser'
    import ActivateStep from './ActivateStep.vue'
    import FormLogin from './forms/FormLogin.vue'
    import Error from './Error.vue'

    import HeaderSgus from './headers/HeaderSgus.vue'
    import HeaderGvus from './headers/HeaderGvus.vue'
    import HeaderDfus from './headers/HeaderDfus.vue'
    import HeaderStus from './headers/HeaderStus.vue'
    import HeaderGtca from './headers/HeaderGtca.vue'
    import HeaderGtus from './headers/HeaderGtus.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'OrderConfirmComponent',
        components: {
            ActivateStep,
            FormLogin,
            Error,
            HeaderSgus,
            HeaderGvus,
            HeaderDfus,
            HeaderStus,
            HeaderGtca,
            HeaderGtus
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                token: this.$route.params.token ? this.$route.params.token : '',
                confirm: {
                    full_id: '',
                    email: '',
                    product_types: {}
                },
                error: '',
                errors: [],
                t: t
            }
        },
        mounted: function() {
            // cm: removed this function since access_token is not passed via url. this.loginRedirect();   
            this.validateOrder();
            this.$nextTick(function() {
                this.$store.dispatch('setActivation', {});
                this.$store.dispatch('setUser', {});
                this.$store.dispatch('setSignup', {});
                this.$store.dispatch('setPhone', {});
                this.$store.dispatch('setContact', {});
                this.$store.dispatch('setMedical', {});
            })
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_login' });
        },
        methods: {

            async validateOrder() {
                console.log('this.$route.params.token -> ' + this.token);
                try {
                    if (this.token) {
                        const response = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/confirmation/' + this.token);
                        this.confirm = response.data;

                    }
                } catch(e) {
                    //var err = e.response.data.errors;
                    this.error = t('Error found. Please correct the form below and try again.');
                    this.errors = e.response.data.errors;
                }

            }

        }
    }
</script>

<style>
.login-bordered {
    border-radius:4px;
    border:1px solid #dfdfdf;
    padding:25px;
}
.login-bordered h3 {
    color:#31396d;
    margin-bottom:15px;
    padding-bottom:10px;
    border-bottom:1px solid #dfdfdf;
}
.order-confirmation-container {
    text-align:center;
}
.order-confirmation-container h2,
.order-confirmation-container h3,
.order-confirmation-container p {
    margin:0 0 30px;
}
.order-confirmation-container h3 {
    font-weight:600;
}
.order-confirmation-container h3 > span {
    margin-left:10px;
    color:#336699;
}
.fa-question-circle:hover {
    opacity:0.7;
}
.ant-popover-inner {
    margin-right:20px;
}
.order-confirmation-bottom {
    display:flex;
    flex-direction:row;
    margin:30px 0;
}
.order-confirmation-bottom .left,
.order-confirmation-bottom .right {
    width:50%;
    text-align:left;
}
.order-confirmation-bottom .left {
    margin-right:15px;
}
.order-confirmation-bottom .right {
    margin-left:15px;
    padding-top:15px;
}
.order-confirmation-bottom .right h3 {
    margin-bottom:20px;
    color:#31396d;
    text-transform:uppercase;
}
.order-confirmation-bottom .right p {
    margin-bottom:20px;
}
.order-confirmation-right-col p {
    margin:0 0 30px;
}
@media only screen and (max-width: 767.99px) {
    .order-confirmation-bottom {
        flex-direction:column;
        align-items:center;
    }
    .order-confirmation-bottom .right {
        margin:30px 0;
    }
}
</style>