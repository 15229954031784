<template>

    <div>

        <Alert />
        
        <a-page-header v-if="isLoggedIn"
            style="margin:0 0 24px;"
            class="app-page-title"
            :ghost="false"
            :title="t('Settings')"
        />

        <a-card :bordered="false" class="with-border">

            <a-row>
                <a-col class="form-container">

                    <error v-if="error" :error="error" />

                    <a-form
                        id="form-general"
                        :form="formGeneral"
                        class="general-form"
                        layout="vertical"
                        @submit.prevent="handleGeneralSubmit"
                    >
                        
                        <a-row :gutter="8">
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                :label="t('First Name')"
                                >
                                    <a-input                        
                                        v-decorator="[
                                            'first_name',
                                            {
                                                initialValue: this.user.first_name,
                                                rules: [{ required: false }] 
                                            }
                                        ]"
                                        placeholder=""
                                        size="large"
                                        readonly="1"
                                        disabled="disabled"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                :label="t('Last Name')"
                                >
                                    <a-input                        
                                        v-decorator="[
                                            'last_name',
                                            {
                                                initialValue: this.user.last_name,
                                                rules: [{ required: false }] 
                                            }
                                        ]"
                                        placeholder=""
                                        size="large"
                                        readonly="1"
                                        disabled="disabled"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item
                            :label="t('Phone Number')"
                            style="margin:0;"
                            :help="errors.phone"
                        >

                            <a-input-group compact>
                                <a-select
                                    show-search
                                    :placeholder="t('Select one')"
                                    :filter-option="filterOption"
                                    style="width:45%;"
                                    size="large"
                                    v-decorator="[
                                        'country',
                                        {
                                            initialValue: this.user.phone_country,
                                            rules: [{ required: true, message: t('Please select a country code.') }] 
                                        }
                                    ]"
                                >
                                    <a-select-option v-for="data in codes" :key="data.code">
                                        {{ data.name }} ({{ data.dial_code }})
                                    </a-select-option>
                                </a-select>
                                <a-input
                                    style="width:55%"                    
                                    v-decorator="[
                                        'phone',
                                        {
                                            initialValue: this.user.phone,
                                            rules: [{ required: true, message: t('Please enter your phone number.') }] 
                                        }
                                    ]"
                                    :placeholder="t('Phone Number')"
                                    size="large"
                                    :max-length="20"
                                />
                            </a-input-group>

                        </a-form-item>

                        <a-button :disabled="formGeneralSubmitting" :loading="formGeneralSubmitting" type="primary" html-type="submit" class="button-submit" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formGeneralSubmitting ? t('Submitting...') : t('Update Info') }}
                        </a-button>

                    </a-form>

                    <a-form
                        id="form-email"
                        :form="formEmail"
                        class="email-form"
                        layout="vertical"
                        @submit.prevent="handleEmailSubmit"
                    >

                        <a-form-item
                            :label="t('Email')"
                            style="margin:0;"
                            :help="errors.email"
                            :class="fieldHasError.email"
                        >
                            <a-input                        
                                v-decorator="[
                                'email',
                                    {
                                        rules: [{ required: true, message: t('Please enter your Email.') }],
                                        initialValue: this.user.email
                                    },
                                ]"
                                placeholder="johndoe@mail.com"
                                size="large"
                                
                            />
                        </a-form-item>

                        <a-button :disabled="formEmailSubmitting" :loading="formEmailSubmitting" type="primary" html-type="submit" class="button-submit" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formEmailSubmitting ? t('Submitting...') : t('Update Email') }}
                        </a-button>

                    </a-form>

                    <a-form
                        id="form-password"
                        :form="formPassword"
                        class="password-form"
                        layout="vertical"
                        @submit.prevent="handlePasswordSubmit"
                    >

                        <a-form-item
                            :label="t('Current Password')"
                            :class="fieldHasError.old_password"
                        >
                            <a-input-password
                                v-decorator="[
                                'old_password',
                                    { rules: [
                                        { required: true, message: t('Please enter your current password.') },
                                    ] },
                                ]"
                                placeholder=""
                                size="large"
                            >
                            </a-input-password>
                            <div class="ant-form-explain" v-if="errors.old_password ">{{ errors.old_password }}</div>
                        </a-form-item>
                        <a-form-item
                            :label="t('New Password')"
                            :class="fieldHasError.password"
                        >
                            <a-input-password
                                v-decorator="[
                                'password',
                                    { rules: [
                                        { required: true, message: t('Please enter your new password.') },
                                        { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: 'Use 8 or more characters with a mix of letters and numbers.' }
                                    ] },
                                ]"
                                placeholder=""
                                size="large"
                            >
                            </a-input-password>
                            <div class="ant-form-explain" v-if="errors.password ">{{ errors.password }}</div>
                        </a-form-item>
                        <a-form-item
                            :label="t('Confirm New Password')"
                            style="margin:0;"
                            :class="fieldHasError.re_password"
                        >
                            <a-input-password
                                v-decorator="[
                                're_password',
                                    { rules: [
                                        { required: true, message: t('Please confirm your new password.') },
                                        { validator: checkPassword }
                                    ] },
                                ]"
                                placeholder=""
                                size="large"
                            >
                            </a-input-password>
                            <div class="ant-form-explain" v-if="errors.re_password ">{{ errors.re_password }}</div>
                        </a-form-item>

                        <a-button :disabled="formPasswordSubmitting" :loading="formPasswordSubmitting" type="primary" html-type="submit" class="button-submit" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formPasswordSubmitting ? t('Submitting...') : t('Update Password') }}
                        </a-button>

                    </a-form>

                </a-col>
            </a-row>
            
        </a-card>

    </div>

</template>

<script>

    import axiosUserInstance from '../../apiUser'
    import Alert from '../Alert.vue'
    import Error from '../Error.vue'
    import CountryCodes from '../../assets/country-code.json';

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

    export default {

        name: 'SettingSecurity',

        components: {
            Alert,
            Error
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}
        },

        data: function() {
            return {
                country: '',
                prefix: '',
                phone: '',
                email: '',
                old_password: '',
                password: '',
                re_password: '',
                error: '',
                errors: {},
                fieldHasError: {},
                formEmailSubmitting: false,
                formPasswordSubmitting: false,
                formGeneralSubmitting: false,
                codes: CountryCodes,
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                t: t
            }
        },

        beforeCreate() {
            this.formGeneral = this.$form.createForm(this, { name: 'normal_general' });
            this.formEmail = this.$form.createForm(this, { name: 'normal_email' });
            this.formPassword = this.$form.createForm(this, { name: 'normal_password' });
        },
 
        methods: {

            async handleGeneralSubmit() {

                console.log('submitting general form.');

                var dialCode = '';

                try {

                    this.formGeneralSubmitting = true;

                    const firstGeneralRes = await this.formGeneral.validateFields();

                    if (firstGeneralRes) {

                        for (let i = 0; i < this.codes.length; i++) {
                            if (this.codes[i].code == this.formGeneral.getFieldValue('country')) {
                                dialCode = this.codes[i].dial_code;
                                break;
                            }
                        }
                    
                        const generalRes = await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/account', {
                            phone: firstGeneralRes.phone,
                            phone_country: firstGeneralRes.country,
                            phone_prefix: dialCode,
                            region_id: (this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID)
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (generalRes) {
                            this.error = '';
                            this.errors = {};
                            var dataUser = this.user;
                            dataUser.phone = firstGeneralRes.phone;
                            dataUser.phone_country = firstGeneralRes.country;
                            dataUser.phone_prefix = dialCode;
                            this.$store.dispatch('setUser', dataUser);
                            this.$message.success('Your information has been updated successfully.', 5);
                            this.formGeneralSubmitting = false;
                        }

                    }

                } catch(e) {
                    this.formGeneralSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                }
                
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            async handleEmailSubmit() {

                console.log('submitting email form.');

                try {

                    this.formEmailSubmitting = true;

                    this.fieldHasError = {};

                    const firstEmailRes = await this.formEmail.validateFields();

                    if (firstEmailRes) {
                    
                        const emailRes = await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/account', {
                            email: firstEmailRes.email,
                            region_id: (this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID)
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (emailRes) {
                            this.error = '';
                            this.errors = {};
                            var dataUser = this.user;
                            dataUser.email = firstEmailRes.email;
                            this.$store.dispatch('setUser', dataUser);
                            this.$message.success('Your Email has been updated successfully.', 5);
                            this.formEmailSubmitting = false;
                        }

                    }

                } catch(e) {
                    this.formEmailSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                    console.log('email errors.');
                    console.log(this.errors);
                    this.fieldHasError.email = 'has-error';
                }
                
            },

            async handlePasswordSubmit() {

                try {

                    this.formPasswordSubmitting = true;

                    this.fieldHasError = {};

                    const firstPasswordRes = await this.formPassword.validateFields();

                    if (firstPasswordRes) {
                    
                        const passwordRes = await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/account', {
                            old_password: firstPasswordRes.old_password,
                            password: firstPasswordRes.password,
                            re_password: firstPasswordRes.re_password,
                            region_id: (this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID)
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (passwordRes) {
                            this.error = '';
                            this.errors = {};
                            this.formPassword.resetFields();
                            this.$message.success('Your password has been updated successfully.', 5);
                            this.formPasswordSubmitting = false;
                        }

                    }

                } catch(e) {
                    this.formPasswordSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                    console.log('password errors.');
                    console.log(this.errors);
                    this.fieldHasError.old_password = 'has-error';
                    console.log(this.fieldHasError);
                }
                
            },

            checkPassword(rule, value, callback) {
                if (value === this.formPassword.getFieldValue('password')) {
                    callback();
                    return;
                }
                callback('Passwords must match.');
            }
        }

    }
</script>

<style scoped>

.form-container {
    max-width: 460px;
    margin: 0 auto;
    padding: 10px 0;
}
.general-form, .email-form, .password-form {
    margin-bottom:50px;
}
.form-container .button-submit {
    margin:10px 0 5px;
    width: 100%;
}

</style>