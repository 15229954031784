import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const state = {
    'user': {
        user: {},
        token: navigator.cookieEnabled ? localStorage.getItem('token') : null,
        refreshToken: null
    },
    'activation': {
        activation: {}
    },
    'patients': {
        patients: {}
    },
    'signup': {
        signup: {},
        contact: {},
        phone: {},
        medicalQuestionnaire: {}
    },
    'client_order': {
        clientOrder: {}
    },
    'env': {
        urls: {}
    },
    'website': {
        website: {}
    },
    'translation': {
        language: 'en-us',
        translation: {}
    }
}

const store = new Vuex.Store({
    state,
    getters: {
        getUser: (state) => {
            return state.user.user;
        },
        getActivation: (state) => {
            return state.activation.activation;
        },
        getPatients: (state) => {
            return state.patients.patients;
        },
        getSignup: (state) => {
            return state.signup.signup;
        },
        getContact: (state) => {
            return state.signup.contact;
        },
        getPhone: (state) => {
            return state.signup.phone;
        },
        getMedical: (state) => {
            return state.signup.medicalQuestionnaire;
        },
        getClientOrder: (state) => {
            return state.client_order.clientOrder;
        },
        getToken: (state) => {
            return state.user.token;
        },
        getRefreshToken: (state) => {
            return state.user.refreshToken;
        },
        getEnv: (state) => {
            return state.env.urls;
        },
        getWebsite: (state) => {
            return state.website.website;
        },
        getTranslation: (state) => {
            return state.translation.translation;
        },
        getLanguage: (state) => {
            return state.translation.language;
        },
        isAuthenticated: state => !!state.user.token,
        isActivated: state => !!state.activation.activation,
        isPatients: state => !!state.patients.patients,
        isSignedup: state => !!state.signup.signup,
        isContact: state => !!state.signup.contact,
        isPhone: state => !!state.signup.phone,
        isMedical: state => !!state.signup.medicalQuestionnaire,
        isClientOrder: state => !!state.client_order.clientOrder,
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        setToken(context, token) {
            context.commit('setToken', token);
        },
        setRefreshToken(context, token) {
            context.commit('setRefreshToken', token);
        },
        setPatients(context, patients) {
            context.commit('setPatients', patients);
        },
        setActivation(context, activation) {
            context.commit('setActivation', activation);
        },
        setSignup(context, signup) {
            context.commit('setSignup', signup);
        },
        setContact(context, contact) {
            context.commit('setContact', contact);
        },
        setPhone(context, phone) {
            context.commit('setPhone', phone);
        },
        setMedical(context, medicalQuestionnaire) {
            context.commit('setMedical', medicalQuestionnaire);
        },
        setClientOrder(context, clientOrder) {
            context.commit('setClientOrder', clientOrder);
        },
        setEnv(context, env) {
            context.commit('setEnv', env);
        },
        setWebsite(context, website) {
            context.commit('setWebsite', website);
        },
        setTranslation(context, translation) {
            context.commit('setTranslation', translation);
        },
        setLanguage(context, language) {
            context.commit('setLanguage', language);
        },
    },
    mutations: {
        setUser(state, user) {
            state.user.user = user;
        },
        setToken(state, token) {
            state.user.token = token;
        },
        setRefreshToken(state, token) {
            state.user.refreshToken = token;
        },
        setActivation(state, activation) {
            state.activation.activation = activation;
        },
        setPatients(state, patients) {
            state.patients.patients = patients;
        },
        setSignup(state, signup) {
            state.signup.signup = signup;
        },
        setContact(state, contact) {
            state.signup.contact = contact;
        },
        setPhone(state, phone) {
            state.signup.phone = phone;
        },
        setMedical(state, medicalQuestionnaire) {
            state.signup.medicalQuestionnaire = medicalQuestionnaire;
        },
        setClientOrder(state, clientOrder) {
            state.client_order.clientOrder = clientOrder;
        },
        setEnv(state, env) {
            state.env.urls = env;
        },
        setWebsite(state, website) {
            state.website.website = website;
        },
        setTranslation(state, translation) {
            state.translation.translation = translation;
        },
        setLanguage(state, language) {
            state.translation.language = language;
        },
    },
    plugins: [createPersistedState()]
});

export default store;
