<template>
    <div>
        <ActivateStep v-if="isLoggedIn === false" step="signup" />
        <a-row>
            <a-col class="app-who">

                <h2>Accounts cannot be shared</h2>

                <p>The person who is taking the test must register their own account.</p>
                <p>If the person already has his or her own account, <a @click="handleLoginClick">login to the correct account</a>.</p>
                <p style="margin-bottom:30px;">If the person does not have an account, <router-link :to="'/' + this.regionId + '/' + this.websiteId + '/signup?via=activate'">create a new account</router-link>.</p>

                <p style="margin-bottom:30px;">
                    <router-link :to="'/' + regionId + '/' + websiteId + '/' + (isLoggedIn ? 'who?via=activate' : 'home')">
                        <a-button type="primary" class="who-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            Return to previous page
                        </a-button>
                    </router-link>
                </p>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    // import axios from 'axios'
    // import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'

    export default {
        name: 'WhoCannotComponent',
        components: {
            ActivateStep
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            signup : function(){ return this.$store.getters.getSignup},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
            }
        },
        methods: {
            async handleLoginClick() {
                this.$store.dispatch('setUser', {});
                this.$store.dispatch('setToken', null);
                this.$router.push('/' + this.regionId + '/' + this.websiteId + '/login?via=activate');
            }
        }        
    }
</script>

<style>

.app-who {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}

.who-form-button {
  margin:10px 0 5px;
  width: 100%;
}

</style>