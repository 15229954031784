<template>
  <div id="app">
    <div id="overlay"></div>
    <a-layout>
      <Navs />
      <a-layout-content class="app-main">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="app-footer">
        <div class="app-footer-link" v-if="$route.params.region_id">
          <ul>
            <li><a :href="urls.faq" target="_blank">{{ t('FAQs') }}</a></li>
            <li><a :href="urls.help" target="_blank">{{ t('Help') }}</a></li>
            <li><a :href="urls.contact" target="_blank">{{ t('Contact Us') }}</a></li>
            <li><a :href="urls.privacy" target="_blank">{{ t('Privacy Policy') }}</a></li>
            <li><a :href="urls.terms" target="_blank">{{ t('Terms of Use') }}</a></li>
          </ul>
        </div>
        &copy; {{ new Date().getFullYear() }} {{ caption }}. {{ t('All Rights Reserved.') }}
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Navs from "./components/Nav.vue";

// import axios from "axios";
import axiosUserInstance from './apiUser'

import Vue from "vue";
import Zendesk from "@dansmaculotte/vue-zendesk";

// eslint-disable-next-line no-unused-vars
import t from './utils'

export default {
  name: "App",
  components: {
    Navs,
  },
  watch: {
    $route: {
      handler() {
        this.checkAppEnv();
      },
    },
  },
  mounted: function () {
    // this.checkAppVersion();
    this.getWebsiteRequest();
    this.checkAppEnv();
    this.$nextTick(function () {
      this.checkUserRequest();
    });
  },
  computed: {
    // ...mapGetters(['getUser']),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
      websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
      caption: "",
      homeUrl: "",
      supportUrl: "",
      urls: {
        faq: "#",
        help: "#",
        contact: "#",
        privacy: "#",
        consent: "#",
        terms: "#",
        support: "#",
      },
      zendeskWidget: {
        help_center_chat_button: "",
        help_center_message_button: "",
        help_center_search_placeholder: "",
        help_center_title: "",
        contact_form_subject: "",
        contact_form_title: "",
        contact_options_contact_button: "",
        contact_options_contact_form_label: "",
        color: "",
        snippet_id: "",
      },
      t: t
    };
  },
  methods: {
    checkAppVersion() {
      axiosUserInstance.interceptors.response.use((resp) => {
        // console.log('resp:');
        // console.log(resp);
        let v = resp.headers["vers"] || "default";
        if (v !== localStorage.getItem("vers") && resp.config.method == "get") {
          localStorage.setItem("vers", this.version);
          //window.location.reload() // For new version, simply reload on any get
        }
        return Promise.resolve(resp);
      });
    },
    checkAppEnv() {
      var path = this.$route.path,
        ele = document.getElementById("app"),
        body = document.getElementById("app-body");
      if (
        (path.includes("account") && !path.includes("create-account")) ||
        path.includes("setting")
      ) {
        ele.classList.remove("logout-env");
        ele.classList.add("login-env");
        body.classList.remove("logout-env");
        body.classList.add("login-env");
        // this.$window.zE('webWidget', 'hide');
      } else {
        ele.classList.remove("login-env");
        ele.classList.add("logout-env");
        body.classList.remove("login-env");
        body.classList.add("logout-env");
        // this.$window.zE('webWidget', 'show');
      }
    },
    async getWebsiteRequest() {
      let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/websites/' + this.websiteId);
      this.caption = res.data.website.caption;
      this.homeUrl = "//" + res.data.website.fqdn;
      this.supportUrl = "//" + this.homeUrl.replace("www", "support");
      this.urls = res.data.website.urls;
      // console.log('res.data.website.zendesk_widget.snippet_id');
      // console.log(res.data.website.zendesk_widget);
      // console.log(res.data.website.zendesk_widget.snippet_id ? 'true' : 'false');
      if (res.data.website.zendesk_widget.snippet_id) {
        this.zendeskWidget = {
          help_center_chat_button:
            res.data.website.zendesk_widget.help_center_chat_button,
          help_center_message_button:
            res.data.website.zendesk_widget.help_center_message_button,
          help_center_search_placeholder:
            res.data.website.zendesk_widget.help_center_search_placeholder,
          help_center_title: res.data.website.zendesk_widget.help_center_title,
          contact_form_subject:
            res.data.website.zendesk_widget.contact_form_subject,
          contact_form_title:
            res.data.website.zendesk_widget.contact_form_title,
          contact_options_contact_button:
            res.data.website.zendesk_widget.contact_options_contact_button,
          contact_options_contact_form_label:
            res.data.website.zendesk_widget.contact_options_contact_form_label,
          color: res.data.website.zendesk_widget.color,
          snippet_id: res.data.website.zendesk_widget.snippet_id,
        };

        Vue.use(Zendesk, {
          key: this.zendeskWidget.snippet_id,
          disabled: false,
          hideOnLoad: false,
          settings: {
            webWidget: {
              helpCenter: {
                title: {
                  "*": this.zendeskWidget.help_center_title
                    ? this.zendeskWidget.help_center_titlev
                    : "How can we help?",
                },
                messageButton: {
                  "*": this.zendeskWidget.help_center_message_button
                    ? this.zendeskWidget.help_center_message_button
                    : "Contact Support",
                },
                searchPlaceholder: {
                  "*": this.zendeskWidget.help_center_search_placeholder
                    ? this.zendeskWidget.help_center_search_placeholder
                    : "Type your question...",
                },
              },
              contactForm: {
                title: {
                  "*": this.zendeskWidget.contact_form_title
                    ? this.zendeskWidget.contact_form_title
                    : "Send us an email",
                },
                contactOptions: {
                  contactFormLabel: {
                    "*": this.zendeskWidget.contact_options_contact_form_label
                      ? this.zendeskWidget.contact_options_contact_form_label
                      : "Send us an email",
                  },
                },
              },
            },
          },
        });
      } else {
        var body = document.getElementById("app-body");
        body.classList.add("no-iframe");
      }
      // this.snippetId = '07be89dd-69fe-4a24-9392-909a900a6c62';
      this.$store.dispatch("setWebsite", res.data.website);
    },
    async checkUserRequest() {
      if (this.isLoggedIn) {
        if (
          this.user.region_id != this.regionId ||
          this.user.website_id != this.websiteId
        ) {
          var qualified = false;
          if (
            this.user.redirect === undefined ||
            this.user.redirect === false
          ) {
            // Nothing.
          } else {
            if (
              this.user.redirect.region_id == this.regionId &&
              this.user.redirect.website_id == this.websiteId
            ) {
              qualified = true;
            }
          }

          if (qualified === false) {
            var div = document.getElementById("overlay");
            div.style.display = "block";
            window.location.href =
              "/" + this.regionId + "/" + this.websiteId + "/home";
            this.$store.dispatch("setUser", {});
            this.$store.dispatch("setToken", null);
          }
        }
      }
    },
  },
};
</script>

<style>
html,
body,
#app,
.ant-layout,
#user-console {
  height: 100%;
  margin: 0;
}
h2 {
  line-height: 130%;
  font-size: 1.6rem;
  font-weight: 400 !important;
  color: #31396d !important;
}
button:hover {
  opacity: 0.9;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;
}
.login-env iframe,
.no-iframe iframe {
  display: none !important;
}
.ant-layout {
  background: #f6fafd !important;
}
.ant-message {
  top: 70px;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  border-color: #999 !important;
}
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff !important;
}
.ant-radio-inner::after {
  width: 12px !important;
  height: 12px !important;
}
.ant-input {
  height: 40px;
}
.ant-btn-sm {
  font-size: 12px;
}
.app-main {
  background-color: #fff !important;
  flex: 1 0 auto !important;
}
.app-footer {
  font-size: 12px !important;
  border-top: 1px solid #ededed;
  background-color: #fff !important;
  height: 70px;
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: column !important;
  text-align: center;
  justify-content: center;
  background-color: #f4f4f4;
}
.app-footer-link {
  margin: 0 0 15px;
}
.app-footer-link ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-footer-link ul li {
  list-style: none;
  margin: 0;
  padding: 0 15px;
  border-right: 1px solid #ededed;
  display: inline-block;
}
.app-footer-link ul li:last-child {
  border-right: 0;
}
.ant-alert {
  margin: 0 0 25px !important;
}
@media (max-width: 400px) {
  .app-footer-link ul li {
    display: block;
    border: 0;
    margin-bottom: 10px;
  }
}
</style>