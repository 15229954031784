<template>
    <div>
        <ActivateStep step="signup" />
        <a-row>
            <a-col class="app-test-confirm">

                <h2 v-if="$route.query.via == 'activate'">{{ t('You are activating Kit ID %s, %s', activation.input.activation_code_code, activation.input.product_labels[0]) }}</h2>

                <div v-if="questionnaire.length == 0">
                    <p style="margin-bottom:30px;">{{ t('This page cannot continue since no questionnaire is found.') }}</p>
                </div>
                <div v-else>
                    <p style="margin-bottom:30px;font-size:110%;" v-if="$store.getters.getActivation.input.product_types.alinity == false" v-html="activationText"></p>
                </div>

                <error v-if="error" :error="error" />

                <a-form-model
                    id="form-normal-test-confirm"
                    :form="form"
                    class="test-confirm-form"
                    layout="vertical"
                    @submit.prevent="handleTestConfirmSubmit"
                >

                    <li v-for="(data, index) in this.questionnaire" :key="index">
                        <a-form-item style="margin:0;padding:0;">

                            <div :class="'activation-question-section ' + data.type">

                                <label :for="'answer-' + data.id" v-if="data.type == 'checkbox'">
                                    <span class="left">
                                        <a-checkbox
                                            v-model="answers[data.id]"
                                            :id="'answer-' + data.id"
                                        />
                                    </span>
                                    <span class="right">
                                        <div v-html="data.question"></div>
                                        <div v-if="errors[data.id]" class="question-error" style="color:#f5222d;margin:10px 0;">
                                            {{ errors[data.id] }}
                                        </div>
                                    </span>
                                </label>

                                <div :for="'answer-' + data.id" v-if="data.type == 'radio'" class="by-radio">

                                    <div v-html="data.question" class="radio-header"></div>

                                    <a-radio-group v-model="answers[data.id]" :id="'answers-' + data.id" @change="handleTestConfirmSingleSubmit(data.id)">
                                        <li v-for="(option, optionIndex) in data.options" :key="optionIndex">
                                            <a-radio :value="option.value" :id="'answers-' + data.id + '-' + option.value">{{ option.label }}</a-radio>
                                        </li>
                                    </a-radio-group>

                                    <div v-if="errors[data.id]" class="question-error" style="color:#f5222d;margin:10px 0;">
                                        {{ errors[data.id] }}
                                    </div>

                                </div>

                            </div>

                            <a-divider v-if="data.type == 'radio'" />

                        </a-form-item>
                    </li>

                    <!--

                    <a-form-item style="margin:0;">
                        
                        <div style="margin-bottom:20px;">
                            <span style="flex:0;margin-right:10px;">
                                <a-checkbox
                                    v-model="is_test_correct"
                                    @change="checkIsTestCorrect"
                                />
                            </span>
                            <span style="flex:1;">
                                I agree that this is the correct test: <strong>{{ activation.input.product_labels[0] }}</strong>    
                            </span>

                            <div v-if="errors['is_test_correct']" style="color:red;margin-bottom:10px;">
                                {{ errors.is_test_correct }}
                            </div>
                            
                        </div>

                        <div style="margin-bottom:20px;">
                            <span style="flex:0;margin-right:10px;">
                                <a-checkbox
                                    v-model="is_individual_correct"
                                    @change="checkIsIndividualCorrect"
                                />
                            </span>
                            <span style="flex:1;">
                                <span v-if="$store.getters.getActivation.input.product_id == 1">
                                    I understand that the sample MUST be taken from the alleged father, not his sister (aunt) or parents (grandparentage).
                                    I understand that if the alleged father is not available for testing, I must <a href="https://support.genovateconnect.com/hc/en-us/articles/4404541857805-How-do-I-contact-Genovate" target="_blank">contact the laboratory</a> the laboratory to change the test type before proceeding and extra costs will apply for a different test type.
                                </span>
                                <span v-else>
                                    I understand that the sample MUST be taken from the assigned individuals for {{ activation.input.product_labels[0] }}.
                                    I understand that if any of the individuals is not available for testing, I must <a href="https://support.genovateconnect.com/hc/en-us/articles/4404541857805-How-do-I-contact-Genovate" target="_blank">contact the laboratory</a> the laboratory to change the test type before proceeding and extra costs will apply for a different test type.
                                </span>
                            </span>
                            <div v-if="errors['is_individual_correct']" style="color:red;margin-bottom:10px;">
                                {{ errors.is_individual_correct }}
                            </div>
                        </div>

                    </a-form-item>

                    -->

                    <a-form-item style="margin-bottom:30px;">
                        <a-button type="primary" html-type="submit" class="test-confirm-form-button" size="large" :disabled="questionnaire.length == 0" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ t('Continue') }}
                        </a-button>
                    </a-form-item>

                </a-form-model>

            </a-col>
        </a-row>
    </div>
</template>


<script>

    import axiosUregInstance from '../apiUreg'

    // import axios from 'axios'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'TestConfirm',
        components: {
            Error,
            ActivateStep
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            signup : function(){ return this.$store.getters.getSignup},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            this.getQuestionnaireList();

        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                activationText: '',
                questionnaire: {},
                answers: {},
                error: '',
                errors: [],
                t: t
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_test_confirm' });
        },
        methods: {

            async getQuestionnaireList() {

                let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/activation-questionnaire/' + this.$store.getters.getActivation.input.product_offer_id, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.activationText = res.data.activation_text;
                this.questionnaire = res.data.questionnaire;

                if (Object.keys(this.questionnaire).length == 0) {
                    this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/individual-confirm', query: { via: 'activate' } } );
                }

            },

            async handleTestConfirmSingleSubmit(q) {

                this.errors[q] = '';

                var isAllowable = false;

                for (var i=0; i<this.questionnaire.length; i++) {

                    if (this.questionnaire[i].id == q) {
                        
                        for (var j=0; j<this.questionnaire[i].options.length; j++) {
                            if (this.questionnaire[i].options[j].value == this.answers[q] && this.questionnaire[i].options[j].is_allowable == true) {
                                isAllowable = true;
                                break;
                            }
                        }

                        console.log('isAllowable => ' + (isAllowable ? 'true' : 'false'))

                        if (isAllowable == false) {
                            this.errors[q] = this.questionnaire[i].text_if_not_allowable;
                        }
                        break;
                    }
                }

            },
                
            async handleTestConfirmSubmit() {

                this.error = '';
                this.errors = [];

                try {

                    this.formSubmitting = true;

                    await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/activation-questionnaire/' + this.$store.getters.getActivation.input.product_offer_id, {
                        'answers': this.answers
                    });

                    this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/individual-confirm', query: { via: 'activate' } } );
                    
                } catch(e) {
                    this.formSubmitting = false;
                    //var err = e.response.data.errors;
                    this.error = t('Error found. Please correct the form below and try again.');
                    this.errors = e.response.data.errors;

                }
                
            },

            checkIsTestCorrect(e) {
                this.is_test_correct = e.target.checked;
            },

            checkIsIndividualCorrect(e) {
                this.is_individual_correct = e.target.checked;
            }
            
        }        
    }
</script>

<style>
.activation-question-section > label {
    display:flex;
    flex-direction:row;
}
.activation-question-section > label .left {
    flex:0;
    margin-right:10px;
}
.activation-question-section > label .right {
    flex:1;
}
.activation-question-section.checkbox {
    margin-bottom:10px;
}
.activation-question-section.checkbox .right {
    margin-bottom:10px;
}
.activation-question-section .by-radio {
    margin:0;
}
.activation-question-section .by-radio .ant-radio-wrapper {
    margin-bottom:8px;
    display:flex;
    align-items:flex-start;
}
.activation-question-section .by-radio .ant-radio-wrapper > span {
    flex:1;
    white-space:normal;
}
.activation-question-section .by-radio .ant-radio-wrapper .ant-radio {
    flex:0;
    margin-right:10px;
    margin-top:2px;
}
.activation-question-section .by-radio .radio-header {
    margin-bottom:10px;
    font-size:110%;
}
.app-test-confirm {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-checkbox-inner {
  border:2px solid #1890ff;
}
#form-normal-who .test-confirm-form-button {
  margin:10px 0 5px;
  width: 100%;
}

</style>