<template>
    <div>
        
        <a-row>

            <a-col class="app-contact-confirm">
                
                <FormContactConfirm />

            </a-col>
            
        </a-row>
    </div>
</template>

<script>
    
    // import ActivateStep from './ActivateStep.vue'
    import FormContactConfirm from './forms/FormContactConfirm.vue'

    export default {
        name: 'ContactConfirmComponent',
        components: {
            FormContactConfirm
        }
    }
</script>

<style>
.app-contact-confirm {
  max-width:480px;
  margin:0 auto;
  padding:30px 20px 0;
}
.app-contact-confirm.wide {
  max-width:920px;
}
</style>