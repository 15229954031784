<template>
    <div>
        <ActivateStep step="signup" />
        <a-row>
            <a-col class="app-individual-confirm">

                <h2 v-if="$route.query.via == 'activate'">{{ t('You are activating Kit ID %s, %s', activation.input.activation_code_code, activation.input.product_labels[0]) }}</h2>

                <error v-if="error" :error="error" />

                <div v-if="$store.getters.getActivation.input.product_set_templates.length > 1">
                    <p>Who will be taking the {{ activation.input.product_labels[0] }} (select one):</p>
                    <a-radio-group v-model="product_set_template_id" @change="updatePcsList">
                        <div v-for="(item, index) in $store.getters.getActivation.input.product_set_templates" :key="index" style="margin-right:20px;margin-bottom:5px;">
                            <a-radio :value="item.id">{{ item.caption }}</a-radio>
                        </div>
                    </a-radio-group>                    
                    <a-divider />
                </div>

                <div v-show="($store.getters.getActivation.input.product_set_templates.length == 1 || product_set_template_id != '') || ($store.getters.getActivation.input.product_set_templates.length == 0 && $store.getters.getActivation.input.client_order_id_shipped_with != '')">

                    <p style="margin-bottom:30px;" v-if="$store.getters.getActivation.input.product_types.alinity == false">{{ t('Please provide the information below for each individual being tested.') }}</p>

                    <a-form-model
                        id="form-normal-individual-confirm"
                        :form="form"
                        class="individual-confirm-form"
                        layout="vertical"
                        @submit.prevent="handleTestConfirmSubmit"
                    >

                        <div :key="componentKey">

                            <li v-for="(data, index) in this.pcsList" :key="index">
                                
                                <div v-if="(isPaternity == true || isMaternity == true) && data.code == 'AF' && data.number == 1">
                                    <div style="margin-bottom:5px;">
                                        <span v-if="numFathers == 1">
                                            {{ t('Is the Alleged Father available for testing?') }}
                                        </span>
                                        <span v-else>
                                            {{ t('Are all Alleged Fathers available for testing?') }}
                                        </span>
                                        
                                    </div>
                                    <div style="margin-bottom:20px;">
                                        <a-radio-group v-model="is_father_available" @change="checkIfFatherAvailable">
                                            <span v-for="(item, index) in booleanOptions" :key="index" style="margin-right:20px;">
                                                <a-radio :value="index">{{ item }}</a-radio>
                                            </span>
                                        </a-radio-group>
                                    </div>
                                    <div v-if="is_father_available == 'n'">
                                        <div data-show="true" class="ant-alert ant-alert-error ant-alert-banner" style="margin:0 0 20px !important;">
                                            <i aria-label="icon: close-circle" class="anticon anticon-close-circle ant-alert-icon">
                                                <svg viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="">
                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                                                </svg>
                                            </i>
                                            <span class="ant-alert-message">
                                                <span v-html="switchTestText"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="isPaternity == false || ((isPaternity == true || isMaternity == true) && is_father_available == 'y')" :class="'component-container ' + data.code">

                                    <h3>{{ t('%s\'s Information', data.label) }}<span></span></h3>

                                    <div class="component-content">

                                        <div v-if="data.code != 'AF' || (data.code == 'AF' && is_father_available == 'y')">
                                            <a-row :gutter="8">
                                                <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                    <a-form-item
                                                        :label="t('%s\'s First Name', data.label)"
                                                        required
                                                        :class="fieldHasError[data.code + '-' + data.number + '-first_name']"
                                                    >
                                                        <a-input                        
                                                            v-model="first_name[data.code + '-' + data.number]"
                                                            size="large"
                                                        />
                                                        <div v-for="(errorMessage, field) in errors.first_name" :key="field">
                                                            <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                                {{ errorMessage }}
                                                            </div>
                                                        </div>
                                                    </a-form-item>
                                                </a-col>
                                                <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                    <a-form-item
                                                        :label="t('%s\'s Last Name', data.label)"
                                                        required
                                                        :class="fieldHasError[data.code + '-' + data.number + '-last_name']"
                                                    >
                                                        <a-input                        
                                                            v-model="last_name[data.code + '-' + data.number]"
                                                            size="large"
                                                        />
                                                        <div v-for="(errorMessage, field) in errors.last_name" :key="field">
                                                            <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                                {{ errorMessage }}
                                                            </div>
                                                        </div>
                                                    </a-form-item>
                                                </a-col>
                                            </a-row>

                                            <div style="margin-bottom:20px;" :class="fieldHasError[data.code + '-' + data.number + '-birth_date']">
                                                <div class="ant-col ant-form-item-label ant-form-item-required">
                                                    <label>{{ t('%s\'s Birth Date', data.label) }}</label>
                                                </div>

                                                <dropdown-datepicker
                                                    v-model="birth_date[data.code + '-' + data.number]"
                                                    :dropdownClass="'birth_date-' + data.code + '-' + data.number"
                                                    :maxYear="maxYear"
                                                    :minYear="minYear"
                                                    :daySuffixes="false"
                                                />
                                                
                                                <div v-for="(errorMessage, field) in errors.birth_date" :key="field">
                                                    <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                        {{ errorMessage }}
                                                    </div>
                                                </div>
                                            </div>

                                            <a-form-item
                                                :label="t('%s\'s Sex at Birth', data.label)"
                                                required
                                                v-if="data.gender == '-'"
                                                :class="fieldHasError[data.code + '-' + data.number + '-gender']"
                                            >
                                                
                                                <a-radio-group v-model="gender[data.code + '-' + data.number]">
                                                    <span v-for="(item, index) in genderOptions" :key="index" style="margin-right:20px;">
                                                        <a-radio :value="index">{{ item }}</a-radio>
                                                    </span>
                                                </a-radio-group>
                                                <div v-for="(errorMessage, field) in errors.gender" :key="field">
                                                    <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                        {{ errorMessage }}
                                                    </div>
                                                </div>

                                            </a-form-item>

                                            <a-form-item
                                                :label="t('%s\'s Race', data.label)"
                                                required
                                                :class="fieldHasError[data.code + '-' + data.number + '-ethnicity']"
                                            >
                                                
                                                <a-select
                                                    show-search
                                                    :placeholder="t('Select one')"
                                                    :filter-option="filterOption"
                                                    option-filter-prop="children"
                                                    size="large"
                                                    v-model="ethnicity[data.code + '-' + data.number]"
                                                >
                                                    <a-select-option v-for="data in ethnicityList" :key="data.key">
                                                        {{ data.value }}
                                                    </a-select-option>
                                                </a-select>
                                                <div v-for="(errorMessage, field) in errors.ethnicity" :key="field">
                                                    <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                        {{ errorMessage }}
                                                    </div>
                                                </div>

                                            </a-form-item>

                                            <a-form-item
                                                :class="fieldHasError[data.code + '-' + data.number + '-confirm']"
                                                style="margin-bottom:0;"
                                            >

                                                <div class="component-checkbox">

                                                    <label :for="'confirm-' + data.code + '-' + data.number">

                                                        <span class="left">
                                                            <a-checkbox
                                                                v-model="confirm[data.code + '-' + data.number]"
                                                                :id="'confirm-' + data.code + '-' + data.number"
                                                            />
                                                        </span>
                                                        <span class="right">
                                                            <div>
                                                                {{ t('I confirm that I will collect 2 buccal swab samples from the %s using the supplies provided in the kit.', data.label) }}
                                                                {{ t('I understand that if I do not follow the instructions, I will not get a result.') }}
                                                            </div>
                                                            <div v-for="(errorMessage, field) in errors.confirm" :key="field">
                                                                <div v-if="field == data.code + '-' + data.number" class="ant-form-explain">
                                                                    {{ errorMessage }}
                                                                </div>
                                                            </div>
                                                        </span>
                                                    
                                                    </label>
                                                    
                                                </div>

                                            </a-form-item>

                                        </div>

                                    </div>

                                </div>

                            </li>

                            <li v-if="isMotherAddable == true">

                                <div v-if="isPaternity == false || (isPaternity == true && is_father_available == 'y')">

                                    <p style="margin-bottom:5px;">{{ t('Please note that testing the mother is optional. The %s can be completed without testing the mother. Will the mother be testing?', activation.input.product_labels[0]) }}</p>
                                    <div style="margin-bottom:20px;">
                                        <a-radio-group v-model="is_mother_tested">
                                            <span v-for="(item, index) in booleanOptions" :key="index" style="margin-right:20px;">
                                                <a-radio :value="index">{{ item }}</a-radio>
                                            </span>
                                        </a-radio-group>
                                    </div>
                                    
                                    <div v-if="is_mother_tested == 'y'">
                                        <div class="component-container M">

                                            <h3>{{ t('%s\'s Information', 'Mother') }}</h3>

                                            <div class="component-content">

                                                <a-row :gutter="8">
                                                    <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                        <a-form-item
                                                            :label="t('%s\'s First Name', 'Mother')"
                                                            required
                                                            :class="fieldHasError['M-1-first_name']"
                                                        >
                                                            <a-input                        
                                                                v-model="first_name['M-1']"
                                                                size="large"
                                                            />
                                                            <div v-for="(errorMessage, field) in errors.first_name" :key="field">
                                                                <div v-if="field == 'M-1'" class="ant-form-explain">
                                                                    {{ errorMessage }}
                                                                </div>
                                                            </div>
                                                        </a-form-item>
                                                    </a-col>
                                                    <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                                        <a-form-item
                                                            :label="t('%s\'s Last Name', 'Mother')"
                                                            required
                                                            :class="fieldHasError['M-1-last_name']"
                                                        >
                                                            <a-input                        
                                                                v-model="last_name['M-1']"
                                                                size="large"
                                                            />
                                                            <div v-for="(errorMessage, field) in errors.last_name" :key="field">            
                                                                <div v-if="field == 'M-1'" class="ant-form-explain">
                                                                    {{ errorMessage }}
                                                                </div>
                                                            </div>
                                                        </a-form-item>
                                                    </a-col>
                                                </a-row>

                                                <div style="margin-bottom:20px;" :class="fieldHasError['M-1-birth_date']">
                                                    <div class="ant-col ant-form-item-label ant-form-item-required"><label>{{ t('%s\'s Birth Date', 'Mother') }}</label></div>

                                                    <dropdown-datepicker
                                                        v-model="birth_date['M-1']"
                                                        :maxYear="maxYear"
                                                        :minYear="minYear"
                                                        :daySuffixes="false"
                                                    />
                                                    <div v-for="(errorMessage, field) in errors.birth_date" :key="field">
                                                        <div v-if="field == 'M-1'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <a-form-item
                                                    :label="t('%s\'s Race', 'Mother')"
                                                    required
                                                    :class="fieldHasError['M-1-ethnicity']"
                                                >
                                                    
                                                    <a-select
                                                        show-search
                                                        :placeholder="t('Select one')"
                                                        :filter-option="filterOption"
                                                        option-filter-prop="children"
                                                        size="large"
                                                        v-model="ethnicity['M-1']"
                                                    >
                                                        <a-select-option v-for="data in ethnicityList" :key="data.key">
                                                            {{ data.value }}
                                                        </a-select-option>
                                                    </a-select>
                                                    <div v-for="(errorMessage, field) in errors.ethnicity" :key="field">
                                                        <div v-if="field == 'M-1'" class="ant-form-explain">
                                                            {{ errorMessage }}
                                                        </div>
                                                    </div>

                                                </a-form-item>

                                                <a-form-item
                                                    :class="fieldHasError['M-1-confirm']"
                                                    style="margin-bottom:0;"
                                                >

                                                    <div class="component-checkbox">

                                                        <label for="confirm-M-1">

                                                            <span class="left">
                                                                <a-checkbox
                                                                    v-model="confirm['M-1']"
                                                                    id="'confirm-M-1"
                                                                />
                                                            </span>
                                                            <span class="right">
                                                                <div>
                                                                    {{ t('I confirm that I will collect 2 buccal swab samples from the biological Mother using the supplies and instructions provided in the kit. I understand that if I do not follow the instructions, I will not get a result.') }}
                                                                </div>
                                                                <div v-for="(errorMessage, field) in errors.confirm" :key="field">
                                                                    <div v-if="field == 'M-1'" class="ant-form-explain">
                                                                        {{ errorMessage }}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        
                                                        </label>
                                                        
                                                    </div>

                                                </a-form-item>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </li>

                        </div>

                        <a-form-item style="margin-bottom:30px;">
                            <a-button type="primary" html-type="submit" class="individual-confirm-form-button" size="large" :disabled="isButtonDisabled" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ t('Continue') }}
                            </a-button>
                        </a-form-item>

                    </a-form-model>
                
                </div>

            </a-col>
        </a-row>
    </div>
</template>


<script>

    import axiosUregInstance from '../apiUreg'

    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'
    import moment from 'moment'
    import DropdownDatepicker from '../../node_modules/vue-dropdown-datepicker/src/dropdown-datepicker.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'IndividualConfirmComponent',
        components: {
            Error,
            ActivateStep,
            DropdownDatepicker
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            signup : function(){ return this.$store.getters.getSignup},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite},
            switchTestText() {
                return t('The Alleged Father\'s sample MUST be taken from the alleged father. If the Alleged Father is not available for testing but another member of the family is available (grandmother, sister, etc.), <a href="%s" target="_blank">contact the laboratory</a> to change the test to a DNA Grandmother or Sibling test. Extra charges will apply.', this.website.urls.contact);
            }
        },
        mounted: function() {
            this.getPcsList();
            this.getEthnicityList();
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                genderOptions: {'m': t('Male'), 'f': t('Female')},
                booleanOptions: {'y': t('Yes'), 'n': t('No')},
                componentKey: 0,
                error: '',
                product_set_template_id: '',            
                errors: {},
                fieldHasError: {},
                pcsList: [],
                ethnicityList: [],
                maxYear: moment().year(),
                minYear: moment().year() - 121,
                is_mother_tested: 'n',
                is_father_available: '',
                isMotherAddable: false,
                isPaternity: false,
                isMaternity: false,
                isButtonDisabled: false,
                numFathers: 0,
                first_name: {},
                last_name: {},
                gender: {},
                birth_date: {},
                ethnicity: {},
                patients: {},
                confirm: {},
                t: t
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_test_confirm' });
        },
        methods: {

            async getEthnicityList() {

                let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/ethnicity/select-options', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.ethnicityList = Object.keys(res.data.options).map(key => {
                    return res.data.options[key];
                });

            },

            async getPcsList() {

                let uri = this.regionId + '/' + this.websiteId + '/';

                if (this.$store.getters.getActivation.input.default_product_set_template_ids.length == 0) {
                    uri += 'orders/' + this.$store.getters.getActivation.input.client_order_id_shipped_with + '/components';
                }
                else {
                    uri += 'product-set-templates/' + this.$store.getters.getActivation.input.default_product_set_template_ids[0] + '/components';
                }

                if ('custom_components' in this.activation.input && this.activation.input.custom_components !== null) {
                    uri += '?custom_components=' + this.activation.input.custom_components;
                }

                let res = await axiosUregInstance.get(uri, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.pcsList = Object.keys(res.data.components).map(key => {
                    return res.data.components[key];
                });

                // console.log('this.pcsList');
                // console.log(this.pcsList);

                this.isMotherAddable = this.activation.input.is_retail ? res.data.meta.is_mother_addable.retail : res.data.meta.is_mother_addable.online;
                this.isPaternity = res.data.meta.is_paternity;
                this.isMaternity = res.data.meta.is_maternity;

                for (var k = 0; k < this.pcsList.length; k++) {
                    if (this.pcsList[k].code == 'AF') {
                        this.numFathers++;
                    }
                }

                // console.log('this.numFathers');
                // console.log(this.numFathers);

                this.checkIfFatherAvailable();

            },

            async updatePcsList() {

                var pcs = this.$store.getters.getActivation.input.product_set_templates;

                for (var i=0; i<pcs.length; i++) {

                    if (pcs[i].id == this.product_set_template_id) {
                        
                        this.pcsList = pcs[i].components;

                        this.first_name = {};
                        this.last_name = {};
                        this.gender = {};
                        this.birth_date = {};
                        this.ethnicity = {};
                        this.confirm = {};

                        this.componentKey += 1;

                        this.$forceUpdate();
                        
                        // console.log('birth_date');
                        // console.log(this.birth_date);
                        // console.log(this.pcsList);

                    }

                }

                // console.log(this.pcsList);
                // console.log(this.product_set_template_id);

            },

            async handleTestConfirmSubmit() {
                try {

                    this.formSubmitting = true; 

                    this.error = '';
                    this.errors = {};
                    this.fieldHasError = {};

                    console.log('this.pcsList');
                    console.log(this.pcsList);

                    for (var k = 0; k < this.pcsList.length; k++) {
                        this.patients[this.pcsList[k].code] = {};
                    }

                    if (this.is_mother_tested == 'y') {
                        this.patients['M'] = {};
                    }

                    for (var j = 0; j < this.pcsList.length; j++) {
                        this.patients[this.pcsList[j].code][this.pcsList[j].number] = {};
                    }

                    if (this.is_mother_tested == 'y') {
                        this.patients['M'][1] = {};
                    }

                    for (var i = 0; i < this.pcsList.length; i++) {

                        var fieldKey = this.pcsList[i].code + '-' + this.pcsList[i].number;

                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['first_name'] = this.first_name[fieldKey] === undefined ? '' : this.first_name[fieldKey];
                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['last_name'] = this.last_name[fieldKey] === undefined ? '' : this.last_name[fieldKey];
                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['gender'] = this.gender[fieldKey] === undefined ? '' : this.gender[fieldKey];
                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['birth_date'] = this.birth_date[fieldKey] === undefined ? '' : this.birth_date[fieldKey];
                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['ethnicity'] = this.ethnicity[fieldKey] === undefined ? '' : this.ethnicity[fieldKey];
                        this.patients[this.pcsList[i].code][this.pcsList[i].number]['confirm'] = this.confirm[fieldKey] === undefined || this.confirm[fieldKey] === false ? 0 : 1;

                    }

                    if (this.is_mother_tested == 'y') {

                        this.patients['M'][1]['first_name'] = this.first_name['M-1'] === undefined ? '' : this.first_name['M-1'];
                        this.patients['M'][1]['last_name'] = this.last_name['M-1'] === undefined ? '' : this.last_name['M-1'];
                        this.patients['M'][1]['gender'] = this.gender['M-1'] === undefined ? '' : this.gender['M-1'];
                        this.patients['M'][1]['birth_date'] = this.birth_date['M-1'] === undefined ? '' : this.birth_date['M-1'];
                        this.patients['M'][1]['ethnicity'] = this.ethnicity['M-1'] === undefined ? '' : this.ethnicity['M-1'];
                        this.patients['M'][1]['confirm'] = this.confirm['M-1'] === undefined || this.confirm['M-1   '] === false ? 0 : 1;

                    }

                    console.log('this.patients');
                    console.log(this.patients);

                    var formInputs = {
                        'patients': this.patients
                    };

                    if ('custom_components' in this.activation.input && this.activation.input.custom_components !== null) {
                        formInputs['custom_components'] = this.activation.input.custom_components;
                    }

                    console.log('this.$store.getters.getActivation.input.default_product_set_template_ids.length');
                    console.log(this.$store.getters.getActivation.input.default_product_set_template_ids.length);

                    if (this.$store.getters.getActivation.input.default_product_set_template_ids.length == 0) {
                        formInputs['client_order_id'] = this.$store.getters.getActivation.input.client_order_id_shipped_with;formInputs['client_order_id'] = this.$store.getters.getActivation.input.client_order_id_shipped_with;
                    }
                    else {
                        formInputs['product_set_template_id'] = this.product_set_template_id ? this.product_set_template_id : this.$store.getters.getActivation.input.default_product_set_template_ids[0];
                    }

                    console.log('formInputs');
                    console.log(formInputs);

                    let response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/validate-patients', formInputs);

                    this.$store.dispatch('setPatients', response.data.patients);

                    if (this.isLoggedIn) {
                        window.location.href = '/' + this.regionId + '/' + this.websiteId + '/contact?via=activate';
                    }
                    else {
                        window.location.href = '/' + this.regionId + '/' + this.websiteId + '/signup?via=activate';
                    }

                    this.formSubmitting = false;
                    
                } catch(e) {

                    // console.log('error found.');
                    // console.log(e.response.data.errors.patients);
                    // console.log();

                    var numErrors = Object.keys(e.response.data.errors.patients).length;

                    if (numErrors > 0) {
                        for (const rel in e.response.data.errors.patients) {
                            for (const num in e.response.data.errors.patients[rel]) {
                                var errorKey = rel + '-' + num;
                                for (const field in e.response.data.errors.patients[rel][num]) {
                                    this.errors[field] = {};
                                    this.fieldHasError[errorKey + '-' + field] = '';
                                }
                            }
                        }
                        for (const rel in e.response.data.errors.patients) {
                            for (const num in e.response.data.errors.patients[rel]) {
                                errorKey = rel + '-' + num;
                                for (const field in e.response.data.errors.patients[rel][num]) {
                                    this.errors[field][errorKey] = {};
                                }
                            }
                        }
                        for (const rel in e.response.data.errors.patients) {
                            for (const num in e.response.data.errors.patients[rel]) {
                                errorKey = rel + '-' + num;
                                for (const field in e.response.data.errors.patients[rel][num]) {
                                    // console.log(errorKey + '...' + field + ' ... ' + e.response.data.errors.patients[rel][num][field]);
                                    this.errors[field][errorKey] = e.response.data.errors.patients[rel][num][field];
                                    this.fieldHasError[errorKey + '-' + field] = 'has-error';
                                }
                            }
                        }

                    }

                    this.formSubmitting = false;
                    this.error = t('Error found. Please correct the form below and try again.');

                }
                
            },

            async checkIfFatherAvailable() {

                if (this.isPaternity == true) {
                    if (this.is_father_available == 'y') {
                        this.isButtonDisabled = false;
                    }
                    else {
                        this.isButtonDisabled = true;
                    }
                }
                else {
                    this.isButtonDisabled = false;
                }

            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }
    }
</script>

<style>
.component-container {
    border:1px solid #999;
    margin:0 0 20px;
    border-radius:3px;
}
.component-container h3 {
    padding:5px 15px;
    margin:0;
    background:#999;
    color:#fff;
}
.component-container.X {
    border-color: #666;
}
.component-container.AF {
    border-color: #3a6bba;
}
.component-container.C {
    border-color: #f4ae00;
}
.component-container.M {
    border-color: #f72f81;
}
.component-container.CO {
    border-color: #84d8d4;
}
.component-container.TW {
    border-color: #6eb5ea;
}
.component-container.GF {
    border-color: #0f3772;
}
.component-container.GM {
    border-color: #9b1967;
}
.component-container.GC {
    border-color: #ffa07b;
}
.component-container.S {
    border-color: #c274c4;
}
.component-container.AU {
    border-color: #ff5b00;
}
.component-container.UN {
    border-color: #ff5b00;
}
.component-container.NN {
    border-color: #f4ae00;
}

.component-container.X h3 {
    background-color: #666;
}
.component-container.AF h3 {
    background-color: #3a6bba;
}
.component-container.C h3 {
    background-color: #f4ae00;
}
.component-container.M h3 {
    background-color: #f72f81;
}
.component-container.CO h3 {
    background-color: #84d8d4;
}
.component-container.TW h3 {
    background-color: #6eb5ea;
}
.component-container.GF h3 {
    background-color: #0f3772;
}
.component-container.GM h3 {
    background-color: #9b1967;
}
.component-container.GC h3 {
    background-color: #ffa07b;
}
.component-container.S h3 {
    background-color: #c274c4;
}
.component-container.AU h3 {
    background-color: #ff5b00;
}
.component-container.UN h3 {
    background-color: #ff5b00;
}
.component-container.NN h3 {
    background-color: #f4ae00;
}

.component-checkbox {
    padding:10px 0 0;
}
.component-checkbox > label {
    display: flex;
    flex-direction: row;
}
.component-checkbox > label .left {
    flex:0;
    margin-right:10px;
}
.component-checkbox > label .right {
    flex:1;
}

.component-container .component-content {
    padding:15px;
}
.app-individual-confirm {
  max-width: 520px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-checkbox-inner {
  border:2px solid #1890ff;
}
.ant-checkbox-inner::after {
  top:43%;
  left:20%;
}
#form-normal-who .individual-confirm-form-button {
  margin:10px 0 5px;
  width: 100%;
}
.has-error input, .has-error select {
    border-color:#f5222d !important;
    border-width:2px !important;
}

</style>

<style scoped>
h4 {
    font-size:14px;
    font-weight:600;
}
</style>