<template>
  <div class="app-home">
    <div style="text-align:center;margin-bottom:30px;" v-if="website.has_account_creation_link">
        <h2 style="margin-bottom:5px;">{{ t('Activate your kit') }}</h2>
        <p style="margin-bottom:30px;">{{ t('Create an account to activate and link your kit.') }}</p>
    </div>
    <div v-else>
        <h2 style="margin-bottom:30px;">{{ t('Activate your kit') }}</h2>
    </div>
    
    <a-row style="margin-bottom:50px;">
      <a-col :xs="24" :sm="11" style="margin-bottom:35px;">

        <p style="margin-bottom:25px;">{{ t('Create a new account using your Activation Code') }}</p>

        <div class="home-image-container">
          <img
            :src="createAccountLeftImage.url"
            alt=""
            :style="(createAccountLeftImage.width ? 'width:' + createAccountLeftImage.width + 'px;' : '') + 'height:' + (createAccountLeftImage.height ? createAccountLeftImage.height + 'px;' : (!createAccountLeftImage.width  ? '100%' : 'auto'))"
          />
        </div>

        <router-link :to="'/' + regionId + '/' + websiteId + '/activate'">
          <a-button type="primary" class="form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
            {{ t('Select') }}
          </a-button>
        </router-link>

      </a-col>

      <a-col :xs="0" :sm="2">
        <div class="vertical-divider">
          <div class="center-element">{{ t('Or') }}</div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="0">
        <div class="separator">
            <div class="line"></div>
            <h2>Or</h2>
            <div class="line"></div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="11" style="margin-bottom:35px;">

        <p style="margin-bottom:25px;">{{ t('Create a new account using your Order ID and Password') }}</p>

        <div class="home-image-container">
          <img
            :src="createAccountRightImage.url"
            alt=""
            :style="(createAccountRightImage.width ? 'width:' + createAccountRightImage.width + 'px;' : '') + 'height:' + (createAccountRightImage.height ? createAccountRightImage.height + 'px;' : (!createAccountRightImage.width  ? '100%' : 'auto'))"
            v-if="createAccountRightImage.url"
          />
          <a-empty :description="false" style="padding:54px 0;display:none;" v-if="!createAccountRightImage.url" id="emptyLoginImage" />
        </div>

        <router-link :to="'/' + regionId + '/' + websiteId + '/login/order' + (this.$route.query.via ? '?via=' + this.$route.query.via : '')">
          <a-button type="primary" class="form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
            {{ t('Select') }}
          </a-button>
        </router-link>

      </a-col>
    </a-row>

    <div style="margin:20px 0 50px;"><router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link></div>

  </div>
</template>

<script>
  import axiosUregInstance from '../apiUreg'

  // eslint-disable-next-line no-unused-vars
  import t from '../utils'

  export default {
    name: 'CreateAccountComponent',
    created: function() {
      this.getHomeImageRequest();
    },
    computed: {
      website : function(){ return this.$store.getters.getWebsite}
    },
    mounted: function() {
    },
    methods: {
      async getHomeImageRequest() { 
        let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/websites');
        this.caption = res.data.website.caption;
        this.createAccountLeftImage = res.data.website.images.signup_code_form ? res.data.website.images.signup_code_form : '';
        this.createAccountRightImage = res.data.website.images.signup_idpw_form ? res.data.website.images.signup_idpw_form : '';
        if (!this.createAccountRightImage.url) {
          document.querySelector('#emptyLoginImage').style.display = 'block';
        }
      }
    },
    data() {
      return {
        regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
        websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
        caption: '',
        createAccountLeftImage: {
          url: '',
          height: '',
          width: ''
        },
        createAccountRightImage: {
          url: '',
          height: '',
          width: ''
        },
        t: t
      }
    }
  }
</script>

<style scoped>
.home-image-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 218px;
}

.app-home {
  max-width: 780px;
  margin: 0 auto;
  padding: 30px 20px 0;
  text-align: center;
}
.vertical-divider {
  position: absolute;
  display: table;
  text-align: center;
  height: 100%;
  width: 100%;
}
.vertical-divider .center-element {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.vertical-divider .center-element:before,
.vertical-divider .center-element:after {
  position: absolute;
  content: "";
  width: 1px;
  left: 50%;
  border-left: 1px solid #cccccc;
}
.vertical-divider .center-element:before {
  bottom: 50%;
  top: 0;
  margin-bottom: 20px;
}
.vertical-divider .center-element:after {
  top: 50%;
  bottom: 0;
  margin-top: 20px;
}
.app-home .vertical-divider {
  height:260px;
}
.form-button {
  width:200px;
}
.separator{
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
}
.separator h2 {
  padding: 0 2rem; /* creates the space */
  font-size:14px;
  margin: 0;
}
.separator .line{
  flex: 1;
  height: 1px;
  background-color: #ccc;
}
.ancestry-login-link {
  text-align:center;
  margin:0 0 30px;
}
</style>