<template>
    <div></div>
</template>

<script>
    
    export default {
        name: 'HeaderSgus',
        head: {
            script: [
                {
                    src: 'https://www.googletagmanager.com/gtag/js?id=AW-948831475',
                    async: true
                },
                {
                    inner: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-948831475');"
                },
                {
                    inner: "gtag('event', 'conversion', { 'send_to': 'AW-948831475/2COfCImr9YMBEPOJuMQD', 'transaction_id': ''});"
                }
            ]
        },
    }
</script>