<template>
    <div id="user-console">
        <a-layout>

            <a-layout-sider
                style="background: #fff"
                breakpoint="md"
                collapsed-width="0"
                :v-model="sidebarCollapsed"
                @collapse="onSiderCollapse"
                @breakpoint="onSiderBreakpoint"
                :zeroWidthTriggerStyle="{
                    'position': 'absolute',
                    'left': 'calc(100vw - 50px)',
                    'z-index': '10',
                    'width': '36px',
                    'font-size': '24px',
                    'color': '#000',
                    'background': 'none'
                }"
            >   
                <a-row class="vert-nav-header">
                    <a-row
                        type="flex"
                        class="top"
                    >
                        
                        <a-col class="left">
                            <img :src="icoAccount" class="ico-accoount" />
                            <span class="nav-first-name">{{user.first_name}}</span>
                        </a-col>

                        <a-col class="mid" v-if="supportUrl">
                            <a :href="supportUrl" target="_blank">
                                <img :src="icoHelp" class="ico-help" />
                            </a>
                        </a-col>

                        <a-col class="right" v-if="cartUrl">
                            <a :href="cartUrl" target="_blank">
                                <img :src="icoCart" class="ico-cart" />
                            </a>
                        </a-col>

                    </a-row>
                </a-row>
                <div class="button-activate-menu ">
                    <a-button type="danger" @click="handleLogoutClick" class="btn-ancestry">
                        Logout
                    </a-button>
                </div>
                <a-divider class="first-sep" />
                <a-menu
                    mode="inline"
                    :selected-keys="[section]"
                    :default-open-keys="['dashboard', 'setting']"
                    :style="{ borderRight: 0 }"
                    :cllass="'section-' + section"
                >
                    <a-sub-menu key="dashboard" class="app-vert-menu" :disabled="true">
                        <a-menu-item v-if="cartUrl" class="hide-on-desktop">
                            <a :href="cartUrl" target="_blank">
                                {{ t('Shop') }}
                            </a>
                        </a-menu-item>
                        <a-menu-item key="result" @click="handleMenuClick('result')" v-if="orderNums.reports > 0">
                            {{ t('Lab Results') }}
                        </a-menu-item>
                        <a-menu-item key="order" @click="handleMenuClick('order')" v-if="website.has_account_creation_link && orderNums.orders > 0">
                            {{ t('Orders') }}
                        </a-menu-item>
                        <a-menu-item key="settings" @click="handleMenuClick('settings')" class="settings-menu">
                            {{ t('Settings') }}
                        </a-menu-item>
                    </a-sub-menu> 

                    <a-divider class="first-sep" />

                </a-menu>

                <div class="button-activate-menu">
                    <a-button type="danger" @click="handleActivateClick" class="btn-activate">
                        {{ t('Activate Kit') }}
                    </a-button>
                </div>

                <div class="button-ancestry-menu" v-if="user.genebase_login">
                    <a-button type="danger" @click="handleMenuClick('ancestry')" class="btn-ancestry">
                        {{ t('Ancestry Database') }}
                    </a-button>
                </div>

            </a-layout-sider>
            
            <a-layout-content
            :style="{ padding: '30px', margin: 0, minHeight: 'auto' }"
            >

                <div v-if="section == 'result' || section == 'order'">

                    <Alert />

                    <div>
                        <a-page-header v-if="isLoggedIn"
                            style=""
                            class="app-page-title"
                            :ghost="false"
                            :title="t('Hello%s! Welcome to your dashboard.', (user.first_name != null ? ', ' + user.first_name : '')) "
                        />
                        <a-page-header v-if="!isLoggedIn"
                            style=""
                            class="app-page-title"
                            :ghost="false"
                            title=" "
                        />
                    </div>

                    <div v-if="section == 'result'">

                        <LabResults :key="currentTimestampLabResults" />

                        <PendingOrders :key="currentTimestampPendingOrders" />

                    </div>

                    <div v-else>

                        <AllOrders :key="currentTimestampAllOrders" />

                    </div>

                </div>

                <div v-if="section == 'security'">

                    <SettingSecurity />

                </div>

                <div v-if="section == 'general'">

                    <SettingGeneral />

                </div>

                <div v-if="section == 'settings'">

                    <Settings />

                </div>

                <div v-if="section == 'replace-kit'">

                    <ReplaceKit />

                </div>

                <div v-if="user.genebase_login">
                    <a-card :bordered="false" class="with-border">
                        <a-row>
                            <a-col class="form-container">
                                <h3>{{ t('Access over 100 Free ancestry web apps with a single ancestry DNA test.') }}</h3>
                                <a-button type="primary" class="btn-ancestry" @click="handleMenuClick('ancestry');">
                                    {{ t('Log in to Ancestry Database') }}
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-card>
                </div>

            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
  
  import axiosUserInstance from '../apiUser'
  import LabResults from './account/LabResults.vue'
  import PendingOrders from './account/PendingOrders.vue'
  import AllOrders from './account/AllOrders.vue'
  import SettingSecurity from './account/SettingSecurity.vue'
  import SettingGeneral from './account/SettingGeneral.vue'
  import Settings from './account/Settings.vue'
  import ReplaceKit from './account/ReplaceKit.vue'
  import Alert from './Alert.vue'
  import moment from 'moment';

  // eslint-disable-next-line no-unused-vars
  import t from '../utils'
  
  export default {
    
    name: 'AccountComponent',

    components: {
        LabResults,
        PendingOrders,
        AllOrders,
        SettingSecurity,
        SettingGeneral,
        Settings,
        ReplaceKit,
        Alert
    },

    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
        token : function(){ return this.$store.getters.getToken},
        website : function(){ return this.$store.getters.getWebsite}
    },

    mounted: function() {
        this.getWebsiteRequest();
        this.$nextTick(function () {
            this.getNumOrders();
            if (this.$route.query.via && this.$route.query.for && this.$route.query.via == 'login') {
                this.$message.success('Thank you. You have successfully linked the Order # ' + this.$route.query.for + ' to your account.', 6.0);
            }
        });
    },

    beforeCreate() {
        // this.formStatus = this.$form.createForm(this, { name: 'form_status' });
        // this.isMobileMenu = window.innerWidth <= 767 ? '600' : '200';
        // console.log(this.isMobileMenu);
    },
    
    data: function() {

        return {
            data: [],
            loading: false,
            isCollapsed: this.collapsed,
            sidebarCollapsed: false,
            popoverVisible: false,
            showMenu: false,

            regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
            websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,

            section: this.$route.params.section ? this.$route.params.section : 'result',
            sectionParent: this.$route.params.section && this.$route.params.section == 'general' || this.$route.params.section == 'security' ? 'setting' : 'dashboard',

            currentTimestampLabResults: 'result-' + moment().unix(),
            currentTimestampPendingOrders: 'pendiing-' + moment().unix(),
            currentTimestampAllOrders: 'all-' + moment().unix(),

            icoAccount: require('@/assets/icon-account.svg'),
            icoHelp: require('@/assets/icon-help.svg'),
            icoCart: require('@/assets/icon-cart.svg'),

            homeUrl: '',
            supportUrl: '',
            cartUrl: '',

            patientName: '',
            patientDob: '',
            patientGender: '',
            patientAddress1: '',
            patientAddress2: '',
            patientCity: '',
            patientState: '',
            patientCountry: '',
            patientZipCode: '',
            statusPatientName: '',
            patientEmail: '',
            patientPhone: '',
            pageTitle: '',
            hqQuestion: [],
            historyLog: [], 
            physicianData: [],
            reviewStatusData: [],
            rephId: '',
            reviewStatus: 'required',
            ruleFormStatus: {
                comment: ''
            },
            orderNums: {
                reports: 0,
                orders: 0
            },
            t: t
        };
    },

    methods: {

        onSiderCollapse(collapsed, type) {
            // console.log('type -> ' + type);
            var collapsedWidth = 767;
            if (type != 'clickTrigger') {
                // this.sidebarCollapsed = window.innerWidth <= collapsedWidth ? true : false;
            }
            if (window.innerWidth <= collapsedWidth) {
                // console.log('window innerWidth is ' + window.innerWidth);
                // console.log(document.getElementsByClassName('ant-layout-sider')[0]);
            }
        },

        onSiderBreakpoint() {
            // console.log('dingg.......');
            // console.log(broken);
        },

        momentFormat(args) {        
            return moment(args).format('MMM DD, YYYY @ HH:mm:ss');
        },

        statusCaption(args) {
            return args == 1 ? 'Okay to release result' : 'Cannot release result';
        },

        onChangeSearchType(value) {
            // console.log('...value: ' + value);
            if (value == 'birth_date') {
                this.formPatientSearch.q = '';
                // document.getElementsByClassName('ant-calendar-picker')[0].style.display = 'inline-block';
                // document.getElementsByClassName('ant-input')[0].style.display = 'none';
            }
            else {
                this.formPatientSearch.d = '';
                // document.getElementsByClassName('ant-calendar-picker')[0].style.display = 'none';
                // document.getElementsByClassName('ant-input')[0].style.display = 'inline-block';
            }
        },

        async getWebsiteRequest() {
            let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/websites/' + this.websiteId);
            this.supportUrl = res.data.website.urls.support;
            this.cartUrl = res.data.website.urls.shopping_cart;
            if (this.isLoggedIn) {
                this.homeUrl = '/' + this.regionId  + '/' + this.websiteId + '/account';
            }
            else {
                this.homeUrl = '//' + res.data.website.fqdn;
            }
        },

        handleLogoutClick() {
            this.$store.dispatch('setUser', {});
            this.$store.dispatch('setActivation', {});
            this.$store.dispatch('setToken', null);
            this.$store.dispatch('setRefreshToken', null);
            this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/home');
            this.$message.success('Thank you. You have logged out successfully.', 4.0);
        },

         async getNumOrders() {

            let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/nums', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            /*
            console.log('getNumOrders');
            console.log('res');
            console.log(res);
            */

            this.orderNums = res.data.nums;

            if (this.website.has_account_creation_link) {
                if (this.orderNums.reports == 0 && this.orderNums.orders > 0) {
                    this.section = 'order';
                }
            }

        },

        async handleMenuClick(selected) {
            var collapsedWidth = 767;
            if (selected == 'ancestry') {
                window.location.href = this.user.genebase_login;
            }
            else {
                if (window.innerWidth <= collapsedWidth) {
                    window.location.href = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/account/' + selected;
                }
                else {
                    console.log('this.section before: ' + this.section);
                    if (this.section != selected) {
                        this.$router.replace('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/account/' + selected);
                    }
                    else {
                        if (this.section == 'result') {
                            this.currentTimestampLabResults = 'result-' + moment().unix();
                            this.currentTimestampPendingOrders = 'pending-' + moment().unix();
                        }
                        else if (this.section == 'order') {
                            this.$router.replace('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/account/' + selected);
                            this.currentTimestampAllOrders = 'all-' + moment().unix();
                        }
                    }
                    this.section = selected;
                }
            }
        },

        async handleActivateClick() {
            this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/activate');
        }

    }

  }
</script>

<style>
body { font-family: "Poppins", sans-serif; }
#user-console h3 { margin-bottom:20px; }
.ant-input { font-size:12px; }
.ant-table { font-size:12px; }
.ant-select { font-size:12px; }
.ant-timeline, .ant-timeline-item { font-size:12px; }
.ant-descriptions-item-label {font-size: 12px; color:#999; }
.ant-descriptions-row:nth-child(odd) .ant-descriptions-item {padding-bottom:0;}
.ant-descriptions-row:nth-child(even) .ant-descriptions-item {padding-bottom:15px;}
.div-select-physician {margin-bottom:15px;}
.div-select-physician .ant-input-group {width:400px;}
.div-select-physician .ant-select {width:250px;}
.ant-select-dropdown-menu-item {font-size:12px;}
.ant-form-item {margin-bottom:10px;}
.ant-table-row-cell-break-word {word-break:normal !important;}

.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-item {
    font-size:12px;
}
.ant-menu-submenu-disabled,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
    cursor:default;
}
.vert-nav-header {
    display: flex;
    flex-direction: column;
    margin:20px 24px 0;
}
.vert-nav-header .top {
    display: flex;
    flex-direction: row;
    gap:10px;
}
.vert-nav-header .top .left {
    flex:auto;
}
.vert-nav-header .top .mid,
.vert-nav-header .top .right {
    width:32px;
    flex:0 0 32px;
    margin:0 4px;
}
.div-select-physician .ant-input {
    height:32px;
}
.ant-layout-sider {
    flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 260px !important;
    width: 260px !important;
    background: rgb(255, 255, 255);
}
.ant-page-header {
    background-color:transparent;
    padding:0;
    margin:0 0 30px;
}
.ant-page-header-heading-title {
    font-size:30px;
    line-height:36px;
    font-weight:400;
    color: #31396d !important;
}
.card-title {
    font-size: 1.1rem;
    text-transform:uppercase;
    font-weight: 400;
    color: #31396d;
    margin:0 0 15px;
}
.card-title .ant-spin {
    margin-left:20px;
}
.empty-wrapper {
    color:#999;
    text-align: center;
}
.empty-wrapper .empty-title {
    font-weight:600;
    color:#999;
    font-size:0.9   rem;
    margin:0 0 5px;
}
.empty-wrapper .empty-desc {
    margin:0 0 15px;
}
.ant-card {
    margin:0 0 20px;
    background-color:transparent;
}
.ant-card-body {
    padding:0;
}
.ant-card.with-border {
    padding:25px;
    border:1px solid #d0d9de;
    border-radius:8px;
    background:#fff;
}
.ant-list-item-meta-title, .ant-list-vertical .ant-list-item-meta-title {
    font-size:0.95rem;
    margin:0 0 2px;
}
.anticon-down {
    margin-left:8px;
}
.pending-loadmore-list .ant-list-item-meta {
    flex:0 1 auto;
}
.step-message-wrapper {
    display:flex;
    flex-direction:column;
    padding:0 10px;
}
.console-message-wrapper {
    margin-bottom:20px;
    text-align:left;
}
.pending-wrapper .steps-wrapper,
.pending-wrapper .recollection-wrapper {
    flex:1;
}
.pending-wrapper .action-wrapper {
    flex:0;
}
.pending-wrapper .action-wrapper > button {
    padding-left:30px;
    padding-right:30px;
}
.ant-list-split .ant-list-item {
    border-bottom:1px solid #ededed;
    padding:30px 0;
}
.ant-steps-small .ant-steps-item-title {
    font-size:12px;
}
.ant-steps-item-finish .ant-steps-item-icon {
    background-color:#1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color:#fff;
}
.button-activate-menu {
    margin:15px 24px;
}
.button-activate-menu button {
    width:100%;
}
.button-ancestry-menu {
    margin:10px 24px;
}
.button-ancestry-menu button {
    width:100%;
}
.ant-avatar {
    font-size:28px;
    line-height:52px;
    width:52px;
    height:52px;
}
.issue-tag {
    align-items:center;
    line-height:22px;
    margin:0 25px 0 0;
    color:#bb273f;
    font-weight:600;
}
.issue-tag svg {
    height:1.4em;
    vertical-align:middle;
}
.replaced-pill {
    width:fit-content;
    display:inline-block;
    line-height:22px;
    padding:3px 15px;
    margin:8px 0 15px;
    border-radius:14px;
    color:#333333;
    background-color:#ededed;
}
.ant-alert-error .ant-alert-message, .ant-alert-error {
    color:#cc0000 !important;
}
.ant-menu-submenu-arrow {
    display:none !important;
}
.btn-activate, .btn-activate:hover {
    background:#052460;
    border-color:#052460;
}
.btn-activate:hover {
    opacity:0.8;
}
.btn-ancestry, .btn-ancestry:hover {
    background:transparent;
    border-color:#052460;
    color:#052460;
}
.btn-ancestry:hover {
    background:#052460;
    color:#fff;
}
@media only screen and (min-width:991px) {
    .pending-loadmore-list .ant-list-item-meta {
        width:35%;
    }
    .step-message-wrapper {
        width:65%;
    }
}

@media only screen and (min-width:1201px) {
    .pending-loadmore-list .ant-list-item-meta {
        width:25%;
    }
    .step-message-wrapper {
        width:75%;
    }
}

@media only screen and (max-width:990.99px) {
    .pending-loadmore-list .ant-list-item {
        flex-direction:column;
    }
    .step-message-wrapper {
        width:100%;
    }
    .pending-loadmore-list .steps-wrapper,
    .pending-loadmore-list .recollection-wrapper,
    .pending-loadmore-list .console-message-wrapper,
    .pending-wrapper .action-wrapper {
        margin-top:15px;
    }
}
@media only screen and (max-width: 767.99px) {
    .pending-loadmore-list .ant-list-item {
        align-items:flex-start;
    }
    .ant-menu-submenu-title {
        display:none;
    }
    .pending-wrapper .steps-wrapper,
    .pending-loadmore-list .recollection-wrapper,
    .pending-wrapper .action-wrapper {
        width:100%;
        margin:15px 0 0;
        text-align:left;
    }
    .console-message-wrapper {
        width:100%;
        margin:15px 0;
        text-align:left;
    }
    .ant-radio-group {
        margin-top:8px;
    }
    .ant-radio-group .ant-radio-wrapper {
        display:block;
        margin:0 0 8px;
    }
    .div-select-physician .ant-input-group,
    .div-select-physician .ant-select,
    .ant-form-inline .ant-form-item  {
        width:100%;
    }
    .ant-layout-sider-zero-width-trigger-left {
        position: absolute;
        top: -55px;
        right: -55px;
    }
    aside:not(.ant-layout-sider-collapsed) > .ant-layout-sider-zero-width-trigger-left {
        position: absolute !important;
        top: -55px !important;
    }
    .ant-layout-sider {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
    }
    .ant-layout-sider.ant-layout-sider-zero-width {
        flex: 0 0 0 !important;
        max-width: 0 !important;
        min-width: 0 !important;
        width: 0 !important;
    }

}
.first-sep {
    margin:15px 0;
}
@media (min-width: 767.01px) {
  .hide-on-desktop {
    display:none;
  }
  .vert-nav-header {
    display:none;
  }
  .first-sep {
    display:none;
  }
  .app-header-wrapper {
    margin-top:0;
  }
  .button-activate-menu,
  .button-ancestry-menu {
    display:none;
  }
}

</style>