<template>

    <div>
        <a-row class="step-container">
            <a-col :xs="0" :sm="24">
                <a-steps size="small" labelPlacement="vertical" style="margin:0 auto 15px;">
                    <a-step :status="isStepAccount" :title="t('Account')" />
                    <a-step :status="isStepPersonal" :title="t('Personal')" />
                    <a-step :status="isStepContact" :title="t('Contact')" />
                    <a-step :status="isStepQuestions" :title="t('Questions')" v-if="activation.input.product_types.relationship == false && activation.input.activation_mode != 'key'" />
                </a-steps>

                <div style="margin:0 20px;" v-if="this.$route.name != 'TestConfirm' && this.$route.name != 'IndividualConfirm' && activation.input.product_types.relationship == false && activation.input.activation_mode != 'key'">
                    You are activating <span v-if="activation.input.activation_mode == 'key'">Order</span><span v-else>Kit</span> ID {{ activation.input.activation_code_code }}, {{ activation.input.product_labels[0] }}<span v-if="this.$route.name == 'Signup' && activation.input.product_types.alinity">*</span>
                </div>

            </a-col>
        </a-row>
    </div>

</template>

<script>

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'ActivateStepComponent',
        props: ['step'],
        computed: {
            activation : function(){ return this.$store.getters.getActivation}
        },
        mounted: function() {
            this.checkCurrentStep();
            this.$nextTick(function () {
                
            })
        },
        data() {
            return {
                isStepAccount: 'wait',
                isStepPersonal: 'wait',
                isStepContact: 'wait',
                isStepQuestions: 'wait',
                t: t
            }
        },
        methods: {
            checkCurrentStep() {
                if (this.step == 'signup' || this.step == 'login') {
                    this.isStepAccount = 'process';
                }
                if (this.step == 'contact') {
                    this.isStepAccount = 'finish';
                    this.isStepPersonal = 'process';
                }
                if (this.step == 'phone-verify' || this.step == 'phone-skip' || this.step == 'phone-confirm') {
                    this.isStepAccount = 'finish';
                    this.isStepPersonal = 'finish';
                    this.isStepContact = 'process';
                }
                if (this.step == 'health-info' || this.step == 'collection-confirm') {
                    this.isStepAccount = 'finish';
                    this.isStepPersonal = 'finish';
                    this.isStepContact = 'finish';
                    this.isStepQuestions = 'process';
                }
            }
        }
    }
</script>
