<template>
    <a-spin :spinning="isSpinning" size="large">
        <div class="spin-content">
            <a-row>
                <a-col class="app-setup">
                    <div v-html="loadingContent"></div>
                </a-col>
            </a-row>
        </div>
    </a-spin>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    //  import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'SetupComponent',
        components: {
            // Error
        },
        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            activation : function(){ return this.$store.getters.getActivation},
            signup : function() { return this.$store.getters.getSignup},    
            contact : function() { return this.$store.getters.getContact},
            phone : function() { return this.$store.getters.getPhone},
            questionnaire : function() { return this.$store.getters.getMedical},
            patients : function() { return this.$store.getters.getPatients},
            website: function () { return this.$store.getters.getWebsite },
            getHelpText() {
                return t('It appears that you ran into a technical problem. Please contact our <a href="%s">Customer Service</a> for assistance.', this.website.urls.contact)
            }
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                isSpinning: true,
                loadingContent: '<h2>' + t('Wait a moment') + '</h2><p>' + t('Please wait while your account is being created. Do not refresh your browser!') + '</p>',
                error: '',
                t: t
            }
        },
        mounted: function() {
            this.handleUserSetup();
        },
        methods: {
            
            async handleUserSetup() {
                
                try {

                    // Shogo needs null instead of empty objects.
                    var activation = this.activation,
                        contact = this.contact,
                        phone = this.phone,
                        questionnaire = this.questionnaire,
                        patients = this.patients;
                    
                    if (!phone || Object.keys(phone).length === 0) {
                        phone = null;
                    }

                    if (!questionnaire || Object.keys(questionnaire).length === 0) {
                        questionnaire = null;
                    }

                    if (!patients || Object.keys(patients).length === 0) {
                        patients = null;
                    }
                    
                    var req = {
                        'activation': activation,
                        'contact': contact,
                        'phone': phone,
                        'questionnaire': questionnaire,
                        'patients': patients
                    };

                    var bearer = '';

                    if (this.isLoggedIn === false) {
                        req['signup'] = this.signup;
                    }
                    else {
                        bearer = 'Bearer ' + this.token;
                    }

                    // console.log('req:');
                    // console.log(req);
                    // console.log(JSON.stringify(req));

                    // console.log('bearer:');
                    // console.log(bearer);

                    var api = '';

                    if (activation.input.activation_mode == 'key' && activation.input.product_types.relationship == false) {

                        api = 'create';

                        delete req.phone;
                        delete req.questionnaire;
                        delete req.patients;

                    }

                    else {

                        api = 'setup';

                    }

                    const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/' + api, req, {
                        headers: { 'Authorization': bearer }
                    });

                    // console.log('response:');
                    // console.log(response);

                    this.$store.dispatch('setClientOrder', response.data.client_order);
                    this.$router.push('/' + this.regionId + '/' + this.websiteId + '/setup-complete/' + response.data.client_order.id);
                    
                } catch(e) {
                    this.error = t('Invalid procedure. Aborted.');
                    this.isSpinning = false;
                    this.loadingContent = '<h2>' + t('Sorry...') + '</h2><p>' + this.getHelpText() + '</p>';
                }
                
            },
   
        }        
    }
</script>

<style scoped>

.app-setup {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-spin-nested-loading {
    height:100%;
}
.ant-spin-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
    text-align: center;
}

</style>