<template>
    <div>
        <ActivateStep step="signup" />
        <a-row>
            <a-col class="app-who">

                <h2 v-if="isLoggedIn === true" style="margin-bottoom:30px;">Hello {{ user.first_name }} {{ user.last_name }}</h2>
                <h2 v-if="isLoggedIn === false" style="margin-bottoom:30px;">Hello {{ signup.input.first_name }} {{ signup.input.last_name }}</h2>

                <p style="margin-bottom:30px;">Who will be taking this test?</p>

                <error v-if="error" :error="error" />

                <a-form-model
                    id="form-normal-who"
                    :form="form"
                    class="who-form"
                    layout="vertical"
                    @submit.prevent="handleWhoSubmit"
                >

                    <a-form-item style="margin:0;">
                        <a-radio-group
                            v-model="for_who"
                            style="margin-bottom:30px;"
                        >
                            <a-radio v-if="isLoggedIn === true" value="1" style="margin-bottom:15px;">
                                I, {{ user.first_name }} {{ user.last_name }}, will be taking this test.
                            </a-radio>
                            <a-radio v-if="isLoggedIn === false" value="1" style="margin-bottom:15px;">
                                I, {{ signup.input.first_name }} {{ signup.input.last_name }}, will be taking this test.
                            </a-radio>
                            <a-radio value="2">
                                This test is for someone else.
                            </a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                        <a-button type="primary" html-type="submit" class="who-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            Continue
                        </a-button>
                    </a-form-item>

                </a-form-model>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'

    export default {
        name: 'WhoComponent',
        components: {
            Error,
            ActivateStep
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            signup : function(){ return this.$store.getters.getSignup},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                for_who: '',
                error: ''
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_who' });
        },
        methods: {
            
            async handleWhoSubmit() {

                try {

                    var nextUrl = '';

                    if (this.for_who == '') {
                        this.error = 'Please select an option below.';
                    }
                    else {
                        if (this.for_who == 2) {

                            if (this.$store.getters.getActivation.hash && this.$route.query.via == 'activate') {
                                nextUrl += 'who-cannot?via=activate'
                            }
                            else {
                                nextUrl += 'who-cannot'
                            }
                        }
                        else if (this.for_who == 1) {

                            if (this.$store.getters.getActivation.hash && this.$route.query.via == 'activate') {
                                nextUrl += 'contact?via=activate';
                            }
                            else {
                                nextUrl += 'contact';
                            }

                        }

                        this.error = '';
                        this.$router.push(nextUrl);
                        // this.$message.success('Thank you. Please proceed to the page below.', 2.5);
                    }
                    
                } catch(e) {
                    this.error = 'Please select an option below.';
                }
                
            },


            
        }        
    }
</script>

<style>

.app-who {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
#form-normal-who .who-form-button {
  margin:10px 0 5px;
  width: 100%;
}

</style>