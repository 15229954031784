<template>
    <div>
        <a-row>
            <a-col class="app-setup">

                <div v-if="activation.input.activation_mode == 'key'">

                    <h2 style="margin-bottom:30px;" v-if="isLoggedIn">{{ t('Congratulations!') }}<br />{{ t('Your order is activated.') }}</h2>

                    <h2 style="margin-bottom:30px;" v-else>{{ t('Congratulations!') }}<br />{{ t('Your account is created.') }}</h2>

                    <p>{{ t('Next, login to your account to view your order information.') }}</p>

                    <p style="margin-bottom:10px;">
                        <router-link :to="'/' + regionId + '/' + websiteId + '/login'">
                            <a-button type="primary" class="setup-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ t('Login to your account') }}
                            </a-button>
                        </router-link>
                    </p>

                </div>

                <div v-else>

                    <h2 style="margin-bottom:30px;">Your test is activated!</h2>

                    <p>{{ t('Next, follow the instructions in your test kit to collect the sample and submit it to us for testing.') }}</p>

                    <p style="margin-bottom:10px;">
                        <router-link :to="'/' + regionId + '/' + websiteId + '/login'">
                            <a-button type="primary" class="setup-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ t('Login to your account') }}
                            </a-button>
                        </router-link>
                    </p>
                    <p style="margin-bottom:30px;">
                        <a :href="sampleCollectionUrl" target="_blank">
                            <a-button type="primary" class="setup-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ t('How to collect your sample') }}
                            </a-button>
                        </a>
                    </p>

                </div>

                <div class="activation-html" v-html="activationConfirmationHtml"></div>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    // import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'SetupCompleteComponent',
        components: {
            // Error
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            website : function(){ return this.$store.getters.getWebsite},
            env : function(){ return this.$store.getters.getEnv},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        },
        mounted: function() {
            this.getSampleCollectionLink();
            this.getActivationConfirmationHtml();
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                clientOrderId: this.$route.params.client_order_id ? this.$route.params.client_order_id : '',
                sampleCollectionUrl: '',
                activationConfirmationHtml: '',
                t: t
            }
        },
        methods: {
            
            async getSampleCollectionLink() {

                if (Object.keys(this.env).length === 0) {

                    var activationProductId = this.activation.input.product_id;
                    
                    let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/env/' + activationProductId);

                    this.$store.dispatch('setEnv', res.data.env.urls);

                }

                this.sampleCollectionUrl = this.env.how_to_collect;

            },

            async getActivationConfirmationHtml() {
                
                if (this.clientOrderId) {
                    
                    let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.clientOrderId + '/activation-confirmation');

                    this.activationConfirmationHtml = res.data.activation_confirmation;

                }
            },

            async getResetRequest() {
                // this.$store.dispatch('setUser', {});
                // this.$store.dispatch('setToken', null);
                this.$store.dispatch('setSignup', {});
                this.$store.dispatch('setActivation', {});
                this.$store.dispatch('setPhone', {});
                this.$store.dispatch('setContact', {});
                this.$store.dispatch('setMedical', {});
                this.$store.dispatch('setClientOrder', {});
                this.$store.dispatch('setPatients', {});
                return true;
            },

        }
    }
</script>

<style>

.app-setup {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.setup-form-button {
  margin:10px 0 5px;
  width: 100%;
}
.activation-html img {
  width:100%;
}
</style>