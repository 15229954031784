<template>
    <a-card :bordered="false">

        <div class="card-title"><span v-if="$route.params.id">{{ t('Your Order') }}</span><span v-else>{{ t('Your Orders') }}</span> <a-spin :spinning="isOrderListLoading" /></div>

        <a-card :bordered="false" class="with-border">
            
            <div v-if="orderView.tax_required">

                <a-alert
                    :message="t('Order information has not been entered into the system yet. Please check back later.')"
                    banner
                />

            </div>

            <div v-else>

                <div v-if="$route.params.id">

                    <a-row :gutter="[30,24]" style="margin-bottom:30px;">
                        <a-col :lg="24" :xl="12">
                            <a-descriptions
                                :title="t('Order ID') + ' # ' + orderView.full_id"
                                bordered
                                size="small"
                                :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
                            >
                                <a-descriptions-item :label="t('Date Ordered')">{{ orderView.ordered | formatDate }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Discount Price')">{{ orderView.discount_price_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Status')" v-if="orderView.client_order_status != ''">{{ orderView.client_order_status }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Activation Status')">
                                    <div v-if="orderView.is_activated == true">
                                        {{ t('Activated') }}
                                        <span v-if="orderView.activated_on">
                                            on {{ orderView.activated_on | formatDate }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span style="margin-right:10px;">
                                            <span v-if="orderView.is_ancestry == true">
                                                <a-button size="small" type="danger" @click="handleAncestryClick(orderView.ancestry_url)">
                                                    {{ t('Ancestry Login') }}
                                                </a-button>
                                            </span>
                                            <span v-else>
                                                {{ t('Not Activated') }}
                                            </span>
                                        </span>
                                        <a-button size="small" type="danger" @click="handleActivateClick" v-if="orderView.is_activatable">
                                            {{ t('Activate Kit') }}
                                        </a-button>
                                    </div>
                                </a-descriptions-item>
                            </a-descriptions>
                        </a-col>
                        <a-col :lg="24" :xl="12" v-if="!orderView.force_hide_prices">
                            <a-descriptions
                                :title="t('Payment Summary')"
                                bordered
                                size="small"
                                :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
                            >
                                <a-descriptions-item :label="t('Total Purchase')">{{ orderView.product_amount_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Total Shipping')">{{ orderView.shipping_amount_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Total Booking')" v-if="orderView.is_legal">{{ orderView.booking_amount_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Total Tax')">{{ orderView.tax_amount_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Grand Total')">{{ orderView.amount_ordered_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Total Payment')">{{ orderView.amount_paid_formatted }}</a-descriptions-item>
                                <a-descriptions-item :label="t('Total Owing')">
                                    {{ orderView.amount_owing_formatted }}
                                    <span v-if="orderView.amount_owing > 0" style="margin-left:15px;">
                                        <a-button size="small" type="danger" @click="handlePaymentLink(orderView.id)">
                                            Pay Now
                                        </a-button>
                                    </span>
                                </a-descriptions-item>
                            </a-descriptions>
                        </a-col>
                    </a-row>

                    <a-row class="status-section" v-if="orderView.is_legal">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Sample Collection History</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <table>
                                        <tbody v-if="orderView.num_bookings == 0">
                                            <tr>
                                                <td style="padding:20px;">There are no sample collection records at the moment.</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr class="ant-descriptions-row">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:35%;">{{ t('Components') }}</th>
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:65%;">{{ t('Collection Appointment Date') }}</th>
                                            </tr>
                                            <tr class="ant-descriptions-row" v-for="booking in orderView.bookings" :key="booking.id">
                                                <td class="ant-descriptions-item-content">
                                                    <div v-for="(psc, key) in booking.product_set_components" :key="key">
                                                        <div>{{ psc.full_caption }} <span v-if="psc.individual_full_name">: {{ psc.individual_full_name }}</span></div>
                                                        <div v-if="psc.cancelled">
                                                            {{ t('Cancelled') }}
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="booking.booked" style="margin-top:5px;color:#cc0000;">
                                                                <div v-if="psc.sample_kit_received">
                                                                    {{ t('Sample received') }} {{ psc.sample_kit_received | formatDate }}
                                                                </div>
                                                                <div v-else>
                                                                    {{ t('Sample not received, waiting for samples') }}
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                {{ t('Sample pending') }}
                                                            </div>
                                                        </div>
                                                        <a-divider class="divider-narrow" v-if="key < booking.product_set_components.length - 1" />
                                                    </div>
                                                </td>
                                                <td class="ant-descriptions-item-content" style="width:65%;">
                                                    <div v-html="booking.booking_notice"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </a-col>

                    </a-row>
            
                    <a-row class="status-section" v-if="orderView.num_shippings > 0">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1">
                                <div class="ant-descriptions-title" bis_skin_checked="1">{{ t('All of the sample collection kits shipped for Order ID # %s are listed below.', orderView.full_id ) }}</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <table>
                                        <tbody>
                                            <tr class="ant-descriptions-row">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;" v-if="!isPricesHidden">{{ t('Shipping Address') }}</th>
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;" v-if="!isPricesHidden">{{ t('Shipping Method') }}</th>
                                                <!-- <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;" v-if="!isPricesHidden">Date Shipped</th> -->
                                                <!--<th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:40%;">Components *</th> -->
                                            </tr>
                                            <tr class="ant-descriptions-row" v-for="shipping in orderView.shippings" :key="shipping.id">
                                                <td class="ant-descriptions-item-content" style="width:20%;" v-if="!isPricesHidden">
                                                    <div v-if="orderView.is_discreet">
                                                        <span v-html="shipping.destination_recipient_name"></span>
                                                    </div>
                                                    <div v-else>
                                                        <span v-html="shipping.destination_html"></span>
                                                    </div>
                                                </td>
                                                <td class="ant-descriptions-item-content" style="width:20%;" v-if="!isPricesHidden">{{ shipping.shipping_method }}</td>
                                                <!--
                                                    <td class="ant-descriptions-item-content" style="width:20%;" v-if="!isPricesHidden">
                                                    <span v-if="shipping.shipped">
                                                        {{ shipping.shipped }}
                                                    </span>
                                                    <span v-else>
                                                        Pending
                                                    </span>
                                                </td>
                                                <td class="ant-descriptions-item-content" style="width:40%;">
                                                    <div v-for="(psc, key) in shipping.product_set_components" :key="key">
                                                        <div>{{ psc.label }} {{ psc.numbering }}<span v-if="psc.kit_no">: Kit # {{ psc.kit_no }}</span></div>
                                                        <span v-if="psc.received">
                                                            {{ psc.received }}
                                                        </span>
                                                        <span v-else>
                                                            Not received, waiting for samples
                                                        </span>
                                                        <a-divider class="divider-narrow" v-if="key < shipping.product_set_components.length - 1" />
                                                    </div>
                                                </td>
                                                -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </a-col>

                    </a-row>


                    <a-row class="status-section">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">{{ t('Products Ordered') }}</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <div tabindex="-1" class="ant-table-body" style="overflow-x:auto;">
                                        <table style="table-layout:auto;">
                                            <tbody>
                                                <tr class="ant-descriptions-row">
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Product') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:40%;">{{ t('Turnaround Time') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:40%;">{{ t('Components') }}</th>
                                                </tr>
                                                <tr class="ant-descriptions-row" v-for="productSet in orderView.product_sets" :key="productSet.id">
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ productSet.product_offer_label }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:40%;">{{ productSet.product_offer_turnaround_console }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:40%;">
                                                        <div v-for="(count, caption) in productSet.components_count" :key="caption">
                                                            {{ caption }} x {{ count }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <a-row class="status-section">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">{{ t('Individuals in Test') }}</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <table>
                                        <tbody>
                                            <tr class="ant-descriptions-row">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Product') }}</th>
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:80%;">{{ t('Components') }}</th>
                                            </tr>
                                            <tr class="ant-descriptions-row" v-for="productSet in orderView.product_sets" :key="productSet.id">
                                                <td class="ant-descriptions-item-content" style="width:20%;">{{ productSet.product_offer_label }}</td>
                                                <td class="ant-descriptions-item-content" style="width:80%;">
                                                    <div v-for="(psc, key) in productSet.components" :key="key">
                                                        {{ psc.label }} {{ psc.numbering }}<span v-if="psc.individual_full_name">: {{ psc.individual_full_name }}</span>
                                                        <a-divider class="divider-narrow" v-if="key < productSet.components.length - 1" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <!--

                    <a-row class="status-section">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Results / Reports</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <table>
                                        <tbody>
                                            <tr class="ant-descriptions-row">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">Type</th>
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:30%;">Reporting Method</th>
                                            </tr>
                                            <tr class="ant-descriptions-row" v-for="report in orderView.reports" :key="report.id">
                                                <td class="ant-descriptions-item-content" style="width:20%;">{{ report.caption }}</td>
                                                <td class="ant-descriptions-item-content" style="width:80%;">
                                                    <div v-for="(delivery, key) in report.deliveries" :key="key">
                                                        <div v-for="(method, key2, index) in delivery.methods" :key="index">
                                                            <div style="margin-bottom:5px;">
                                                                {{ method.caption }}
                                                            </div>
                                                            <div v-if="orderView.is_discreet">
                                                                <span v-html="method.full_name"></span>
                                                            </div>
                                                            <div v-else>
                                                                <span v-html="method.to"></span>
                                                            </div>
                                                            <div>
                                                                Reporting Status:
                                                                <span v-if="key2 == 'shipping'">
                                                                    <span v-if="delivery.shipping_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                                <span v-else-if="key2 == 'email'">
                                                                    <span v-if="delivery.email_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                                <span v-else-if="key2 == 'online'">
                                                                    <span v-if="delivery.online_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                                <span v-else-if="key2 == 'phone'">
                                                                    <span v-if="delivery.phone_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                                <span v-else-if="key2 == 'fax'">
                                                                    <span v-if="delivery.fax_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                                <span v-else-if="key2 == 'hand'">
                                                                    <span v-if="delivery.hand_delivered">Sent</span><span v-else>Pending</span>
                                                                </span>
                                                            </div>
                                                            <a-divider class="divider-narrow" v-if="index < Object.keys(delivery.methods).length - 1" />
                                                        </div>
                                                        <a-divider class="divider-narrow" v-if="key < report.deliveries.length - 1" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    -->

                    <a-row class="status-section" v-if="!orderView.force_hide_prices">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Purchases</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <div tabindex="-1" class="ant-table-body" style="overflow-x:auto;">
                                        <table style="table-layout:auto;">
                                            <tbody v-if="orderView.client_purchases_by_product_and_extra.length == 0">
                                                <tr>
                                                    <td style="padding:20px;">There are no purchases at the moment.</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr class="ant-descriptions-row">
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Date') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Product') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Price') }}</th>
                                                    <!--
                                                        <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;" v-if="orderView.client_purchases_by_product_and_extra_has_discount">{{ t('Discount Price') }}</th>
                                                    -->
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Tax') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Total After Tax') }}</th>
                                                </tr>
                                                <tr class="ant-descriptions-row" v-for="purchase in orderView.client_purchases_by_product_and_extra" :key="purchase.id">
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.added | formatDate }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.caption }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        {{ purchase.amount_formatted }}
                                                        <div v-if="purchase.discount_amount && purchase.discount_amount != '0.00'" class="txtNo" style="margin-top:5px;">
                                                            discount -{{ purchase.discount_amount_formatted }}
                                                        </div>
                                                    </td>
                                                    <!--
                                                        <td class="ant-descriptions-item-content" style="width:15%;" v-if="orderView.client_purchases_by_product_and_extra_has_discount">
                                                            {{ purchase.discounted_amount_formatted }}
                                                        </td>    
                                                    -->
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <div v-if="purchase.tax_rate">
                                                            {{ purchase.tax_amount_formatted }}
                                                            <div>({{ purchase.tax_type }} {{ purchase.tax_rate}}%)</div>
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">{{ purchase.net_amount_formatted }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <a-row class="status-section" v-if="orderView.num_shippings > 0 && !orderView.force_hide_prices">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Shipping Charges</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <div tabindex="-1" class="ant-table-body" style="overflow-x:auto;">
                                        <table style="table-layout:auto;">
                                            <tbody v-if="orderView.client_purchases_by_shipping.length == 0">
                                                <tr>
                                                    <td style="padding:20px;">{{ t('There are no shipping charges at the moment.') }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr class="ant-descriptions-row">
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Date') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Type') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Shipping Method') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Price') }}</th>
                                                    <!--
                                                       <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;" v-if="orderView.client_purchases_by_shipping_has_discount">{{ t('Discount Price') }}</th> 
                                                    -->
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Tax') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Total After Tax') }}</th>
                                                </tr>
                                                <tr class="ant-descriptions-row" v-for="purchase in orderView.client_purchases_by_shipping" :key="purchase.id">
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.added | formatDate }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.type }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">{{ purchase.caption }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">{{ purchase.amount_formatted }}</td>
                                                    <!--
                                                        <td class="ant-descriptions-item-content" style="width:15%;" v-if="orderView.client_purchases_by_shipping_has_discount">
                                                            {{ purchase.discounted_amount_formatted }}
                                                        </td>
                                                    -->
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <div v-if="purchase.tax_rate">
                                                            {{ purchase.tax_amount_formatted }}
                                                            <div>({{ purchase.tax_type }} {{ purchase.tax_rate}}%)</div>
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">{{ purchase.net_amount_formatted }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <a-row class="status-section" v-if="orderView.is_legal && !orderView.force_hide_prices">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Booking Charges</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <div tabindex="-1" class="ant-table-body" style="overflow-x:auto;">
                                        <table style="table-layout:auto;">
                                            <tbody v-if="orderView.client_purchases_by_booking.length == 0">
                                                <tr>
                                                    <td style="padding:20px;">{{ t('There are no booking charges at the moment.') }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr class="ant-descriptions-row">
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Date') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Product') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Price') }}</th>
                                                    <!--
                                                        <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;" v-if="orderView.client_purchases_by_product_and_extra_has_discount">{{ t('Discount Price') }}</th>
                                                    -->
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Tax') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Total After Tax') }}</th>
                                                </tr>
                                                <tr class="ant-descriptions-row" v-for="purchase in orderView.client_purchases_by_booking" :key="purchase.id">
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.added | formatDate }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ purchase.caption }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        {{ purchase.amount_formatted }}
                                                        <div v-if="purchase.discount_amount && purchase.discount_amount != '0.00'" class="txtNo" style="margin-top:5px;">
                                                            discount -{{ purchase.discount_amount_formatted }}
                                                        </div>
                                                    </td>
                                                    <!--
                                                        <td class="ant-descriptions-item-content" style="width:15%;" v-if="orderView.client_purchases_by_product_and_extra_has_discount">
                                                            {{ purchase.discounted_amount_formatted }}
                                                        </td>
                                                    -->
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <div v-if="purchase.tax_rate">
                                                            {{ purchase.tax_amount_formatted }}
                                                            <div>({{ purchase.tax_type }} {{ purchase.tax_rate }}%)</div>
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">{{ purchase.net_amount_formatted }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <a-row class="status-section" v-if="!orderView.force_hide_prices">

                        <a-col :lg="24" :xl="24">

                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1" style="margin-bottom:10px;">
                                <div class="ant-descriptions-title" bis_skin_checked="1">Transactions</div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <div tabindex="-1" class="ant-table-body" style="overflow-x:auto;">
                                        <table style="table-layout:auto;">
                                            <tbody v-if="orderView.transactions.length == 0">
                                                <tr>
                                                    <td style="padding:20px;">There are no transactions at the moment.</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr class="ant-descriptions-row">
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Date') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Type') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Payment Method') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Amount') }}</th>
                                                    <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:15%;">{{ t('Status') }}</th>
                                                </tr>
                                                <tr class="ant-descriptions-row" v-for="transaction in orderView.transactions" :key="transaction.id">
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ transaction.paid | formatDate }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:20%;">{{ transaction.type_caption }}</td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <span v-if="transaction.cc">{{ transaction.cc }}</span>
                                                    </td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <span v-if="transaction.is_refund == '1' || transaction.is_chargeback == '1'">-</span> {{ transaction.quoted_amount_formatted }}
                                                    </td>
                                                    <td class="ant-descriptions-item-content" style="width:15%;">
                                                        <span v-if="transaction.is_declined == '1'">
                                                            Declined
                                                        </span>
                                                        <span v-else>
                                                            Approved
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                    <a-row class="status-section">

                        <a-col :lg="24" :xl="24">
                            
                            <div class="ant-descriptions ant-descriptions-small ant-descriptions-bordered" bis_skin_checked="1">
                                <div class="ant-descriptions-title" bis_skin_checked="1">
                                    Contact Information
                                </div>
                                <div class="ant-descriptions-view" bis_skin_checked="1">
                                    <table>
                                        <tbody>
                                            <tr class="ant-descriptions-row" v-if="orderView.primary_phone">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Phone') }}</th>
                                                <td colspan="1" class="ant-descriptions-item-content" style="width:80%;">{{ orderView.primary_phone }}</td>
                                            </tr>
                                            <tr class="ant-descriptions-row" v-if="orderView.primary_fax">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Fax') }}</th>
                                                <td colspan="1" class="ant-descriptions-item-content" style="width:80%;">{{ orderView.primary_fax }}</td>
                                            </tr>
                                            <tr class="ant-descriptions-row" v-if="orderView.primary_email">
                                                <th class="ant-descriptions-item-label ant-descriptions-item-colon" style="width:20%;">{{ t('Email') }}</th>
                                                <td colspan="1" class="ant-descriptions-item-content" style="width:80%;">{{ orderView.primary_email }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </a-col>

                    </a-row>

                </div>

                <div v-else>

                    <div v-if="isOrderListReady">
                            
                        <div class="empty-wrapper" v-if="isOrderListEmpty" style="display:none;">
                            <a-empty>
                                <span slot="description">   
                                    <div class="empty-title">{{ t('You have no orders.') }}</div>
                                </span>
                            </a-empty>
                        </div>

                        <div class="order-wrapper">

                            <a-table
                                size="middle"
                                :columns="columns"
                                :rowKey="record => record.id"
                                :dataSource="orderList"
                                :pagination="pagination"
                                :loading="isOrderListLoading"
                                @change="handleTableChange"
                            >\
                                <div slot="caption" slot-scope="record">
                                    <div v-for="(caption, key) in record.products" :key="key">
                                        <span v-if="record.is_relationship">{{ record.collection_category }}</span> {{ caption }}
                                        <a-divider class="divider-narrow" v-if="key < record.products.length - 1" />
                                    </div>
                                </div>
                                <div slot="activation_status" slot-scope="record">
                                    <div v-if="record.is_activated == true">
                                        {{ t('Activated') }}
                                    </div>
                                    <div v-else>
                                        <span style="margin-right:10px;">
                                            <span v-if="record.is_ancestry == true">
                                                <a-button size="small" type="danger" @click="handleAncestryClick(record.ancestry_url)">
                                                    {{ t('Ancestry Login')  }}
                                                </a-button>
                                            </span>
                                            <span v-else>
                                                {{ t('Not Activated') }}
                                            </span>
                                        </span>
                                        <a-button size="small" type="danger" @click="handleActivateClick" v-if="record.is_activatable">
                                            {{ t('Activate Kit') }}
                                        </a-button>
                                    </div>
                                </div>
                                <div slot="action" slot-scope="record">
                                    <a-button :data-id="record.id" size="small" type="primary" @click="handleViewDetailClick" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                        {{ t('View Details') }}
                                    </a-button>
                                </div>
                                
                            </a-table>

                        </div>
                    
                    </div>

                </div>

            </div>

        </a-card>
        
    </a-card>
</template>

<script>    
  // import {mapGetters} from 'vuex'
  import Vue from 'vue'
  import axiosUserInstance from '../../apiUser'
  import moment from 'moment'

  // eslint-disable-next-line no-unused-vars
  import t from '../../utils'

    Vue.filter('formatDate', function(value) {
        if (value) {
            return moment(String(value)).format('MMM DD, YYYY')
        }
    });

    Vue.filter('formatDateTime', function(value) {
        if (value) {
            return moment(String(value)).format('MMM DD, YYYY @ hh:mm a');
        }
    });

  export default {

    name: 'AllOrders',

    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
        token : function(){ return this.$store.getters.getToken},
        website : function(){ return this.$store.getters.getWebsite}
    },

    mounted: function() {
        this.ifSingleOrder();
        this.$nextTick(function () {
            this.onChangeScreensize();
        })
    },

    created() {
        window.addEventListener("resize", this.onChangeScreensize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onChangeScreensize);
    },

    methods: {

        onChangeScreensize() {
            var collapsedWidth = 560;
            this.stepsDirection = window.innerWidth <= collapsedWidth ? 'vertical' : 'horizontal';
        },

        async handleViewDetailClick(e) {
            var orderId = e.target.getAttribute('data-id');
            window.location.href = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/account/order/view/' + orderId;
        },

        async ifSingleOrder() {
            if (this.$route.params.id) {
                this.getOrderDetail();
            }
            else {
                this.getAllOrderList();
            }
        },

        async getOrderDetail() {

            let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.$route.params.id, {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            console.log('res');
            console.log(res);

            this.orderView = res.data.order;

        },

        async getAllOrderList() {

            // console.log('checking order list.');

            this.orderList = [];
            
            this.isOrderListEmpty = true;

            this.isOrderListLoading = true;

            let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('res');
            // console.log(res);

            this.isOrderListLoading = false;
            this.isOrderListReady = true;

            let list = Object.keys(res.data.orders).map(key => {
                return res.data.orders[key];
            });

            list.forEach(element => {
                this.orderList.push({ ...element, visible: false});
            });

            // var numOrders = this.orderList.length;

            this.pagination = {
                ...this.pagination,
                size: 'small',
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count,
                showTotal: (num, range) => t('%s to %s of %s Order(s)',  range[0], range[1], num)
            };
    
            // console.log('orderList');
            // console.log(this.orderList);
            // console.log('numOrders: ' + numOrders);
            
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination = {
                ...this.pagination,
                current: pagination.current
            };

            this.getAllOrderList({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        },

        handlePaymentLink(orderId) {
            this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/payment/' + orderId);
        },

        cancelAutoUpdate() {  
            clearInterval(this.getAllOrderList);  
        },

        async handleActivateClick() {
            this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/activate');
        },

        async handleAncestryClick(url) {
            window.location.href = url;
        }

    },
    
    data: function() {

        return {

            regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
            websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,

            isOrderListReady: false,
            isOrderListEmpty: true,
            isOrderListLoading: false,
            isRecollectionRequired: false,
            isPricesHidden: false,
            orderList: [],
            orderView: {
                bookings: [],
                shippings: [],
                product_sets: [],
                reports: [],
                client_purchases_by_product_and_extra: [],
                client_purchases_by_shipping: [],
                client_purchases_by_booking: [],
                transactions: []
            },
            pagination: {},
            columns: [
                {
                    title: t('Order ID'),
                    dataIndex: 'full_id',
                    key: 'full_id'
                },
                {
                    title: t('Date Ordered'),
                    dataIndex: 'ordered_on',
                    key: 'ordered_on'
                },
                {
                    title: t('Tests'),
                    scopedSlots: {
                        customRender: "caption"
                    }
                },
                {
                    title: t('Activation Status'),
                    scopedSlots: {
                        customRender: "activation_status"
                    }
                },
                {
                    title: '',
                    scopedSlots: {
                        customRender: "action"
                    }
                }
            ],
            t: t
        }

    },

    beforeDestroy() {  
        this.cancelAutoUpdate();
    }

}
</script> 

<style>
.txtNo {
    color:#cc0000;
}
.status-section {
    margin-bottom:30px;
}
.booking-appt-datetime {
    margin-bottom:10px;
    color:#cc0000;
}
.ant-table-thead > tr > th {
    font-weight:600;
}
.divider-narrow {
    margin:10px 0;
}
.ant-descriptions-title {
    margin-bottom:10px;
}
.ant-descriptions-item-label {
    color:#333 !important;
    font-weight:600;
}
.ant-descriptions-view th {
    width: 30%;
}
.ant-descriptions-view td {
    width: 70%;
}


</style>