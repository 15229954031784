<template>
    <div>
        <a-row>
            <a-col class="app-who">

                <h2>{{ t('Thank you') }}!</h2>

                <p style="margin-bottom:30px;">{{ t('Your password has been successfully updated.') }}</p>
                
                <p style="margin-bottom:30px;">
                    <router-link :to="{ path: '/' + regionId + '/' + websiteId + '/login'} ">
                        <a-button type="primary" class="who-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ t('Log in') }}
                        </a-button>
                    </router-link>
                </p>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    // import axios from 'axios'
    // import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'PasswordCompleteComponent',
        components: {
            // Error
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        data: function() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                t: t
            }
        }
    }
</script>

<style>

.app-who {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}

.who-form-button {
  margin:10px 0 5px;
  width: 100%;
}

</style>