<template>
  <div>
    <a-row>
      <a-col class="app-terms">
        <h2>Terms of Use</h2>
        
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'TermsComponent'
  }
</script>

<style>
.app-terms {
  max-width: 460px;
  margin: 0 auto;
  padding: 30px 20px 0;
  text-align: center;
}
</style>