<template>

    <div>
        
        <a-page-header v-if="isLoggedIn"
            style="margin:0 0 24px;"
            class="app-page-title"
            :title="t('Replace Your Kit')"
            :ghost="false"
        />

        <a-card :bordered="false" class="post-submit with-border" style="display:none;">
            <a-row>
                <a-col class="form-container">
                    <a-result
                        status="success"
                        :title="('Shipping address confirmed!')"
                        :sub-title="t('Please follow the instructions in the kit carefully to recollect your sample.')"
                    >
                        <template #extra>
                            <a-button key="console" type="primary" size="large" @click="handleAccountClick">
                                {{ t('Back to My Account') }}
                            </a-button>
                        </template>
                    </a-result>
                </a-col>
            </a-row>
        </a-card>

        <a-card :bordered="false" class="pre-submit with-border">
            <a-row>
                <a-col class="instruction-container">
                    <p style="font-weight:bold;font-size:120%;" class="sub-title"></p>
                    <p>{{ t('We are happy to send you a replacement kit at no cost. To receive your replacement kit, please complete the kit replacement form below.') }}</p>
                    <p v-html="ifYouNeedHelpText"></p>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="form-container">
                
                    <error v-if="error" :error="error" />

                    <a-form
                        id="form-general"
                        :form="formReplaceKit"
                        class="general-form"
                        layout="vertical"
                        @submit.prevent="handleReplaceKitSubmit"
                    >
                        
                        <a-row :gutter="8">
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                label="First Name"
                                >
                                    <a-input                        
                                        v-decorator="[
                                            'first_name',
                                            {
                                                initialValue: this.user.first_name,
                                                rules: [{ required: false }] 
                                            }
                                        ]"
                                        placeholder=""
                                        size="large"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                label="Last Name"
                                >
                                    <a-input                        
                                        v-decorator="[
                                            'last_name',
                                            {
                                                initialValue: this.user.last_name,
                                                rules: [{ required: false }] 
                                            }
                                        ]"
                                        placeholder=""
                                        size="large"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item
                            label="Address Line 1"
                        >
                            <a-input                        
                                v-decorator="[
                                'address_1',
                                    {
                                        initialValue: isLoggedIn ? user.address_1 : '',
                                        rules: [{ required: true, message: 'Please enter your address.' }]
                                    },
                                ]"
                                placeholder=""
                                size="large"
                            />
                        </a-form-item>
                        <a-form-item
                        label="Address Line 2"
                        >
                            <a-input                        
                                v-decorator="[
                                'address_2',
                                    {
                                        initialValue: isLoggedIn ? user.address_2 : '',
                                        rules: [{ required: false }]
                                    },
                                ]"
                                placeholder=""
                                size="large"
                            />
                        </a-form-item>
                        <a-form-item
                            label="Country"
                        >
                            <a-select
                                show-search
                                placeholder="Select one"
                                @change="handleCountryChange"
                                :filter-option="filterOption"
                                option-filter-prop="children"
                                size="large"
                                v-decorator="[
                                    'country_id',
                                    {
                                        initialValue: isLoggedIn ? user.country.id : 226 + '',
                                        rules: [
                                            { required: true, message: 'Please select a country.' },
                                            { validator: checkCountry }
                                        ]
                                    }
                                ]"
                            >
                                <a-select-option v-for="data in countries" :key="data.key">
                                    {{ data.value }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item
                            label="State"
                            data-label="state_id"
                        >
                            <a-select
                                show-search
                                placeholder="Select one"
                                :filter-option="filterOption"
                                option-filter-prop="children"
                                size="large"
                                v-decorator="[
                                    'state_id',
                                    {
                                        rules: [
                                            { required: true, message: 'Please select a state/province.' }
                                        ] 
                                    }
                                ]"
                            >
                                <a-select-option v-for="data in states" :key="data.key">
                                    {{ data.value }}
                                </a-select-option>
                            </a-select>

                            <div v-if="errors['state_id']" style="color:red;margin-top:5px;">
                                {{ errors['state_id'] }}
                            </div>
                            
                        </a-form-item>

                        <a-form-item
                        label="State"
                        data-label="state"
                        >
                        
                            <a-input                        
                                v-decorator="[
                                    'state_caption',
                                    {
                                        rules: [{ required: false }]
                                    },
                                ]"
                                placeholder=""
                                size="large"
                            />
                        </a-form-item>

                        <a-form-item
                        label="City"
                        >
                            <a-input                        
                                v-decorator="[
                                'city',
                                    {
                                        initialValue: isLoggedIn ? user.city : '',
                                        rules: [{ required: true, message: 'Please enter your city.' }]
                                    },
                                ]"
                                placeholder=""
                                size="large"
                            />
                        </a-form-item>

                        <a-form-item
                            label="Zip Code"
                            data-label="zip_code"
                        >
                            <a-input                        
                                v-decorator="[
                                'zip_code',
                                    {
                                        initialValue: isLoggedIn ? user.postal_code : '',
                                        rules: [{ required: true, message: 'Please enter your zip/postal code.' }]
                                    },
                                ]"
                                placeholder=""
                                size="large"
                            />
                        </a-form-item>

                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="button-submit" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? 'Submitting...' : 'Replace Kit' }}
                        </a-button>

                    </a-form>

                </a-col>
            </a-row>
            
        </a-card>

    </div>

</template>

<script>

    import axiosUserInstance from '../../apiUser'
    import Error from '../Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

    export default {

        name: 'ReplaceKit',

        components: {
            Error
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite},
            ifYouNeedHelpText() {
                return t('If you need help, contact <a href="%s" target="_blank">Customer Care</a> and we would be happy to walk you through recollecting your sample.', this.website.urls.contact);
            }
        },

        mounted: function() {   
            this.getCountryListRequest();
            this.getPatientList();
            this.$nextTick(function () {
                this.handleCountryChange(this.isLoggedIn && this.user.country.id ? this.user.country.id : 226);
            })
        },

        data: function() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                error: '',
                errors: {},
                states: [],
                countries: [],
                patients: [],
                first_name: '',
                last_name: '',
                address_1: '',
                address_2: '',
                country_id: '',
                state_id: '',
                state_caption: '',
                city: '',
                t: t
            }
        },

        beforeCreate() {
            this.formReplaceKit = this.$form.createForm(this, { name: 'normal_replace_kit' });
        },
 
        methods: {

            async getPatientList() {
            
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.$route.query.orderId + '/patients', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.patients = res.data.patients;

                if (this.patients.length > 0) {

                    var relations = [],
                        whose = '';

                    for (var j=0; j<this.patients.length; j++) {
                        
                        if (this.patients[j].is_recollection_required === false) {
                            
                            // nothing here.

                        }
                        else {

                            if (this.patients[j].component_label == 'Individual 1') {
                                whose = 'your';
                            }
                            else {
                                var who = 'the ' + this.patients[j].component_label;
                                relations.push(who.replace(' 1', "'s"));
                            }

                        }
                    }

                    if (relations.length == 0) {
                        // Nothing happens.
                    }
                    else if (relations.length == 1) {
                        whose = relations[0];
                    }
                    else if (relations.length == 2) {
                        whose = relations.join(' ' + t('and') + ' ');
                    }
                    else {
                        const l = relations.length;
                        const lastItem = relations[l - 1];
                        const remainingItems = relations.slice(0, l - 1);
                        whose = remainingItems.join(', ') + ', ' + t('and') + ' ' + lastItem;
                    }

                }

            console.log(relations.length);
            console.log(whose);


                var title = t('There is an issue with %s samples.', whose);

                document.getElementsByClassName('sub-title')[0].innerHTML = title;

            },

            async handleReplaceKitSubmit() {

                try {

                    this.formSubmitting = true;

                    const firstRes = await this.formReplaceKit.validateFields();

                    if (firstRes) {

                        if (this.patients.length > 0) {

                            for (var j=0; j<this.patients.length; j++) {

                                if (this.patients[j].is_recollection_required === true) {

                                    if (this.user.province.name == firstRes.state_id) {
                                        firstRes.state_id = this.user.province.id;
                                    }

                                    var entries = {
                                        first_name: firstRes.first_name,
                                        last_name: firstRes.last_name,
                                        address_1: firstRes.address_1,
                                        address_2: firstRes.address_2,
                                        country_id: firstRes.country_id,
                                        state_id: firstRes.state_id,
                                        state: firstRes.state_caption ? firstRes.state_caption : '',
                                        city: firstRes.city,
                                        zip_code: firstRes.zip_code,
                                        product_set_component_id: this.patients[j].product_set_component_id,
                                        client_order_id: this.$route.query.orderId
                                    }

                                    await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/sample-kits/recollection-requests', entries, {
                                        headers: { 'Authorization': 'Bearer ' + this.token }
                                    });

                                    break;

                                }

                            }

                            this.error = '';
                            this.errors = {};

                            var dataUser = this.user;
                            dataUser.first_name = entries.first_name;
                            dataUser.last_name = entries.last_name;
                            dataUser.address_1 = entries.address_1;
                            dataUser.address_2 = entries.address_2;
                            dataUser.country.id = entries.country_id;
                            dataUser.province.id = entries.state_id;
                            dataUser.province.name = entries.state;
                            dataUser.postal_code = entries.zip_code;

                            this.$store.dispatch('setUser', dataUser);

                            document.getElementsByClassName('pre-submit')[0].style.display = 'none';
                            document.getElementsByClassName('post-submit')[0].style.display = 'block';

                        }

                    }

                } catch(e) {
                    console.log(e);
                    this.formSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                }
                
            },

            async getCountryListRequest() {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/countries/select-options');

                this.countries = res.data.options;

            },

            async getStateListRequest(countryId) {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/states/select-options?country_id=' + countryId);

                this.states = res.data.options;

                this.formReplaceKit.setFieldsValue({
                    'state_id': this.user.province.name,
                    'state_caption': this.user.province.name
                });

            },

            async handleCountryChange(value) {
                if (value == '1') {
                    document.querySelectorAll('[for="normal_replace_kit_state_id"]')[0].innerHTML = t('Province');
                    document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'block';
                    document.querySelectorAll('[data-label="state"]')[0].style.display = 'none';
                    document.querySelectorAll('[for="normal_replace_kit_zip_code"]')[0].innerHTML = t('Postal Code');
                }
                else if (value == '14' || value == '226') {
                    document.querySelectorAll('[for="normal_replace_kit_state_id"]')[0].innerHTML = t('State');
                    document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'block';
                    document.querySelectorAll('[data-label="state"]')[0].style.display = 'none';
                    document.querySelectorAll('[for="normal_replace_kit_zip_code"]')[0].innerHTML = t('Zip Code');
                }
                else {
                    document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'none';
                    document.querySelectorAll('[data-label="state"]')[0].style.display = 'block';
                    if (value == '225') {
                        document.querySelectorAll('[for="normal_replace_kit_zip_code"]')[0].innerHTML = t('Post Code');
                    }
                    this.state_id = 999;
                }
                this.formReplaceKit.setFieldsValue({
                    'state_id': '',
                    'state_caption': ''
                });
                this.getStateListRequest(value);
            },
            
            checkCountry() {
                return true;
            },

            /*
            checkCountry(rule, value, callback) {
                return;
                if (['1', '226'].includes(value)) {
                    callback();
                    return;
                }
                callback(t('This test is not available in the selected country for now.'));
            },
            */

            handleAccountClick() {
                window.location.href = '/' + this.regionId + '/' + this.websiteId + '/account';
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }

    }
</script>

<style scoped>

.instruction-container {
    max-width: 660px;
    margin: 0 auto;
    padding: 10px 0;
}
.form-container {
    max-width: 460px;
    margin: 0 auto;
    padding: 10px 0;
}
.email-form, .password-form {
    margin-bottom:50px;
}
.form-container .button-submit {
    margin:10px 0 5px;
    width: 100%;
}

</style>