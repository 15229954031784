<template>

    <a-form
        id="form-normal-login"
        :form="form"
        class="login-form"
        layout="vertical"
        @submit.prevent="handleSubmit"
    >
        <a-alert
            v-if="$route.query.redirect"
            description="Your session has expired, please login again."
            type="info"
            show-icon
            style="margin-bottom:20px;"
        />

        <error v-if="error" :error="error" />

        <div v-if="accounts.length > 1">

            <h3 style="margin-bottom:15px;">Your email <strong>{{ email }}</strong> is currently associated to more than one account.</h3>
            <h3 style="margin-bottom:25px;">{{ t('To continue, please choose the company that you would like to log into:') }}</h3>

            <a-form-item>
                <a-radio-group v-model="acct_id" style>

                    <a-radio v-for="data in accounts" :key="data.key" class="radioBlock" :value="data.acct.id">
                        {{ data.region.division_name }} {{ data.region.region_name }} (as {{ data.acct.full_name }}) 
                    </a-radio>

                </a-radio-group>
            </a-form-item>

            <a-form-item>
                <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="login-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                    {{ formSubmitting ? 'Submitting...' : 'Continue' }}
                </a-button>
            </a-form-item>

        </div>

        <div v-else>

            <a-form-item>
                <div v-if="this.form_style == 'email'">
                    <a-form-item
                        :label="t('Email')"
                    >
                    <a-input                        
                        v-decorator="[
                        'email',
                        { rules: [{ required: true, message: t('Please enter your Email.') }] },
                        ]"
                        placeholder="johndoe@mail.com"
                        size="large"
                    >
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                    </a-form-item>
                </div>
                <div v-else>
                    <div class="ant-col ant-form-item-label" bis_skin_checked="1">
                        <label for="normal_login_id" title="Order ID #" class="ant-form-item-required">
                            Order ID #
                            <a-popover title="" trigger="click" placement="right" v-if="this.form_style == 'order'">
                                <template #content>
                                    <div style="max-width:350px;">
                                        If you placed your order at <strong>{{ website.fqdn }}</strong>, your Order ID # can be found on your order confirmation page and order confirmation email.
                                    </div>
                                </template>
                                <font-awesome-icon icon="question-circle" size="lg" :style="{ color: '#666', cursor: 'pointer', marginLeft: '5px' }" />
                            </a-popover>
                        </label>
                    </div>
                    <a-input
                        v-decorator="[
                        'id',
                            {
                                rules: [{ required: true, message: 'Please enter your Order ID.' }],
                                initialValue: this.form_style == 'order-confirmation' && this.full_id ? this.full_id : ''
                            },
                        ]"
                        :placeholder="regionId.toUpperCase() + 'XXXXX'" 
                        size="large"
                    >
                        <a-icon slot="prefix" type="profile" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                </div>
            </a-form-item>

            <a-form-item>
                <div class="ant-col ant-form-item-label" bis_skin_checked="1">
                    <label for="normal_login_password" title="Password" class="ant-form-item-required">
                        {{ t('Password') }}
                        <a-popover title="" trigger="click" placement="right" v-if="this.form_style == 'order'">
                            <template #content>
                                <div style="max-width:350px;">
                                    {{ t('Your password is the one that you selected yourself when you placed your order.') }}
                                </div>
                            </template>
                            <font-awesome-icon icon="question-circle" size="lg" :style="{ color: '#666', cursor: 'pointer', marginLeft: '5px' }" />
                        </a-popover>
                    </label>
                </div>
                <a-input-password
                    v-decorator="[
                    'password',
                    { rules: [{ required: true, message: t('Please enter your password.') }] },
                    ]"
                    type="password"
                    placeholder="Password"
                    size="large"
                >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                </a-input-password>
            </a-form-item>

            <a-form-item v-if="this.form_style != 'order-confirmation'">
                <router-link :to="{ path: '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/password/request', query: { via: 'activate'} }" v-if="$route.query.via == 'activate'">{{ t('Forgot password?') }}</router-link>
                <router-link :to="{ path: '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/password/request' }" v-else>{{ t('Forgot password?') }}</router-link>
            </a-form-item>

            <a-form-item style="margin-bottom:0;">
                <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="login-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                    {{ formSubmitting ? t('Submitting...') : ( this.form_style == 'order' || this.form_style == 'order-confirmation' ? t('Create Account') : t('Log in') ) }}
                </a-button>
            </a-form-item>

            <div style="text-align:center;margin-bottom:30px;font-size:85%;color:#aeaeae;"><span v-html="googleTermsText"></span></div>

            <div style="text-align:center;margin-bottom:30px;" v-if="this.form_style == 'order-confirmation'">

            </div>
            <div style="text-align:center;margin-bottom:30px;" v-else-if="this.form_style == 'order'">
                <div v-if="website.has_account_creation_link">
                    {{ t('Don\'t have an Order ID #?') }}<br />
                    <router-link :to="{ path: '/' + regionId + '/' + websiteId + '/activate' }">{{ t('Create an account using your Activation Code.') }}</router-link>
                </div>
            </div>
            <div style="text-align:center;margin-bottom:30px;" v-else>
                <div v-if="website.has_account_creation_link">
                    {{ t('Don\'t have an account?') }}<br />
                    <router-link :to="'/' + regionId + '/' + websiteId + '/create-account'">{{ t('Create an account to activate and link your kit.') }}</router-link>
                </div>
                <div v-else>
                    {{ t('Didn\'t activate your kit yet?') }} <router-link :to="{ path: '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/activate' }">{{ t('Activate your kit') }}</router-link>
                </div>
            </div>

            <div style="text-align:center;margin-bottom:30px;" v-if="this.form_style != 'order-confirmation'">
                <router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link>
            </div>

        </div>
        
    </a-form>

</template>

<script>
    import axiosUserInstance from '../../apiUser'
    import axiosUregInstance from '../../apiUreg'
    import Error from '../Error.vue'

    import Vue from 'vue'
    import { VueReCaptcha } from 'vue-recaptcha-v3'

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

    // For more options see below
    Vue.use(VueReCaptcha, { siteKey: '6LcuMbsUAAAAALnxyF8Xfh-qqlVwvTtfksRuozvG' })

    export default {
        name: 'FormLoginComponent',
        props: {
            full_id: String,
            form_style: String
        },
        components: {
            Error
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite},
            token : function(){ return this.$store.getters.getToken},
            googleTermsText() {
                return t('This site is protected by reCAPTCHA and the Google %s and %s apply.', '<a href="https://policies.google.com/privacy" title="Privacy Policy" target="_blank">Privacy Policy</a>', '<a href="https://policies.google.com/terms" title="Terms of Service" target="_blank">Terms of Service</a>');
            },
            generalTermsText() {
                return t('I have read and accept the %s, %s, and %s.', '<a href="' + this.website.urls.terms + '" target="_blank">Terms of Use</a>', '<a href="' + this.website.urls.consent + '" target="_blank">Privacy Authorization</a>', '<a href="' + this.website.urls.privacy + '" target="_blank">Privacy Policy</a>');
            }
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                email: '',
                password: '',
                acct_id: '',
                accounts: [],
                error: '',
                t: t
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_login' });
        },
        methods: {

            async handleSubmit() {

                try {

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {

                        var nextUrl = '';

                        if (this.form_style == 'order' || this.form_style == 'order-confirmation') {

                            const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/activation-codes/auth-key', {
                                id: firstRes.id,
                                password: firstRes.password,
                                access_token: this.token
                            });

                            // console.log('response');
                            // console.log(response);

                            this.error = '';

                            if (response.data.acct_id) {
                                if (response.data.linked) {
                                    nextUrl = '/' + this.regionId + '/' + this.websiteId + '/account?via=login&for=' + response.data.order_id;
                                }
                                else {
                                    nextUrl = '/' + this.regionId + '/' + this.websiteId + '/login?via=secure-status&for=' + firstRes.id;
                                }
                            }
                            else {
                                nextUrl = '/' + this.regionId + '/' + this.websiteId + '/activate?key=' + response.data.key + (this.$route.query.via ? '&via=' + this.$route.query.via : '');
                            }

                            // this.$store.dispatch('setUser', {}); 
                            this.$store.dispatch('setSignup', {});
                            this.$store.dispatch('setPhone', {});
                            this.$store.dispatch('setContact', {});
                            this.$store.dispatch('setMedical', {});

                            // console.log('nextUrl');
                            // console.log(nextUrl);

                            window.location.href = nextUrl;

                        }

                        else {

                            // console.log('2.....');

                            await this.$recaptchaLoaded();

                            // Execute reCAPTCHA with action "login".
                            const token = await this.$recaptcha('login');

                            const responseVars = {
                                email: firstRes.email,
                                password: firstRes.password,
                                region_id: this.regionId,
                                key: this.$route.query.key ? this.$route.query.key : null,
                                g_recaptcha_response: token
                            };

                            // console.log('3.....');
                            // console.log('this.acct_id: ' + this.acct_id);
                            // console.log('this.accounts');
                            // console.log(this.accounts);
                            // console.log('responseVars');
                            // console.log(responseVars);

                            if (this.acct_id && this.accounts.length > 0) {
                                responseVars.acct_id = this.acct_id;
                                responseVars.email = this.email;
                                responseVars.password = this.password;

                                for (var i = 0; i < this.accounts.length; i++) {
                                    if (this.accounts[i].acct.id == this.acct_id) {
                                        this.regionId = this.accounts[i].region.prefix;
                                        this.websiteId = this.accounts[i].region.website_id;
                                        nextUrl = '/' + this.regionId + '/' + this.websiteId + '/';
                                    }
                                }
                            }

                            const response = await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/auth/login', responseVars);

                            // console.log('response 1');
                            // console.log(response);

                            this.error = '';

                            if (response.data.accounts && response.data.accounts.length > 1) {

                                this.accounts = response.data.accounts;

                                if (this.acct_id == '') {
                                    this.acct_id = response.data.accounts[0].acct.id;
                                }

                                // console.log('accounts');
                                // console.log(this.accounts);

                            }
                            else {

                                var user = response.data.user;

                                user.region_id = this.regionId;
                                user.website_id = this.websiteId;
                                user.redirect = false;

                                this.$store.dispatch('setUser', response.data.user);
                                this.$store.dispatch('setToken', response.data.access_token);
                                this.$store.dispatch('setRefreshToken', response.data.refresh_token);

                            }

                            if (this.accounts.length > 0) {
                                if (firstRes.email) this.email = firstRes.email;
                                if (firstRes.password) this.password = firstRes.password;
                            }

                            /*
    
                            console.log('this.email');
                            console.log(this.email);
    
                            console.log('this.password');
                            console.log(this.password);
    
                            console.log('response 2');
                            console.log(response);
    
                            */

                            this.$store.dispatch('setSignup', {});
                            this.$store.dispatch('setPhone', {});
                            this.$store.dispatch('setContact', {});
                            this.$store.dispatch('setMedical', {});

                            // console.log('response.data.redirect');
                            // console.log(response.data.redirect);

                            if (this.$route.query.key && this.$route.query.key !== null && response.data.order_id) {
                                nextUrl += 'account?via=login&for=' + response.data.order_id;
                            }

                            else if (this.$store.getters.getActivation.hash && this.$route.query.via == 'activate') {

                                if (this.$store.getters.getActivation.input.product_types.alinity) {
                                    nextUrl += 'who?via=activate';
                                }
                                else {
                                    nextUrl += 'contact?via=activate';
                                }

                            }
                            else {

                                if (response.data.redirect === false) {
                                    nextUrl += 'account';
                                }
                                else {

                                    if (typeof response.data.redirect === 'object' && response.data.redirect !== null) {

                                        user.redirect = response.data.redirect;
                                        this.$store.dispatch('setUser', response.data.user);

                                        nextUrl = '/' + response.data.redirect.region_id + '/' + response.data.redirect.website_id + '/account';

                                    }

                                    else {
                                        nextUrl = response.data.redirect;
                                    }

                                }
                            }

                            if (response.data.redirect === false && this.acct_id == '') {
                                this.$router.push(nextUrl);
                            }
                            else {
                                if (this.$route.query.redirect && this.$route.query.redirect != '') {
                                    window.location.href = this.$route.query.redirect;
                                }
                                else {
                                    if (this.acct_id && !firstRes.email && !firstRes.password) {
                                        window.location.href = nextUrl;
                                    }
                                    else {
                                        if (this.accounts.length > 1) {
                                            this.formSubmitting = false;
                                        }
                                        else {
                                            window.location.href = nextUrl;
                                        }
                                    }
                                }
                            }

                            // console.log('nextUrl');
                            // console.log(nextUrl);

                        }

                    }

                } catch(e) {
                    // console.log('.....');
                    // console.log(e);
                    this.formSubmitting = false;
                    var err = e.data.error;
                    this.error = err;
                }

                
                
            }
        }
    }
</script>

<style>
.app-login {
  max-width:480px;
  margin:0 auto;
  padding:30px 20px 0;
}
.app-login.wide {
  max-width:920px;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-login .login-form {
  max-width:300px;
}
#form-normal-login .login-form-forgot {
  float:right;
}
#form-normal-login .login-form-button {
  margin:10px 0 5px;
  width:100%;
}
.radioBlock {
    display:block;
    margin-bottom:10px;
}
</style>