<template>
    <div></div>
</template>

<script>
    
    export default {
        name: 'HeaderDfus',
        head: {
            script: [
                {
                    src: 'https://www.googletagmanager.com/gtag/js?id=AW-995323897',
                    async: true
                },
                {
                    inner: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-995323897');"
                },
                {
                    inner: "gtag('event', 'conversion', { 'send_to': 'AW-995323897/CaGXCIr-rW0Q-d_N2gM', 'transaction_id': ''});"
                }
            ]
        },
    }
</script>