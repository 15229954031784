<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash && $route.query.via == 'activate'" step="signup" />
        <a-row>
            <a-col class="app-signup">

                <div v-if="style == 'direct'">
                    <h2 style="margin-bottom:30px;">Sign Up</h2>
                </div>

                <div v-else>

                    <h2 v-if="$route.query.via == 'activate'">
                        <span v-if="$store.getters.getActivation.input.activation_mode == 'key' && $store.getters.getActivation.input.product_types.relationship == false">
                            {{ t('Create an account to view your order summary for Order # %s', $store.getters.getActivation.input.activation_code_code)}}
                        </span>
                        <span v-else>
                            {{ t('Create your account to activate your %s', $store.getters.getActivation.input.collection_category_id == 1 ? t('test') : t('kit')) }}
                        </span>
                    </h2>
                    <h2 style="margin-bottom:30px;" v-else>{{ t('Sign Up') }}</h2>

                    <p style="margin-bottom:30px;" v-if="$route.query.via == 'activate'">
                        <span v-html="alreadyHaveAccountText"></span>
                    </p>

                    <a-alert
                        type="error"
                        v-if="$store.getters.getActivation.input.activation_mode == 'key' && $store.getters.getActivation.input.product_types.relationship == false"
                        style="margin-bottom:30px !important;"
                    >
                        <template #description>
                            <p><strong>{{ t('Important!') }}</strong> {{ t('Please note that you are creating an account to view your order summary only.') }}</p>
                            {{ t('Your test kit will have its own activation code. You will need to use the activation code on your test kit to activate your kit.') }}
                        </template>
                    </a-alert>

                </div>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-signup"
                    :form="form"
                    class="signup-form"
                    layout="vertical"
                    @submit.prevent="handleSignupSubmit"
                >

                    <a-row :gutter="8">
                        <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                            <a-form-item
                                :label="firstNameLabel"
                            >
                                <a-input                        
                                    v-decorator="[
                                    'first_name',
                                    { rules: [{ required: true, message: t('Please enter your first name.') }] },
                                    ]"
                                    placeholder=""
                                    size="large"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                            <a-form-item
                                :label="lastNameLabel"
                            >
                                <a-input                        
                                    v-decorator="[
                                    'last_name',
                                    { rules: [{ required: true, message: t('Please enter your last name.') }] },
                                    ]"
                                    placeholder=""
                                    size="large"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-form-item
                      :label="t('Email')"
                    >
                        <a-input                        
                            v-decorator="[
                            'email',
                            { rules: [{ required: true, message: t('Please enter your Email.') }] },
                            ]"
                            placeholder="johndoe@mail.com"
                            size="large"
                        />

                        <div v-if="errors['email']" style="color:red;margin-bottom:10px;">
                            <span v-html="errors['email']"></span>
                        </div>

                    </a-form-item>
                    <a-form-item
                      :label="t('Confirm Email')"
                    >
                        <a-input                        
                            v-decorator="[
                                're_email',
                                { rules: [
                                    { required: true, message: t('Please confirm your Email.') },
                                    { validator: checkEmail }
                                ] },
                            ]"
                            placeholder="johndoe@mail.com"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                      :label="t('Password')"
                    >
                        <a-input-password
                            v-decorator="[
                            'password',
                                { rules: [
                                    { required: true, message: t('Please enter your password.') },
                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: t('Use 8 or more characters with a mix of letters and numbers.') }
                                ] },
                            ]"
                            type="password"
                            placeholder=""
                            size="large"
                            :max-length="100"
                        >
                        </a-input-password>
                    </a-form-item>
                    <a-form-item
                      :label="t('Confirm Password')"
                    >
                        <a-input-password
                            v-decorator="[
                            're_password',
                                { rules: [
                                    { required: true, message: t('Please confirm your password.') },
                                    { validator: checkPassword }
                                ] },
                            ]"
                            type="password"
                            placeholder=""
                            size="large"
                            :max-length="100"   
                        >
                        </a-input-password>
                    </a-form-item>
                    <a-form-item>

                        <div v-if="style == 'direct'">

                        </div>

                        <div v-else>

                            <p v-if="$route.query.via == 'activate' && $store.getters.getActivation.input.product_types.alinity === false" style="display:flex;font-size:1em;">
                                <span style="flex:0;margin-right:10px;">
                                    <a-checkbox
                                        v-model="i_agree"
                                        @change="checkIAgree"
                                    />
                                </span>
                                <span style="flex:1;">
                                    <span v-html="generalTermsText"></span>
                                </span>
                            </p>

                        </div>

                        <div v-if="errors['i_agree']" style="color:red;margin-bottom:10px;">
                            {{ errors.i_agree }}
                        </div>

                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="signup-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Submitting...') : $route.query.via == 'activate' ? t('Next') : t('Create My Account') }}
                        </a-button>

                    </a-form-item>

                    <div v-if="style == 'direct'" style="text-align:center;margin-bottom:30px;">
                        <p style="margin-bottom:20px;"><span v-html="googleTermsText"></span></p>
                        <p v-if="$route.query.via == 'activate'">{{ t('Already have an account?') }} <router-link :to="{ path: '/' + $route.params.region_id + '/' + $route.params.website_id + '/login?via=activate' + (this.$route.query.key ? '&key=' + this.$route.query.key : '') }">{{ t('Log in') }}</router-link></p>
                        <p v-else>{{ t('Already have an account?') }} <router-link :to="{ path: '/' + $route.params.region_id + '/' + $route.params.website_id + '/login' }">{{ t('Log in') }}</router-link></p>
                    </div>

                    <div v-else>

                        <div style="text-align:center;margin-bottom:30px;" v-if="this.$route.name == 'Signup' && $store.getters.getActivation.input.product_types.alinity">
                            * {{ t('These lab tests do not replace visits with a healthcare professional. Consult with a healthcare professional for medical advice, help, diagnosis, and/or treatment.') }}
                        </div>

                    </div>
                    
                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUserInstance from '../apiUser'
    import axiosUregInstance from '../apiUreg'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'

    import Vue from 'vue'
    import { VueReCaptcha } from 'vue-recaptcha-v3'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    // For more options see below
    Vue.use(VueReCaptcha, { siteKey: '6LcuMbsUAAAAALnxyF8Xfh-qqlVwvTtfksRuozvG' })

    export default {
        name: 'SignupComponent',
        components: {
            Error,
            ActivateStep
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite},
            alreadyHaveAccountText() {
                return t('If you already have an account, <a href="%s">login to continue</a>', '/' + this.regionId + '/' + this.websiteId + '/login?via=activate');
            },
            googleTermsText() {
                return t('This site is protected by reCAPTCHA and the Google %s and %s apply.', '<a href="https://policies.google.com/privacy" title="Privacy Policy" target="_blank">Privacy Policy</a>', '<a href="https://policies.google.com/terms" title="Terms of Service" target="_blank">Terms of Service</a>');
            },
            generalTermsText() {
                return t('I have read and accept the %s, %s, and %s.', '<a href="' + this.website.urls.terms + '" target="_blank">Terms of Use</a>', '<a href="' + this.website.urls.consent + '" target="_blank">Privacy Authorization</a>', '<a href="' + this.website.urls.privacy + '" target="_blank">Privacy Policy</a>');
            }
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                style: this.$route.params.style ? this.$route.params.style : '',
                formSubmitting: false,
                firstNameLabel: (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.product_types.relationship ? t('First Name') : (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.activation_mode == 'key' ? t('First Name of Account Holder') : t('First Name of Test Taker'))),
                lastNameLabel: (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.product_types.relationship ? t('Last Name') : (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.activation_mode == 'key' ? t('Last Name of Account Holder') : t('Last Name of Test Taker'))),
                i_agree: this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.product_types.alinity ? true : false,
                first_name: '',
                last_name: '',
                email: '',
                re_email: '',
                password: '',
                re_password: '',
                homeUrl: '',
                error: '',
                errors: [],
                t: t
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_signup' });
        },
        mounted: function() {
            if (this.style == 'direct') {
                this.firstNameLabel = 'First Name';
                this.lastNameLabel = 'Last Name';
            }
            this.getWebsiteRequest();
        },
        methods: {

            async handleSignupSubmit() {
                try {

                    this.formSubmitting = true;

                    await this.$recaptchaLoaded()

                    // Execute reCAPTCHA with action "signup".
                    const token = await this.$recaptcha('signup');

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {
                      
                        const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/validate-signup', {
                            first_name: firstRes.first_name,
                            last_name: firstRes.last_name,
                            email: firstRes.email,
                            re_email: firstRes.re_email,
                            password: firstRes.password,
                            re_password: firstRes.re_password,
                            i_agree: this.style == 'direct' || (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.product_types.alinity) ? true : this.i_agree,
                            region_id: this.regionId,
                            website_id: this.websiteId,
                            key: this.$route.query.key ? this.$route.query.key : null
                        });

                        // console.log('response');
                        // console.log(response);

                        this.error = '';
                        this.$store.dispatch('setSignup', response.data.signup);
                        this.$store.dispatch('setUser', {});
                        this.$store.dispatch('setToken', null);

                        if (this.style == 'direct') {

                            try {

                                const nextVars = {
                                    region_id: this.regionId,
                                    website_id: this.websiteId,
                                    g_recaptcha_response: token,
                                    signup: response.data.signup
                                };

                                await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/signup', nextVars);

                                // console.log('nextVars');
                                // console.log(nextVars);
                                // console.log('responseNext');q
                                // console.log(responseNext);

                                this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/login' } );

                                this.$message.success('Your account has been created successfully.', 5.0);

                            }

                            catch(e) {

                                this.formSubmitting = false;    
                                //var err = e.response.data.errors;
                                this.error = e.response.data.error;
                                // this.errors = e.response.data.errors;

                            }

                        }

                        else {

                            if (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.activation_mode == 'key') {

                                this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/contact' } );

                            }

                            else {

                                if (this.$store.getters.getActivation.input && this.$store.getters.getActivation.input.product_types.alinity) {
                                        
                                    if (this.$store.getters.getActivation.hash && this.$route.query.via == 'activate') {
                                        this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/who', query: { via: 'activate' } } );
                                    }
                                    else {
                                        this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/who' } );
                                    }

                                }

                                else {

                                    this.$router.push( { path: '/' + this.regionId + '/' + this.websiteId + '/contact', query: { via: 'activate' } } );

                                }
                            }

                        }

                        // this.$message.success('Sign-up information submitted successfully.', 2.5);

                    }
                    
                } catch(e) {

                    this.formSubmitting = false;
                    //var err = e.response.data.errors;
                    this.error = 'Error found. Please correct the form below and try again.';
                    this.errors = e.response.data.errors;

                }
                
            },

            async getWebsiteRequest() { 
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/websites/' + this.websiteId);
                this.homeUrl = '//' + res.data.website.fqdn;
            },

            checkEmail(rule, value, callback) {
                if (value === this.form.getFieldValue('email')) {
                    callback();
                    return;
                }
                callback('Emails must match.');
            },

            checkPassword(rule, value, callback) {
                if (value === this.form.getFieldValue('password')) {
                    callback();
                    return;
                }
                callback('Passwords must match.');
            },

            handleTermsClick() {
                window.open(this.website.urls.terms, '_blank');
            },

            handlePrivacyClick() {
                window.open(this.website.urls.privacy, '_blank');
            },

            handlePrivacyAuthClick() {
                window.open(this.website.urls.consent, '_blank');
            },

            checkIAgree(e) {
                console.log(e.target.checked);
                this.i_agree = e.target.checked;
            }

         }        
    }
</script>

<style>
.app-signup {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-signup .signup-form {
  max-width: 300px;
}
#form-normal-signup .signup-form-forgot {
  float: right;
}
#form-normal-signup .signup-form-button {
  margin:10px 0 5px;        
  width: 100%;
}
.step-container {
  width:500px;
  margin: 20px auto 0;
}
.grecaptcha-badge {
  opacity:0;
} 
</style>