<template>
    <div>
        <a-row>
            <a-col class="app-request">

                <h2>{{ t('Reset your password') }}</h2>
                <p style="margin-bottom:30px;">{{ t('Enter the email you used to activate your kit and we will send you a link to reset your password.') }}</p>

                <a-alert style="margin:0 0 30px;" v-if="formSubmitted" :message="t('If the email below is registered to a kit, a password reset email was sent. Please check your email to reset your password. If you do not see the email in your inbox, check your spam folder.')" banner />

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-request"
                    :form="form"
                    class="request-form"
                    layout="vertical"
                    @submit.prevent="handleSubmit"
                >

                    <a-form-item
                      :label="t('Email')"
                    >
                    <a-input                        
                        v-decorator="[
                        'email',
                        { rules: [{ required: true, message: t('Please enter your Email.') }] },
                        ]"
                        placeholder="johndoe@mail.com"
                        size="large"
                    />
                    </a-form-item>

                    <a-form-item>
                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="request-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Submitting...') : t('Send Reset Link') }}
                        </a-button>
                    </a-form-item>

                    <div style="text-align:center;margin-bottom:30px;">
                        <p v-if="$route.query.via == 'activate'"><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/login', query: { via: 'activate' } }">{{ t('Back to Log in') }}</router-link></p>
                        <p v-else><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/login' }">{{ t('Back to Log in') }}</router-link></p>    
                    </div>

                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUserInstance from '../apiUser'
    import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'PasswordRequestComponent',
        components: {
            Error
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                formSubmitted: false,
                email: '',
                password: '',
                error: '',
                t: t
            }
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            this.$nextTick(function () {
            })
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_login' });
        },
        methods: {

            async handleSubmit() {
                try {

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {
                      
                        await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/passwords/request', {
                            email: firstRes.email,
                            region_id: this.regionId,
                            website_id: this.websiteId
                        });

                        this.error = '';
                        // this.$message.success('Thank you. You should receive a password reset Email shortly.', 2.5);
                        this.formSubmitting = false;
                        this.formSubmitted = true;

                    }
                    
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = t('Invalid Login!');
                }
                
            }
        }        
    }
</script>

<style>
.app-request {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-explain {
  font-size:12px;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-request .request-form {
  max-width: 300px;
}
#form-normal-request .request-form-forgot {
  float: right;
}
#form-normal-request .request-form-button {
  margin:10px 0 5px;
  width: 100%;
}
</style>