<template>

    <div>
            
        <a-card :bordered="false" v-if="!isCompletedTestEmpty">

            <div class="card-title" v-if="!isCompletedTestEmpty">{{ t('Your Lab Results') }} <a-spin :spinning="isCompletedTestLoading" /></div>

            <div v-if="isCompletedTestReady">
                    
                <div class="empty-wrapper" v-if="isCompletedTestEmpty" style="display:none;">
                    <a-empty>
                        <span slot="description">
                            <div class="empty-title">{{ t('You have no recent lab results.') }}</div>
                            <!--
                            <div class="empty-desc">Order a new test with next day delivery.</div>
                            <a-button type="primary" size="large" disabled>
                                Explore Health Tests
                            </a-button> -->
                        </span>
                    </a-empty>
                </div>
                

                <div class="completed-wrapper" v-if="!isCompletedTestEmpty">
                    
                    <div :data-order_id="item.client_order_id" v-for="item in completedTestList" :key="item.client_order_id" class="order-list">

                        <div class="left">

                            <!-- <span class="circ"><font-awesome-icon icon="user-secret" /></span> -->

                            <div class="left-icon">

                                <img :src="item.acct_console_icon" />

                                <!--
                                <a-avatar slot="avatar">
                                    <a-icon type="deployment-unit" size="large" />
                                </a-avatar>
                                -->

                            </div>

                        </div>
                        
                        <div class="right">

                            <div class="right-content">

                                <h4><span v-if="item.is_relationship">{{ item.collection_category }} </span>{{ item.label }}</h4>
                                
                                <div class="description">
                                    <div class="client-order-id with-info">{{ t('Test ID') }}: {{ item.client_order_full_id }}</div>
                                    <div class="sub-info">
                                        <!-- {{ item.reports.length }} Report<span v-if="item.reports.length > 1">s</span> -->
                                        {{ t('Reported Date') }}: {{ momentDateFormat(item.latest_completed_on) }}
                                    </div>
                                    <div class="sub-info" v-if="item.is_combined_report === false && item.num_reports > 1">
                                        <!-- {{ item.reports.length }} Report<span v-if="item.reports.length > 1">s</span> -->
                                        {{ t('# Reports') }}: {{ item.num_reports }}
                                    </div>
                                </div>

                            </div>

                            <div class="actions">

                                <div v-if="item.is_combined_report === true">

                                    <div v-if="item.show_letter === true">
                                        <a-button v-if="item.is_viewable_online == 1" :data-id="item.client_order_id" :data-division_name="item.division_name" :data-is_contact_attempted="item.is_contact_attempted ? '1' : '0'" type="primary" @click="showModalContact" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                            {{ t('View Results') }}
                                        </a-button>
                                        <a-button v-if="item.is_viewable_online == 0" :data-id="item.client_order_id" type="primary" @click="getResultPdfLink" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                            <a-icon type="download" />
                                            {{ t('View Results') }}
                                        </a-button>
                                    </div>
                                    <div v-else>
                                        <a-button v-if="item.is_viewable_online == 1" :data-id="item.client_order_id" :data-division_name="item.division_name" :data-is_contact_attempted="item.is_contact_attempted ? '1' : '0'" type="primary" @click="showModalResult" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                            {{ t('View Results') }}
                                        </a-button>
                                        <a-button v-if="item.is_viewable_online == 0" :data-id="item.client_order_id" type="primary" @click="getResultPdfLink" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                            <a-icon type="download" />
                                            {{ t('View Results') }}
                                        </a-button>
                                    </div>

                                </div>

                                <div class="actions-spacer" v-else>

                                    <ul>

                                        <li v-for="report in item.reports" :key="report.id">

                                            <a-button v-if="item.is_viewable_online == 0" :data-id="report.id" type="primary" @click="getResultIndividualPdfLink" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                                {{ t('View Results') }}
                                            </a-button>

                                        </li>

                                    </ul>

                                </div>

                            </div>

                            <div class="break"></div>

                            <div class="right-content">

                                <div class="console-message">
                                    <div :data-report_order_id="item.client_order_id" class="console-message-wrapper" style="display:none;margin-bottom:0;"></div>
                                </div>

                            </div>

                            <div class="actions empty"></div>

                        </div>

                    </div>

                </div>
            
            </div>
            
        </a-card>

        <a-modal
            title="Lab Results"
            :width="760"
            v-model="resultVisible"
            :visible="resultVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="resultHandleOk"
            @cancel="resultHandleOk"
        >
            
            <div v-if="resultContentObj.report">

                <div v-if="modalResultLoading">

                    <div class="modal-page-header">
                        <div class="left">
                            <h3>{{ this.resultContentObj.product.label }} *</h3>
                            <!-- <div class="sub-title">{{ this.resultContentObj.report.test_name }}</div> -->
                        </div>
                        <div class="right">
                            <a-button :data-id="resultOrderId" type="primary" @click="getResultPdfLink" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                <a-icon type="download" />
                                Download Report
                            </a-button>
                        </div>
                    </div>

                    <a-card class="modal-result-qualitative-card with-border">

                        <div class="qualitative-table" v-for="(item, index) in resultList" :key="index">
                            
                            <div class="right">

                                <h3 v-if="resultList.length > 1">{{ item.test }}</h3>

                                <div class="scale-bar" v-if="item.alinity.chart">
                                    <div class="ruler-container ruler-boundary" v-for="(data, key1) in item.alinity.chart.ranges" :key="key1" :style="{left: data.lower_percent + '%', borderWidth: key1 == 0 ? '0' : '1px' }">
                                        <div class="number" :style="{ display: key1 == 0 ? 'none' : 'block' }">
                                            {{ data.lower_percent }}
                                        </div>
                                      </div>
                                    <div class="marker" :style="{ left: item.alinity.chart.reference_at_percent + '%' }">
                                        <div class="arrow">
                                            <div class="marker-result">
                                                {{ item.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bar-container">
                                        <div class="bar" v-for="(data, key2) in item.alinity.chart.ranges" :key="key2" :style="{left: data.lower_percent + '%', width: data.upper_percent - data.lower_percent + '%', backgroundColor: data.color, borderTopLeftRadius: key2 == 0 ? '20px' : '0', borderBottomLeftRadius: key2 == 0 ? '20px' : '0', borderTopRightRadius: key2 == item.alinity.chart.num_ranges - 1? '20px' : '0', borderBottomRightRadius: key2 == item.alinity.chart.num_ranges - 1 ? '20px' : '0', borderRight: key2 < item.alinity.chart.num_ranges - 1 ? '3px solid #fff' : '0' }">
                                            <div class="text">{{ data.label }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tag-container" v-else>
                                    <a-tag v-if="item.alinity.label == 'Reactive'" color="#ff4d4f">{{ item.alinity.label }}</a-tag>
                                    <a-tag v-else-if="item.alinity.label == 'Indeterminate'" color="#ffa500">{{ item.alinity.label }}</a-tag>
                                    <a-tag v-else color="#87d068">{{ item.alinity.label }}</a-tag>
                                </div>

                                <div v-html="item.alinity.web_description" style="white-space:pre-line;"></div>
                            </div>
                        </div>

                    </a-card>

                    <div class="qualitative-disclaimer">* {{ t('These lab tests do not replace visits with a healthcare professional. Consult with a healthcare professional for medical advice, help, diagnosis, and/or treatment.') }}</div>

                </div>

            </div>

        </a-modal>

        <a-modal
            title="Important Notice"
            :width="600"
            v-model="contactVisible"
            :visible="contactVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="contactHandleOk"
            @cancel="contactHandleOk"
        >
            
            <div>
                <p style="margin-bottom:25px;">
                    {{ t('Thank you for purchasing a %s at-home test kit.', this.divisionName) }}
                    {{ t('We have partnered with Transcarent, an on-demand digital primary care provider to help you review, better understand and if necessary, take action regarding your results.') }} 
                </p>
                <p style="margin-bottom:25px;">
                    <span v-if="this.isContactAttempted == '1'">
                        {{ t('Transcarent has tried contacting you via phone to discuss your lab results and has not been able to connect.') }}
                    </span>
                    <strong>{{ t('Your results have been released to you via the %s patient portal and it is important to your health that you access, view and follow up on these results.', this.divisionName) }}</strong>
                    {{ t('We recommend that you seek care with a physician for further discussion and guidance in person.') }}
                    {{ t('You can reach a Transcarent physician to review your lab results by contacting the Transcarent Support Team at (866) 788-8550, Option 1.') }}
                </p>

                <a-form
                    id="form-hide-contact"
                    :form="formHideContact"
                    class="hide-contact-form"
                    layout="vertical"
                    @submit.prevent="handleHideContactSubmit"
                >

                    <div style="margin-bottom:25px;">
                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" size="large" class="button-submit" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Please Wait...'): t('View Results') }}
                        </a-button>
                    </div>

                    <a-form-item
                        style="margin:0 0 15px;font-weight:600;"
                    >
                        <span style="flex:0;margin-right:5px;">
                            <a-checkbox
                                v-model="hide_message"
                            />
                        </span>
                        <span style="flex:1;font-weight:400;">
                            Do not show this message again.
                        </span>
                    
                    </a-form-item>

                </a-form>

            </div>

        </a-modal>



    </div>

</template>

<script>

    import axiosUserInstance from '../../apiUser'
    import moment from 'moment'

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'

    export default {

        name: 'LabResults',

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite}  
        },

        mounted: function() {
            this.getCompletedOrderList();
        },

        beforeCreate() {
            this.formHideContact = this.$form.createForm(this, { name: 'normal_hide_contact' });
        },

        methods: {

            async getResult(orderId) {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + orderId + '/report', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                // console.log('......showModalResult........');
                // console.log(res.data);

                this.resultContentObj = res.data.report;

                this.resultList = Object.keys(res.data.report.results).map(key => {
                    return res.data.report.results[key];
                });

                // console.log('.....resultList.....');
                // console.log(this.resultList);

                this.modalResultLoading = true;

                this.resultOrderId = orderId;

            },
        
            showModalResult: async function(event) {

                this.resultVisible = true;
                
                var orderId = event.target.getAttribute('data-id');

                this.getResult(orderId);

            },

            showModalContact: async function(event) {

                var orderId = event.target.getAttribute('data-id'),
                    divisionName = event.target.getAttribute('data-division_name'),
                    isContactAttempted = event.target.getAttribute('data-is_contact_attempted')

                this.targetOrderId = orderId;
                this.divisionName = divisionName;
                this.isContactAttempted = isContactAttempted;

                this.contactVisible = true;

            },
            
            resultHandleOk() {
                this.resultVisible = false;
            },

            contactHandleOk() {
                this.contactVisible = false;
            },

            momentDateFormat(args) {
                return moment(args).format('MMM DD, YYYY');
            },

            getResultPdfLink: async function(event) {
                var orderId = event.target.getAttribute('data-id');
                window.open(process.env.VUE_APP_API_USER_URL_GFRONT + this.regionId + '/' + this.websiteId + '/orders/' + orderId + '/report/pdf?access_token=' + this.token, '_blank');
            },

            getResultIndividualPdfLink: async function(event) {
                var reportId = event.target.getAttribute('data-id');
                window.open(process.env.VUE_APP_API_USER_URL_GFRONT + this.regionId + '/' + this.websiteId + '/reports/' + reportId + '/pdf?access_token=' + this.token, '_blank');
            },
        
            async getCompletedOrderList() {

                this.completedTestList = [];

                this.isCompletedTestEmpty = true;

                this.isCompletedTestLoading = true;

                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/reports/completed', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.isCompletedTestLoading = false;
                this.isCompletedTestReady = true;

                this.completedTestList = Object.keys(res.data.reports).map(key => {
                    return res.data.reports[key];
                });

                var numOrders = this.completedTestList.length;

                if (numOrders > 0) {                

                    this.isCompletedTestEmpty = false;

                    for (var i=0; i<numOrders; i++) {

                        let res3 = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.completedTestList[i].client_order_id + '/console-vars', {
                            headers: { 'Authorization': 'Bearer ' + this.token }
                        });

                        if (res3.data.console_vars) {

                            var console_vars = res3.data.console_vars;

                            // console.log('console_vars for ' + this.completedTestList[i].client_order_id);
                            // console.log(console_vars);

                            if (console_vars) {

                                document.querySelectorAll('[data-report_order_id="' + this.completedTestList[i].client_order_id + '"]').forEach(function(element) {
                                    element.style.display = 'block';
                                    element.innerHTML = console_vars.message;
                                });

                            }

                        }

                    }

                }

                // setTimeout(this.getCompletedOrderList, 5000);

                // console.log('.....getCompletedOrderList......');
                // console.log(count);
                // console.log(res.data.reports);

            },

            async handleHideContactSubmit() {

                if (this.hide_message === true) {

                    try {

                        this.formSubmitting = true;

                        var uriHideContact = '/orders/' + this.targetOrderId + '/report'
                        
                        const hideContactRes = await axiosUserInstance.post(this.regionId + '/' + this.websiteId + uriHideContact, {
                            show_letter: this.hide_message ? 0 : 1
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (hideContactRes) {
                            // console.log('success!');
//                             console.log(hideContactRes);
                            this.error = '';
                            this.errors = {};
                            this.validateStatus = '';

                            this.hide_message = false;
                            this.formSubmitting = false;

                            this.contactVisible = false;
                            this.resultVisible = true;
                            
                            this.getResult(this.targetOrderId);
                            this.getCompletedOrderList();

                        }

                    } catch(e) {
                        this.formSubmitting = false;
                        var err = e.response.data.error;
                        this.error = err;
                        this.errors = e.response.data.errors;
                        this.validateStatus = 'error';
                    }

                }

                else {

                    this.hide_message = false;
                    this.formSubmitting = false;

                    this.contactVisible = false;
                    this.resultVisible = true;
                    
                    this.getResult(this.targetOrderId);

                }

            },

            cancelAutoUpdate() {  
                clearInterval(this.getCompletedOrderList);
            },

        },
        
        data: function() {
            return {
                resultVisible: false,
                contactVisible: false,
                modalResultLoading: false,
                isCompletedTestReady: false,
                isCompletedTestEmpty: true,
                isCompletedTestLoading: false,
                formSubmitting: false,
                hide_message: false,
                targetReportId: '',
                completedTestList: [],
                resultList: [],
                completedTestHtml: '',
                resultOrderId: '',
                isContactAttempted: '',
                divisionName: '',
                resultContentObj: Object,
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                t: t
            }
        },

        beforeDestroy() {  
            this.cancelAutoUpdate();
        }

    }
</script> 

<style>

.completed-wrapper .order-list .right .console-message {
    margin-right:20px;
}
.completed-wrapper .order-list .right .console-message-wrapper {
    width:100%;
}
.ant-modal-body {
    background-color:#f5fafd;
}
.modal-page-header {
    display:flex;
    margin:0 0 20px;
    align-items:center;
}
.modal-page-header .left {
    flex:1 1 auto;
}
.modal-page-header h3 {
    margin:0;
}
.modal-result-qualitative-card .tag-container {
    text-align:center;
}
.modal-result-qualitative-card .ant-tag {
    font-size:20px;
    padding:10px 18px;
    border-radius:20px;
    margin:0 auto 20px;
    display:inline-block;
}
.modal-result-qualitative-card .qualitative-table {
    display:flex;
    margin:0 0 20px;
    padding:0 0 20px;
    border-bottom:1px solid #ededed;
}
.modal-result-qualitative-card .qualitative-table:last-child {
    margin:0;
    padding:0;
    border:0;
}
.modal-result-qualitative-card .qualitative-table .left {
    margin-right:20px;
}
.modal-result-qualitative-card .qualitative-table .right {
    flex:1 1 auto;
}
.qualitative-disclaimer {
    font-size:0.9em;
}
.completed-wrapper .ant-list-item {
    flex-direction:column;
    align-items:flex-start;
}
.completed-wrapper .ant-list-item-action {
    margin-top:8px;
    margin-left:48px !important;
}
.scale-bar {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 75px;
    padding-bottom: 20px;
}
.scale-bar .text {
    font-weight:300;
    font-family:monospace;
    font-size:12px;
    padding-top:30px;
    text-align:center;
}
.scale-bar .number {
    position: absolute;
    padding: 2px 11px;
    opacity: 0.9;
    color: #333;
    left: -18px;
    top: 21px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
}
.scale-bar .marker {
    width: 6px;
    height: 38px;
    position: absolute;
    z-index: 2;
    left: 37.963%;
    transform: translateX(-50%);
    top:-11px;
}
.scale-bar .marker .indicator {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(0, 49, 51);
    border-radius: 5px;
}
.scale-bar .marker .arrow {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
}
.scale-bar .marker .indicator .marker-result, .scale-bar .marker .arrow .marker-result {
    position: relative;
    top: -33px;
    background-color: rgba(190, 234, 185, 0.05);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(0, 32, 33);
}
.scale-bar .ruler-boundary {
    position: absolute;
    top: 20px;
    bottom: 0px;
    border: 1px solid #333;
    transform: translateX(-1px);
    height: 20px;
    z-index: 1;
}
.scale-bar .bar-container {
    height: 16px;
    position: relative;
    background-color: rgb(233, 127, 114);
    border-radius: 20px;
    overflow: visible;
    width: 100%;
}
.scale-bar .bar-container .bar {
    position: absolute;
    top: 0px;
    bottom: 0px;
}

@media only screen and (max-width: 767.99px) {
    .modal-page-header {
        flex-direction:column;
    }
    .modal-page-header .left {
        text-align:center;
        margin:0 0 10px;
    }
    .modal-page-header .right { 
        text-align:center;
    }
    .modal-result-qualitative-card .qualitative-table {
        flex-direction:column;
    }
    .modal-result-qualitative-card .qualitative-table .left {
        margin:0 0 10px;        
    }
    .ant-list-item-meta-description > span > span {
        display:block;
    }
    .ant-list-item-meta-description .ant-divider {
        display:none;
    }
}

@media only screen and (max-width:767.99px) {
    .completed-wrapper .order-list .right .console-message {
        margin-right:0;
    }
}
</style>