<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash" step="phone-verify" />
        <a-row>
            <a-col class="app-phone">

                <h2>Contact Information</h2>
                <p style="margin-bottom:30px;">
                    <span v-if="$store.getters.getActivation.input.phone_text === null">{{ t('For your security, a text message containing a verification code will be sent to your cell phone.') }}</span>
                    <span v-if="$store.getters.getActivation.input.phone_text !== null">{{ $store.getters.getActivation.input.phone_text }}</span>
                </p>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-phone"
                    :form="form"
                    class="phone-form"
                    layout="vertical"
                    @submit.prevent="handlePhoneSubmit"
                >

                    <a-form-item
                      :label="t('Phone Number')"
                    >

                        <a-input-group compact>
                            <a-select
                                show-search
                                :placeholder="t('Select one')"
                                :filter-option="filterOption"
                                style="width:35%;"
                                size="large"
                                v-decorator="[
                                    'country',
                                    {
                                        initialValue: 'US',
                                        rules: [{ required: true, message: t('Please select a country code.') } ] 
                                    }
                                ]"
                            >
                                <a-select-option v-for="data in codes" :key="data.code">
                                    {{ data.name }} ({{ data.dial_code }})
                                </a-select-option>
                            </a-select>
                            <a-input
                                style="width:65%"                    
                                v-decorator="[
                                    'phone',
                                    { rules: [{ required: true, message: t('Please enter your phone number.') }] },
                                ]"
                                :placeholder="t('Phone Number')"
                                size="large"
                                :max-length="20"
                            />
                        </a-input-group>

                    </a-form-item>

                    <a-form-item>

                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="phone-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Submitting...') : t('Send Verification Code') }}
                        </a-button>

                    </a-form-item>

                    <div v-if="$store.getters.getActivation.input.is_phone_required === false" style="text-align:center;margin-bottom:30px;">
                        <p v-if="$route.query.via == 'activate'"><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-skip', query: { via: 'activate' } }">{{ t('Skip this step') }}</router-link></p>
                        <p v-else><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-skip' }">{{ t('Skip this step') }}</router-link></p>
                    </div>

                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'
    import CountryCodes from "../assets/country-code.json";

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'PhoneVerifyComponent',
        components: {
            Error,
            ActivateStep
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            website : function(){ return this.$store.getters.getWebsite}
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                codes: CountryCodes,
                prefix: '',
                country: '',
                phone: '',
                error: '',
                t: t
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_phone' });
        },
        methods: {

            async handlePhoneSubmit() {

                var dialCode = '';

                try {

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {


                        for (let i = 0; i < this.codes.length; i++) {
                            if (this.codes[i].code == this.form.getFieldValue('country')) {
                                dialCode = this.codes[i].dial_code;
                                break;
                            }
                        }

                        // console.log('dialCode : ' + dialCode);
                      
                        const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/verify-phone', {
                            prefix: dialCode,
                            country: firstRes.country,
                            phone: firstRes.phone,
                            region_id: this.regionId
                        });

                        this.error = '';
                        this.$store.dispatch('setPhone', response.data.phone);
                        this.$router.push('/' + this.regionId + '/' + this.websiteId + '/phone-confirm');
                        // this.$message.success('You shall receive the verification code shortly.', 2.5);

                    }
                    
                } catch(e) {
                    this.formSubmitting = false;
                    var err = e.errors.phone.errors;
                    this.error = err.map(e => e.message).join('. ');
                }
                
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }        
    }
</script>

<style>

@import '../assets/country-code.css';

.app-phone {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-phone .phone-form {
  max-width: 300px;
}
#form-normal-phone .phone-form-forgot {
  float: right;
}
#form-normal-phone .phone-form-button {
  margin:10px 0 5px;
  width: 100%;
}
.iti-flag {
    display:inline-block;
    margin-right:4px;
}
</style>