var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.getters.getActivation.hash)?_c('ActivateStep',{attrs:{"step":"phone-verify"}}):_vm._e(),_c('a-row',[_c('a-col',{staticClass:"app-phone"},[_c('h2',[_vm._v("Contact Information")]),_c('p',{staticStyle:{"margin-bottom":"30px"}},[(_vm.$store.getters.getActivation.input.phone_text === null)?_c('span',[_vm._v(_vm._s(_vm.t('For your security, a text message containing a verification code will be sent to your cell phone.')))]):_vm._e(),(_vm.$store.getters.getActivation.input.phone_text !== null)?_c('span',[_vm._v(_vm._s(_vm.$store.getters.getActivation.input.phone_text))]):_vm._e()]),(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"phone-form",attrs:{"id":"form-normal-phone","form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handlePhoneSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.t('Phone Number')}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'country',
                                {
                                    initialValue: 'US',
                                    rules: [{ required: true, message: _vm.t('Please select a country code.') } ] 
                                }
                            ]),expression:"[\n                                'country',\n                                {\n                                    initialValue: 'US',\n                                    rules: [{ required: true, message: t('Please select a country code.') } ] \n                                }\n                            ]"}],staticStyle:{"width":"35%"},attrs:{"show-search":"","placeholder":_vm.t('Select one'),"filter-option":_vm.filterOption,"size":"large"}},_vm._l((_vm.codes),function(data){return _c('a-select-option',{key:data.code},[_vm._v(" "+_vm._s(data.name)+" ("+_vm._s(data.dial_code)+") ")])}),1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'phone',
                                { rules: [{ required: true, message: _vm.t('Please enter your phone number.') }] },
                            ]),expression:"[\n                                'phone',\n                                { rules: [{ required: true, message: t('Please enter your phone number.') }] },\n                            ]"}],staticStyle:{"width":"65%"},attrs:{"placeholder":_vm.t('Phone Number'),"size":"large","max-length":20}})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"phone-form-button",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formSubmitting,"loading":_vm.formSubmitting,"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" "+_vm._s(_vm.formSubmitting ? _vm.t('Submitting...') : _vm.t('Send Verification Code'))+" ")])],1),(_vm.$store.getters.getActivation.input.is_phone_required === false)?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"30px"}},[(_vm.$route.query.via == 'activate')?_c('p',[_c('router-link',{attrs:{"to":{ path: '/' + _vm.regionId + '/' + _vm.websiteId + '/phone-skip', query: { via: 'activate' } }}},[_vm._v(_vm._s(_vm.t('Skip this step')))])],1):_c('p',[_c('router-link',{attrs:{"to":{ path: '/' + _vm.regionId + '/' + _vm.websiteId + '/phone-skip' }}},[_vm._v(_vm._s(_vm.t('Skip this step')))])],1)]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }