<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash" step="phone-verify" />
        <a-row>
            <a-col class="app-phone">

                <a-alert message="The verification code has been sent to your phone." type="success" show-icon closable />

                <h2>Provide the verification code</h2>
                <p style="margin-bottom:30px;">Check your phone for the text message we just sent to you and enter the verification code below.</p>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-phone"
                    :form="form"
                    class="phone-form"
                    layout="vertical"
                    @submit.prevent="handlePhoneSubmit"
                >

                    <a-form-item>
                        <CodeInput :loading="false" class="code-input-container" />
                    </a-form-item>

                    <a-form-item style="margin-bottom:0;">

                        <div style="margin-bottom:20px;">
                            <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="phone-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ formSubmitting ? 'Submitting...' : 'Submit Verification Code' }}
                            </a-button>
                        </div>

                    </a-form-item>

                    <div style="text-align:center;margin-bottom:30px;">

                        <p v-if="$route.query.via == 'activate'"><router-link :to="{ path: 'phone-verify', query: { via: 'activate' } }">Re-send verification code</router-link></p>
                        <p v-else><router-link :to="{ path: 'phone-verify' }">Re-send verification code</router-link></p>

                        <div v-if="$store.getters.getActivation.input.is_phone_required === false" >
                            <div class="separator">
                                <div class="line"></div>
                                <h2>Or</h2>
                                <div class="line"></div>
                            </div>

                            <p v-if="$route.query.via == 'activate'"><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-skip', query: { via: 'activate' } }">Skip this step</router-link></p>
                            <p v-else><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-skip' }">Skip this step</router-link></p>

                        </div>

                    </div>
                    
                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'
    import CodeInput from 'vue-verification-code-input'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'


    export default {
        name: 'PhoneConfirmComponent',
        components: {
            Error,
            CodeInput,
            ActivateStep
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                code: '',
                error: ''
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_phone' });
        },
        methods: {
            
            async handlePhoneSubmit() {

                var verificationCode = '',
                    inputs = document.querySelectorAll('input');

                for (var i = 0; i < inputs.length; i++) {
                    verificationCode += inputs[i].value;
                }

                try {

                    this.formSubmitting = true;

                    var elem = document.querySelector('.ant-alert-success');
                    if (typeof(elem) != 'undefined' && elem != null) {
                        elem.parentNode.removeChild(elem);
                    }

                    const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/verify-phone-code', {
                        code: verificationCode,
                        phone: this.$store.getters.getPhone,
                        region_id: this.regionId
                    });

                    this.error = '';
                    this.$store.dispatch('setPhone', response.data.phone);

                    var nextUrl = '';

                    if (this.$store.getters.getActivation.input.product_types.alinity == true && this.$store.getters.getActivation.input.activation_mode != 'key') {
                        nextUrl = 'health-info';
                    }
                    else {
                        nextUrl = 'setup';
                    }
                    
                    this.$router.push(nextUrl);
                    // this.$message.success('SMS verification code submitted successfully.', 2.5);
                    
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = t('Invalid verification code.');
                }
                
            },
            
        }        
    }
</script>

<style>

.code-input-container {
    margin:0 auto;
    width:100% !important;
}
.react-code-input {
    display:flex;
}
.react-code-input input {
    width:100% !important;
}
.separator{
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.separator h2 {
  padding: 0 2rem; /* creates the space */
  font-size:14px;
  margin: 0;
}

.separator .line{
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

</style>