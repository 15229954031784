<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash" step="health-info" />
        <a-row>
            <a-col class="app-health">

                <a-alert v-if="$store.getters.getPhone != null && Object.keys($store.getters.getPhone).length !== 0" :message="t('Your phone number has been confirmed.')" type="success" show-icon closable />

                <h2>Questions relevant to your test</h2>
                <p style="margin-bottom:30px;">{{ t('You are almost there! Complete the questionnaire below.') }}</p>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-health"
                    class="health-form"
                    layout="vertical"
                    @submit.prevent="handleHealthSubmit"
                >
                    <li v-for="(data, index) in this.questionnaire" :key="index">

                        <a-form-item v-if="questionnaireShown[data.id] == true">
                            
                            <div class="ant-col ant-form-item-label">
                                <label><span v-html="data.is_required ? '<h4>*</h4> ' : ''"></span> {{ data.question }}</label>
                            </div>
                            
                            <div v-if="data.input_type == 'a-date-picker'">
                                <dropdown-datepicker
                                    v-model="questions[data.id]"
                                    :maxYear="maxYear"
                                    :minYear="minYear"
                                    :daySuffixes="false"
                                />
                            </div>
                            <div v-else-if="data.input_type == 'a-select'">
                                <element
                                    show-search
                                    placeholder="Select one"
                                    :filter-option="filterOption"
                                    :is="data.input_type"
                                    v-model="questions[data.id]"
                                    size="large"
                                        
                                >
                                    <a-select-option v-for="bit in data.options" :key="data.field + '@' + bit.key">
                                        {{ bit.value }}
                                    </a-select-option>
                                </element>
                                <a-textarea
                                    v-if="data.show_textbox_if && (questions[data.id] && questions[data.id].includes('@') ? questions[data.id].split('@')[1] == data.show_textbox_if : questions[data.id] === data.show_textbox_if)"
                                    v-model="extra[data.id]"
                                    placeholder="Please describe."
                                    :max-length="255"
                                    style="margin-top:10px;"
                                    :auto-size="{ minRows: 3, maxRows: 5 }"
                                />
                            </div>
                            <div v-else-if="data.input_type == 'a-radio-group'">
                                <element
                                    :is="data.input_type"
                                    v-model="questions[data.id]"
                                    placeholder=""
                                    size="large"
                                    @change="checkRadioValue"
                                >

                                    <div v-if="data.show_textbox_if">
                                        <div v-for="bit in data.options" :key="bit.key">
                                            <a-radio :value="data.field + '@' + bit.key">
                                                {{ bit.value }}
                                                <a-textarea
                                                    v-if="data.show_textbox_if == 'y' && (bit.key === 1 && (questions[data.id] && questions[data.id].includes('@') ? questions[data.id].split('@')[1] == '1' : questions[data.id] === 1))"
                                                    v-model="extra[data.id]"
                                                    placeholder="Please describe."
                                                    :max-length="255"
                                                    style="margin-top:10px;display:block;"
                                                    :auto-size="{ minRows: 3, maxRows: 7 }"
                                                />
                                            </a-radio>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span v-for="bit in data.options" :key="bit.key">
                                            <a-radio :value="data.field + '@' + bit.key">
                                                {{ bit.value }}
                                            </a-radio>
                                        </span>
                                    </div>

                                </element>
                            </div>
                            
                            <div v-if="errors['mq-' + data.id] || errors['mq-' + data.id] == ''" style="color:red;margin-top:5px;font-size:90%;">
                                {{ errors['mq-' + data.id].length == 0 ? 'Required.' : errors['mq-' + data.id] }}
                            </div>
                            
                        </a-form-item>

                        <a-divider  v-if="questionnaireShown[data.id] == true" />

                    </li>

                    <a-form-item style="margin-bottom:30px;">

                        <p style="display:flex;font-size:1em;">
                            <span style="flex:0;margin-right:10px;">
                                <a-checkbox
                                    v-model="i_agree"
                                    @change="checkIAgree"
                                />
                            </span>
                            <span style="flex:1;">
                                I have read and accept the <a @click="handlePrivacyAuthClick">terms of this Consent</a>, I authorize the <a @click="handlePrivacyAuthClick">collection, use and disclosure of my PHI</a> and I have read and accept the <a @click="handleTermsClick">Terms of Use</a> and <a @click="handlePrivacyClick">Privacy Policy</a>.
                            </span>
                        </p>
                        <div v-if="errors['i_agree']" style="color:red;margin-bottom:10px;font-size:90%;">
                            {{ errors['i_agree'] }}
                        </div>

                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="health-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? 'Submitting...' : (isLoggedIn ? t('Activate Test Kit') : t('Create My Account')) }}
                        </a-button>

                        <error v-if="error" :error="error" style="margin-top:20px;" />

                    </a-form-item>

                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'
    import moment from 'moment'
    import DropdownDatepicker from '../../node_modules/vue-dropdown-datepicker/src/dropdown-datepicker.vue'
    
    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'HealthInfoComponent',
        components: {
            Error,
            ActivateStep,
            DropdownDatepicker
        },
        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            activation : function(){ return this.$store.getters.getActivation},
            contact : function() { return this.$store.getters.getContact},
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            this.getHealthListRequest();
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                i_agree: false,
                maxYear: moment().year(),
                minYear: moment().year() - 121,
                questionnaire: [],
                questionnaireShown: [],
                questions: [],
                ansType: [],
                extra:[],
                homeUrl: '',
                error: '',
                errors: [],
                t: t
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_health' });
        },
        methods: {

            async getHealthListRequest() {

                // console.log(this.activation.input.product_id);
                // console.log(this.contact.input.country_id);
                // console.log(this.contact.input.state_id);
                // console.log(this.isLoggedIn);
                // console.log(this.$store.getters.getToken);

                var productId = this.activation.input.product_id,
                    countryId = this.contact.input.country_id ? this.contact.input.country_id :  this.user.country.id,
                    stateId = this.contact.input.state_id ? this.contact.input.state_id : this.user.province.id;

                let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/medical-questionnaire/' + productId + '/' + countryId + '/' + stateId);

                this.questionnaire = res.data.questionnaire;

                console.log('this.questionnaire');
                console.log(this.questionnaire);

                for (var k = 0; k < this.questionnaire.length; k++) {
                    this.questionnaireShown[this.questionnaire[k].id] = true;
                    this.ansType[this.questionnaire[k].id] = this.questionnaire[k].field;
                }

                console.log('this.questionnaireShown');
                console.log(this.questionnaireShown);

                console.log('this.ansType');
                console.log(this.ansType);

                this.compileShowHide();

            },

            async handleHealthSubmit() {

                try {

                    this.formSubmitting = true;

                    var elem = document.querySelector('.ant-alert-success');
                    if (typeof(elem) != 'undefined' && elem != null) {
                        elem.parentNode.removeChild(elem);
                    }

                    var answers = this.compileAnswers(),
                        temp = [];

                    for (var ansNum in answers) {
                        if (this.questionnaireShown[ansNum] === false) {
                            // Skip.
                        }
                        else {
                            temp[ansNum] = answers[ansNum];
                        }
                    }

                    answers = temp;

                    var req = {
                        'answers': answers,
                        'i_agree': this.i_agree
                    }

                    var productId = this.activation.input.product_id,
                        countryId = this.contact.input.country_id ? this.contact.input.country_id :  this.user.country.id,
                        stateId = this.contact.input.state_id ? this.contact.input.state_id : this.user.province.id;

                    const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/medical-questionnaire/' + productId + '/' + countryId + '/' + stateId, req);

                    this.error = '';
                    this.errors = [];
                    this.$store.dispatch('setMedical', response.data.questionnaire);
                    this.$router.push('/' + this.regionId + '/' + this.websiteId + '/setup');

                    
                } catch(e) {

                    this.formSubmitting = false;
                    this.error = 'Invalid entries on the questionnaire.';
                    this.errors = e.response.data.errors;

                }
                
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            compileAnswers() {
                
                var answers = {};

                for (var i = 0; i < this.questionnaire.length; i++) {
                    console.log(this.questionnaire[i].id);
                    console.log('......... ' + this.questionnaire[i].input_type + ' ... ' + this.questionnaire[i].id + ' ... ' + this.questionnaire[i].answer_type + ' .. ');
                    console.log(this.questions[this.questionnaire[i].id]);

                    if (this.questionnaire[i].input_type && (this.questions[this.questionnaire[i].id] || this.questions[this.questionnaire[i].id] == '0')) {
                        if (this.questionnaire[i].input_type == 'a-date-picker') {
                            answers[this.questionnaire[i].id] = this.questions[this.questionnaire[i].id];
                        }
                        else {
                            if (this.questionnaire[i].answer_type === 'object') {
                                var ansO = this.questions[this.questionnaire[i].id];
                                if (ansO.includes('@')) {
                                    answers[this.questionnaire[i].id] = {
                                        'selected': ansO.split('@')[1],
                                        'text': this.extra[this.questionnaire[i].id] ? this.extra[this.questionnaire[i].id] : null
                                    }
                                }
                                else {
                                    answers[this.questionnaire[i].id] = {
                                        'selected': ansO,
                                        'text': this.extra[this.questionnaire[i].id] ? this.extra[this.questionnaire[i].id] : null
                                    }
                                }

                                if (this.questionnaire[i].field) {
                                    if (this.questionnaire[i].field == 'height') {
                                        answers[this.questionnaire[i].id] = {
                                            'unit': null,
                                            'ft': null,
                                            'in': null,
                                            'cm': null
                                        }
                                    }
                                    else if (this.questionnaire[i].field == 'weight') {
                                        answers[this.questionnaire[i].id] = {
                                            'unit': null,
                                            'lb': null,
                                            'kg': null
                                        }
                                    }
                                }
                            }
                            else if (this.questionnaire[i].answer_type === 'scalar') {
                                var ans = this.questions[this.questionnaire[i].id];
                                if (ans.includes('@')) {
                                    answers[this.questionnaire[i].id] = ans.split('@')[1];
                                }
                                else {
                                    answers[this.questionnaire[i].id] = this.questions[this.questionnaire[i].id];
                                }
                            }
                            else {
                                answers[this.questionnaire[i].id] = this.questions[this.questionnaire[i].id];
                            }
                        }
                    }
                    else {
                        if (this.questionnaire[i].answer_type === 'object') {
                            answers[this.questionnaire[i].id] = {
                                'selected': null,
                                'text': null
                            };
                            if (this.questionnaire[i].field) {
                                if (this.questionnaire[i].field == 'height') {
                                    answers[this.questionnaire[i].id] = {
                                        'unit': null,
                                        'ft': null,
                                        'in': null,
                                        'cm': null
                                    }
                                }
                                else if (this.questionnaire[i].field == 'weight') {
                                    answers[this.questionnaire[i].id] = {
                                        'unit': null,
                                        'lb': null,
                                        'kg': null
                                    }
                                }
                            }
                        }
                        else {
                            answers[this.questionnaire[i].id] = null;
                        }
                    }
                    
                }

                return answers;

            },

            handleTermsClick() {
                window.open(this.website.urls.terms, '_blank');
            },

            handlePrivacyClick() {
                window.open(this.website.urls.privacy, '_blank');
            },

            handlePrivacyAuthClick() {
                window.open(this.website.urls.consent, '_blank');
            },

            checkIAgree(e) {
                this.i_agree = e.target.checked;
            },

            checkRadioValue() {
                this.compileShowHide();
            },

            compileShowHide() {

                var ans = this.compileAnswers();

                for (var i = 0; i < this.questionnaire.length; i++) {

                    if (Array.isArray(this.questionnaire[i].show_if)) {

                        // console.log('----------');
                        // console.log(this.questionnaire[i]);

                        var numConditions = this.questionnaire[i].show_if.length,
                            conditionsMet = false;

                        for (var j = 0; j < numConditions; j++) {

                            // console.log(this.questionnaire[i].show_if[j].field);
                            // console.log(this.questionnaire[i].show_if[j].value);

                            for (var ansNum in this.ansType) {
                                // console.log('... ' + this.ansType[ansNum]);
                                // console.log('...... ');
                                // console.log(ans[ansNum]);
                                // console.log(this.questionnaire[i].show_if[j].field);
                                if (this.ansType[ansNum] == this.questionnaire[i].show_if[j].field) {
                                    if (ans[ansNum] == this.questionnaire[i].show_if[j].value) {
                                        conditionsMet = true;
                                    }
                                    else {
                                        conditionsMet = false;
                                        break;
                                    }
                                }
                            }

                        }

                        if (conditionsMet === true) {
                            // console.log(this.questionnaire[i].id + ' should be shown.');
                            this.questionnaireShown[this.questionnaire[i].id] = true;
                        }
                        else {
                            // console.log(this.questionnaire[i].id + ' should be hidden.');
                            this.questionnaireShown[this.questionnaire[i].id] = false;
                        }


                        
                    }
                    else {
                        // console.log(i + ' is not an array.');
                    }

                }

            }

        }
    }
</script>

<style>
.date-dropdowns select {
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 8px;
    margin-right: 10px;
    transition: 0.5s;
}
.date-dropdowns select:hover {
    border-color:#1890ff;
}
.ant-form-item-label > label > span > h4 {
    color:red;
    font-size:100%;
    margin:0 0 5px;
    padding:0;
    display:inline-block;
}
.ant-calendar-picker, .ant-radio-group {
    width:100%;
}
.app-health {
    max-width: 480px;
    margin: 0 auto;
    padding: 30px 20px 0;
}
.health-form label {
    font-size:14px !important;
    line-height:110%;
    width: 100%;
}
.ant-form-vertical .ant-form-item-label {
    padding:0 0 5px;
}
#form-normal-health .health-form {
    max-width: 300px;
}
#form-normal-health .health-form-forgot {
    float: right;
}
#form-normal-health .health-form-button {
    margin:10px 0 5px;
    width: 100%;
}
</style>