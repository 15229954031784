<template>
  <div class="app-home">
    <div v-if="areCookiesBlocked">
      <h2 style="margin-bottom:30px;">Cookies are disabled</h2>
      <p>Your browser has cookies disabled. Please enable the cookies from your browser's settings page and try again.</p>
    </div>
    <div v-else>
      <h2 style="margin-bottom:30px;">{{ t('Welcome to %s!', caption ) }}</h2>
      <a-row style="margin-bottom:50px;">
        <a-col :xs="24" :sm="11" style="margin-bottom:35px;">

          <div class="home-image-container">
            <img
              :src="homeActivationImage.url"
              alt=""
              :style="(homeActivationImage.width ? 'width:' + homeActivationImage.width + 'px;' : '') + 'height:' + (homeActivationImage.height ? homeActivationImage.height + 'px;' : (!homeActivationImage.width  ? '100%' : 'auto'))"
            />
          </div>

          <router-link :to="'/' + regionId + '/' + websiteId + '/create-account'" v-if="website.has_account_creation_link">
            <a-button type="primary" class="form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
              {{ t('Activate your kit') }}
            </a-button>
          </router-link>
          
          <router-link :to="'/' + regionId + '/' + websiteId + '/activate'" v-else>
            <a-button type="primary" class="form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
              {{ t('Activate your kit') }}
            </a-button>
          </router-link>

        </a-col>

        <a-col :xs="0" :sm="2">
          <div class="vertical-divider">
            <div class="center-element">{{ t('Or') }}</div>
          </div>
        </a-col>

        <a-col :xs="24" :sm="0">
          <div class="separator">
              <div class="line"></div>
              <h2>{{ t('Or') }}</h2>
              <div class="line"></div>
          </div>
        </a-col>

        <a-col :xs="24" :sm="11" style="margin-bottom:35px;">

          <div class="home-image-container">
            <img
              :src="homeLoginImage.url"
              alt=""
              :style="(homeLoginImage.width ? 'width:' + homeLoginImage.width + 'px;' : '') + 'height:' + (homeLoginImage.height ? homeLoginImage.height + 'px;' : (!homeLoginImage.width  ? '100%' : 'auto'))"
              v-if="homeLoginImage.url"
            />
            <a-empty :description="false" style="padding:54px 0;display:none;" v-if="!homeLoginImage.url" id="emptyLoginImage" />
          </div>

          <router-link :to="'/' + regionId + '/' + websiteId + '/login'">
            <a-button type="primary" class="form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
              {{ t('Log in') }}
            </a-button>
          </router-link>

        </a-col>
      </a-row>

      <div v-if="website.has_ancestry_login_link" class="ancestry-login-link">
        If you ordered an Ancestry Test, <a href="https://www.account-ssl.com/acct/GBUS57/auth/login-master" target="_blank">{{ t('log in') }} here</a> to access your test result.
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'

  // eslint-disable-next-line no-unused-vars
  import t from '../utils'

  export default {
    name: 'HomeComponent',
    created: function() {
      this.getHomeImageRequest();
      // this.checkMaintenance();
    },
    computed: {
      website : function() { return this.$store.getters.getWebsite},
      translation: function () { return this.$store.getters.getTranslation }
    },
    mounted: function() {
      this.areCookiesBlocked = !navigator.cookieEnabled;
    },
    methods: {
      async getHomeImageRequest() { 
        let res = await axios.get(process.env.VUE_APP_API_UREG_URL_GFRONT + (this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID) + '/' + (this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID) + '/websites');
        this.caption = res.data.website.caption;
        this.homeActivationImage = res.data.website.images.activation_form ? res.data.website.images.activation_form : require('@/assets/thumb-activate.png');
        this.homeLoginImage = res.data.website.images.login_form ? res.data.website.images.login_form : '';
        if (!this.homeLoginImage.url) {
          document.querySelector('#emptyLoginImage').style.display = 'block';
        }
      },
      async checkMaintenance() {
        const excludedIps = ['216.71.196.211', '207.6.11.226', '207.81.81.56', '96.49.200.47'];
        fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(y => {
          if (!excludedIps.includes(y.ip)) {
            this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/maintenance');
          }
          else {
            return
          }
        });
      }
    },
    data() {
      return {
        areCookiesBlocked: false,
        regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
        websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
        caption: '',
        homeActivationImage: {
          url: '',
          height: '',
          width: ''
        },
        homeLoginImage: {
          url: '',
          height: '',
          width: ''
        },
        t: t
      }
    }
  }
</script>

<style scoped>
.home-image-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 218px;
}

.app-home {
  max-width: 780px;
  margin: 0 auto;
  padding: 30px 20px 0;
  text-align: center;
}
.vertical-divider {
  position: absolute;
  display: table;
  text-align: center;
  height: 100%;
  width: 100%;
}
.vertical-divider .center-element {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.vertical-divider .center-element:before,
.vertical-divider .center-element:after {
  position: absolute;
  content: "";
  width: 1px;
  left: 50%;
  border-left: 1px solid #cccccc;
}
.vertical-divider .center-element:before {
  bottom: 50%;
  top: 0;
  margin-bottom: 20px;
}
.vertical-divider .center-element:after {
  top: 50%;
  bottom: 0;
  margin-top: 20px;
}
.app-home .vertical-divider {
  height:260px;
}
.form-button {
  min-width:200px;
}
.separator{
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
}
.separator h2 {
  padding: 0 2rem; /* creates the space */
  font-size:14px;
  margin: 0;
}
.separator .line{
  flex: 1;
  height: 1px;
  background-color: #ccc;
}
.ancestry-login-link {
  text-align:center;
  margin:0 0 30px;
}
</style>