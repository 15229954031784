var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{staticClass:"app-reset"},[_c('h2',[_vm._v(_vm._s(_vm.t('Choose a new password')))]),_c('p',{staticStyle:{"margin-bottom":"30px"}},[_vm._v(_vm._s(_vm.t('Please enter your new password.')))]),(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"reset-form",attrs:{"id":"form-normal-reset","form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                            { rules: [
                                { required: true, message: _vm.t('Please enter your password.') },
                                { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: _vm.t('Use 8 or more characters with a mix of letters and numbers.') }
                            ] },
                        ]),expression:"[\n                        'password',\n                            { rules: [\n                                { required: true, message: t('Please enter your password.') },\n                                { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\\d).{8,}$/), message: t('Use 8 or more characters with a mix of letters and numbers.') }\n                            ] },\n                        ]"}],attrs:{"type":"password","placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"Confirm Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        're_password',
                            { rules: [
                                { required: true, message: _vm.t('Please confirm your password.') },
                                { validator: _vm.checkPassword }
                            ] },
                        ]),expression:"[\n                        're_password',\n                            { rules: [\n                                { required: true, message: t('Please confirm your password.') },\n                                { validator: checkPassword }\n                            ] },\n                        ]"}],attrs:{"type":"password","placeholder":"","size":"large"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"30px"}},[_c('a-button',{staticClass:"reset-form-button",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formSubmitting,"loading":_vm.formSubmitting,"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" "+_vm._s(_vm.formSubmitting ? _vm.t('Submitting...') : _vm.t('Confirm Password'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }