<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash" step="contact" />
        <a-row>
            <a-col class="app-contact">

                <h2 style="margin-bottom:30px;">{{ t('Contact Information') }}</h2>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-contact"
                    :form="form"
                    class="contact-form"
                    layout="vertical"
                    @submit.prevent="handleContactSubmit"
                >

                    <div v-if="activation.input.product_types.relationship == false && activation.input.activation_mode != 'key'">
                        <h3 style="margin-bottom:20px;">{{ t('Name of person taking the %s', activation.input.product_labels[0]) }}</h3>

                        <a-row :gutter="8" style="margin-bottom:10px;">
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                    :label="t('First Name of Test Taker')"
                                >
                                    <a-input                        
                                        v-decorator="[
                                        'first_name',
                                            {
                                                initialValue: isLoggedIn ? user.first_name : signup.input.first_name,
                                                rules: [{ required: true, message: t('Please enter your first name.') }]
                                            },
                                        ]"
                                        placeholder=""
                                        size="large"
                                        :disabled="inputDisabled"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                                <a-form-item
                                :label="t('Last Name of Test Taker')"
                                >
                                    <a-input                        
                                        v-decorator="[
                                        'last_name',
                                            {
                                                initialValue: isLoggedIn ? user.last_name : signup.input.last_name,
                                                rules: [{ required: true, message: t('Please enter your last name.') }]
                                            },
                                        ]"
                                        placeholder=""
                                        size="large"
                                        :disabled="inputDisabled"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <h3 style="margin-bottom:20px;">{{ t('Address') }}</h3>
                        
                    </div>

                    <a-form-item
                        :label="t('Address Line 1')"
                    >
                        <a-input                        
                            v-decorator="[
                            'address_1',
                                {
                                    initialValue: isLoggedIn ? user.address_1 : '',
                                    rules: [{ required: true, message: t('Please enter your address.') }]
                                },
                            ]"
                            placeholder=""
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                      :label="t('Address Line 2')"
                    >
                        <a-input                        
                            v-decorator="[
                            'address_2',
                                {
                                    initialValue: isLoggedIn ? user.address_2 : '',
                                    rules: [{ required: false }]
                                },
                            ]"
                            placeholder=""
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        :label="t('Country')"
                    >
                        <a-select
                            show-search
                            :placeholder="t('Select one')"
                            @change="handleCountryChange"
                            :filter-option="filterOption"
                            option-filter-prop="children"
                            size="large"
                            v-decorator="[
                                'country_id',
                                {
                                    initialValue: isLoggedIn ? user.country.name : '226',
                                    rules: [
                                        { required: true, message: t('Please select a country.') },
                                        { validator: checkCanadaOrUs }
                                    ]
                                }
                            ]"
                        >
                            <a-select-option v-for="data in countries" :key="data.key">
                                {{ data.value }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                        :label="t('State')"
                        data-label="state_id"
                    >
                        <a-select
                            show-search
                            placeholder="Select one"
                            :filter-option="filterOption"
                            option-filter-prop="children"
                            size="large"
                            v-decorator="[
                                'state_id',
                                {
                                    initialValue: isLoggedIn ? user.province.name : '',
                                    rules: [
                                        {
                                            required: checkCountry(this.form.getFieldValue('country_id')),
                                            message: t('Please select a state/province.')
                                        }
                                    ] 
                                }
                            ]"
                        >
                            <a-select-option v-for="data in states" :key="data.key">
                                {{ data.value }}
                            </a-select-option>
                        </a-select>

                        <div v-if="errors['state_id']" style="color:red;margin-top:5px;">
                            {{ errors['state_id'] }}
                        </div>
                        
                    </a-form-item>

                    <a-form-item
                      :label="t('State')"
                      data-label="state"
                    >
                        <a-input                        
                            v-decorator="[
                                'state_caption',
                                {
                                    initialValue: isLoggedIn ? user.province.name : '',
                                    rules: [{ required: false }]
                                },
                            ]"
                            placeholder=""
                            size="large"
                        />
                    </a-form-item>

                    <a-form-item
                      :label="t('City')"
                    >
                        <a-input                        
                            v-decorator="[
                            'city',
                                {
                                    initialValue: isLoggedIn ? user.city : '',
                                    rules: [{ required: true, message: t('Please enter your city.') }]
                                },
                            ]"
                            placeholder=""
                            size="large"
                        />
                    </a-form-item>

                    <a-form-item
                        :label="t('Zip Code')"
                        data-label="zip_code"
                    >
                        <a-input                        
                            v-decorator="[
                            'zip_code',
                                {
                                    initialValue: isLoggedIn ? user.postal_code : '',
                                    rules: [{ required: true, message: t('Please enter your zip/postal code.') }]
                                },
                            ]"
                            placeholder=""
                            size="large"
                        />
                    </a-form-item>
                    
                    <a-form-item>
                        
                        <div style="margin-bottom:20px;">
                            <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="contact-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                                {{ formSubmitting ? t('Submitting...') : t('Continue') }}
                            </a-button>
                        </div>

                    </a-form-item>
                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>

    import axiosUregInstance from '../apiUreg'
    import axiosUserInstance from '../apiUser'
    import Error from './Error.vue'
    import ActivateStep from './ActivateStep.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'ContactComponent',
        components: {
            Error,
            ActivateStep
        },
        computed: {
            activation : function(){ return this.$store.getters.getActivation},
            signup : function(){ return this.$store.getters.getSignup},
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            website : function(){ return this.$store.getters.getWebsite}
        },
        mounted: function() {
            this.getCountryListRequest();
            this.$nextTick(function () {
                this.handleCountryChange(this.isLoggedIn ? this.user.country.id : '226');
                if (this.isLoggedIn) {
                    this.form.setFieldsValue({
                        'country_id': this.user.country.name,
                        'state_id': this.user.province.name,
                        'state_caption': this.user.province.name
                    });
                }
            })
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                inputDisabled: true,
                formSubmitting: false,
                countriesWithStateIds: ['1', '14', '45', '96', '108', '208', '226'],
                states: [],
                countries: [],
                first_name: '',
                last_name: '',
                address_1: '',
                address_2: '',
                country_id: '',
                state_id: '',
                state_caption: '',
                city: '',
                error: '',
                errors: [],
                t: t
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_contact' });
        },
        methods: {

            async handleContactSubmit() {
                try {   

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {

                        if (this.isLoggedIn) {
                            if (this.user.country.name == firstRes.country_id) {
                                firstRes.country_id = this.user.country.id;
                            }
                            if (this.user.province.name == firstRes.state_id) {
                                firstRes.state_id = this.user.province.id;
                            }
                        }

                        var entries = {
                            address_1: firstRes.address_1,
                            address_2: firstRes.address_2,
                            country_id: firstRes.country_id,
                            state_id: firstRes.state_id,
                            state: firstRes.state_caption ? firstRes.state_caption : '',
                            city: firstRes.city,
                            zip_code: firstRes.zip_code,
                            region_id: this.regionId,
                            product_id: this.activation.input.product_id
                        }

                        var dataSignup = {};

                        if (this.activation.input.product_types.relationship == false) {

                            if (this.activation.input.activation_mode != 'key') {

                                entries.first_name = firstRes.first_name;
                                entries.last_name = firstRes.last_name;

                                if (this.isLoggedIn == false) {

                                    dataSignup = this.signup;
                                    dataSignup.input.first_name = firstRes.first_name;
                                    dataSignup.input.last_name = firstRes.last_name;
                                    
                                    this.$store.dispatch('setSignup', dataSignup);
                                    
                                }
                            }

                        }

                        else {

                            if (this.isLoggedIn == false) {

                                dataSignup = this.signup;

                                entries.first_name = dataSignup.input.first_name;
                                entries.last_name = dataSignup.input.last_name;
                                
                            }

                        }

                        var api = 'validate-contact'; // corresponds to /account/setup

                        if (this.activation.input.activation_mode == 'key' && this.activation.input.product_types.relationship == false) {

                            api += '-lite'; // corresponds to /account/create

                            delete entries.region_id;
                            delete entries.product_id;

                        }

                        const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/account/' + api, entries);

                        var nextUrl = '/' + this.regionId + '/' + this.websiteId + '/';

                        this.error = '';
                        this.$store.dispatch('setContact', response.data.contact);

                        nextUrl += 'phone-verify'

                        this.$message.success(t('Contact information submitted successfully.'), 2.5);

                        this.$router.push(nextUrl);

                    }
                    
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = t('Error found. Please correct the form below and try again.');
                    this.errors = e.response.data.errors;
                    console.log(e);
                }
                
            },

            async getCountryListRequest() {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/countries/select-options');

                this.countries = res.data.options;

            },

            async getStateListRequest(countryId) {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/states/select-options?country_id=' + countryId);

                this.states = res.data.options;

            },

            async handleCountryChange(value) {
                
                var found = this.checkCountry(value);

                if (found) {
                    if (value == '1') {
                        document.querySelectorAll('[for="normal_contact_state_id"]')[0].innerHTML = 'Province';
                        document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'block';
                        document.querySelectorAll('[data-label="state"]')[0].style.display = 'none';
                        document.querySelectorAll('[for="normal_contact_zip_code"]')[0].innerHTML = 'Postal Code';
                    }
                    else {
                        document.querySelectorAll('[for="normal_contact_state_id"]')[0].innerHTML = 'State';
                        document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'block';
                        document.querySelectorAll('[data-label="state"]')[0].style.display = 'none';
                        document.querySelectorAll('[for="normal_contact_zip_code"]')[0].innerHTML = 'Zip Code';
                    }    
                    
                }
                else {
                    document.querySelectorAll('[data-label="state_id"]')[0].style.display = 'none';
                    document.querySelectorAll('[data-label="state"]')[0].style.display = 'block';
                    if (value == '225') {
                        document.querySelectorAll('[for="normal_contact_state_caption"]')[0].innerHTML = 'County';
                        document.querySelectorAll('[for="normal_contact_zip_code"]')[0].innerHTML = 'Post Code';
                    }
                    this.state_id = 999;
                }
                
                this.form.setFieldsValue({
                    'state_id': '',
                    'state_caption': ''
                });
                this.getStateListRequest(value);
            },

            checkCountry(value) {

                let found = false;

                for (var i=0; i<this.countriesWithStateIds.length; i++) {
                    if (this.countriesWithStateIds[i] == value) {
                        found = true;
                        break;
                    }
                }

                // console.log('check country => ' + (found ? 'true' : 'false'));

                return found;

            },
            
            checkCanadaOrUs(rule, value, callback) {
                if (this.activation.input.product_types.alinity) {
                    if (['1', '226', 'Canada', 'United States'].includes(value)) {
                        callback();
                        return;
                    }
                    callback(t('This test is not available in the selected country for now.'));
                }
                else {
                    return true;
                }
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }        
    }
</script>

<style>
.app-contact {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-contact .contact-form {
  max-width: 300px;
}
#form-normal-contact .contact-form-forgot {
  float: right;
}
#form-normal-contact .contact-form-button {
  margin:10px 0 5px;
  width: 100%;
}
</style>