var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Alert'),(_vm.isLoggedIn)?_c('a-page-header',{staticClass:"app-page-title",staticStyle:{"margin":"0 0 24px"},attrs:{"ghost":false,"title":"Settings - General"}}):_vm._e(),_c('a-card',{staticClass:"with-border",attrs:{"bordered":false}},[_c('a-row',[_c('a-col',{staticClass:"form-container"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"general-form",attrs:{"id":"form-general","form":_vm.formGeneral,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleGeneralSubmit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'first_name',
                                        {
                                            initialValue: this.user.first_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'first_name',\n                                        {\n                                            initialValue: this.user.first_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large","readonly":"1","disabled":"disabled"}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'last_name',
                                        {
                                            initialValue: this.user.last_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'last_name',\n                                        {\n                                            initialValue: this.user.last_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large","readonly":"1","disabled":"disabled"}})],1)],1)],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":"Phone Number","help":_vm.errors.phone}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'country',
                                    {
                                        initialValue: this.user.phone_country,
                                        rules: [{ required: true, message: 'Please select a country code.' }] 
                                    }
                                ]),expression:"[\n                                    'country',\n                                    {\n                                        initialValue: this.user.phone_country,\n                                        rules: [{ required: true, message: 'Please select a country code.' }] \n                                    }\n                                ]"}],staticStyle:{"width":"35%"},attrs:{"show-search":"","placeholder":"Select one","filter-option":_vm.filterOption,"size":"large"}},_vm._l((_vm.codes),function(data){return _c('a-select-option',{key:data.code},[_vm._v(" "+_vm._s(data.name)+" ("+_vm._s(data.dial_code)+") ")])}),1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'phone',
                                    {
                                        initialValue: this.user.phone,
                                        rules: [{ required: true, message: 'Please enter your phone number.' }] 
                                    }
                                ]),expression:"[\n                                    'phone',\n                                    {\n                                        initialValue: this.user.phone,\n                                        rules: [{ required: true, message: 'Please enter your phone number.' }] \n                                    }\n                                ]"}],staticStyle:{"width":"65%"},attrs:{"placeholder":"Phone Number","size":"large","max-length":20}})],1)],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formGeneralSubmitting,"loading":_vm.formGeneralSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formGeneralSubmitting ? 'Submitting...' : 'Update Info')+" ")])],1),_c('a-form',{staticClass:"email-form",attrs:{"id":"form-email","form":_vm.formEmail,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleEmailSubmit.apply(null, arguments)}}},[_c('a-form-item',{class:_vm.fieldHasError.email,staticStyle:{"margin":"0"},attrs:{"label":"Email","help":_vm.errors.email}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                                {
                                    rules: [{ required: true, message: 'Please enter your email.' }],
                                    initialValue: this.user.email
                                },
                            ]),expression:"[\n                            'email',\n                                {\n                                    rules: [{ required: true, message: 'Please enter your email.' }],\n                                    initialValue: this.user.email\n                                },\n                            ]"}],attrs:{"placeholder":"johndoe@mail.com","size":"large"}})],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formEmailSubmitting,"loading":_vm.formEmailSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formEmailSubmitting ? 'Submitting...' : 'Update Email')+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }