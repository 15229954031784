var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Alert'),(_vm.isLoggedIn)?_c('a-page-header',{staticClass:"app-page-title",staticStyle:{"margin":"0 0 24px"},attrs:{"ghost":false,"title":"Settings - Security and Login"}}):_vm._e(),_c('a-card',{staticClass:"with-border",attrs:{"bordered":false}},[_c('a-row',[_c('a-col',{staticClass:"form-container"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"email-form",attrs:{"id":"form-email","form":_vm.formEmail,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleEmailSubmit.apply(null, arguments)}}},[_c('a-form-item',{class:_vm.fieldHasError.email,staticStyle:{"margin":"0"},attrs:{"label":"Email","help":_vm.errors.email}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                                {
                                    rules: [{ required: true, message: 'Please enter your email.' }],
                                    initialValue: this.user.email
                                },
                            ]),expression:"[\n                            'email',\n                                {\n                                    rules: [{ required: true, message: 'Please enter your email.' }],\n                                    initialValue: this.user.email\n                                },\n                            ]"}],attrs:{"placeholder":"johndoe@mail.com","size":"large"}})],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formEmailSubmitting,"loading":_vm.formEmailSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formEmailSubmitting ? 'Submitting...' : 'Update Email')+" ")])],1),_c('a-form',{staticClass:"password-form",attrs:{"id":"form-password","form":_vm.formPassword,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handlePasswordSubmit.apply(null, arguments)}}},[_c('a-form-item',{class:_vm.fieldHasError.old_password,attrs:{"label":"Current Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'old_password',
                                { rules: [
                                    { required: true, message: 'Please enter your current password.' },
                                ] },
                            ]),expression:"[\n                            'old_password',\n                                { rules: [\n                                    { required: true, message: 'Please enter your current password.' },\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.old_password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.old_password))]):_vm._e()],1),_c('a-form-item',{class:_vm.fieldHasError.password,attrs:{"label":"New Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'password',
                                { rules: [
                                    { required: true, message: 'Please enter your new password.' },
                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: 'Use 8 or more characters with a mix of letters and numbers.' }
                                ] },
                            ]),expression:"[\n                            'password',\n                                { rules: [\n                                    { required: true, message: 'Please enter your new password.' },\n                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\\d).{8,}$/), message: 'Use 8 or more characters with a mix of letters and numbers.' }\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.password))]):_vm._e()],1),_c('a-form-item',{class:_vm.fieldHasError.re_password,staticStyle:{"margin":"0"},attrs:{"label":"Confirm New Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            're_password',
                                { rules: [
                                    { required: true, message: 'Please confirm your new password.' },
                                    { validator: _vm.checkPassword }
                                ] },
                            ]),expression:"[\n                            're_password',\n                                { rules: [\n                                    { required: true, message: 'Please confirm your new password.' },\n                                    { validator: checkPassword }\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.re_password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.re_password))]):_vm._e()],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formPasswordSubmitting,"loading":_vm.formPasswordSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formPasswordSubmitting ? 'Submitting...' : 'Update Password')+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }