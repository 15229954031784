import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faExclamationTriangle, faQuestionCircle, faCheckCircle, faSpinner, faUserCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'
import store from './vuex'
import VueHead from 'vue-head'

import moment from 'moment'
import Maska from 'maska'

// import { t } from './utils.js'

// import Zendesk from '@dansmaculotte/vue-zendesk'

import axiosFrontInstance from './apiFront'
import axiosUserInstance from './apiUser'

import "@fontsource/poppins"
import "@fontsource/poppins/600.css"

library.add(faUserSecret, faExclamationTriangle, faQuestionCircle, faCheckCircle, faSpinner, faUserCircle, faShoppingCart);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.$t = function t(key, ...args) {
  let currentIndex = 0;
  const translated = this.translation[key];
  return translated.replace(/%s/g, () => {
    const value = args[currentIndex];
    currentIndex++;
    return typeof value !== 'undefined' ? value : '';
  });
};

Vue.prototype.$http = axiosUserInstance;

axiosUserInstance.defaults.timeout = 20000;

axiosUserInstance.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.use(Antd);
Vue.use(VueHead);
Vue.use(moment);
Vue.use(Maska);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {

    const routeParams = this.$route.params;
    const regionId = routeParams.region_id;
    const websiteId = routeParams.website_id;

    axiosUserInstance.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      async (error) => {
        if (error.response.status) {
          switch (error.response.status) {
            case 400:
              break;
            case 401:
              break;
            case 403:
              
              if (store.getters.getRefreshToken) {
                try {
                  
                  const response = await axiosUserInstance.post(regionId + '/' + websiteId + '/auth/refresh', {
                    refresh_token: store.getters.getRefreshToken,
                  });

                  store.dispatch('setToken', response.data.access_token);
                  store.dispatch('setRefreshToken', response.data.refresh_token);

                  router.go();

                }
                catch (_error) {
                  store.dispatch('setUser', {});
                  store.dispatch('setToken', null);
                  store.dispatch('setRefreshToken', null);
                  window.location.href = '/' + router.currentRoute.params.region_id + '/' + router.currentRoute.params.website_id + '/login?redirect=' + encodeURIComponent(router.currentRoute.fullPath);
                }
              }
              break;
            case 404:
              alert('Error ' + error.response.status + ': page does not exist.');
              break;
            case 502:
              alert('Error ' + error.response.status + ' detected.');
              setTimeout(() => {
                router.push('/' + router.currentRoute.params.region_id + '/' + router.currentRoute.params.website_id + '/login?redirect=' + router.currentRoute.fullPath);
              }, 1000);
              return;
          }
          return Promise.reject(error.response);
        }
      }
    );

    axiosFrontInstance.get(regionId + '/' + websiteId + '/locales/translations')
      .then(response => {
        const data = response.data;
        store.dispatch('setTranslation', data.translations);
        store.dispatch('setLanguage', data.locale);
      });
  }
}).$mount('#app');

