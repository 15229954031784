<template>
    <div>
        <ActivateStep v-if="$store.getters.getActivation.hash" step="phone-verify" />
        <a-row>
            <a-col class="app-phone">

                <h2>Skip phone verification</h2>
                <p style="margin-bottom:30px;">If you do not verify your phone number, you will not receive test updates by SMS.</p>

                <a-popconfirm
                    title="Are you sure you don't want to receive updates via SMS?"
                    ok-text="Yes, Proceed"
                    cancel-text="No"
                    @confirm="confirm"
                    @cancel="cancel"
                >
                    <p style="margin-bottom:30px;"><a href="#" class="phone-form-button ant-btn ant-btn-primary ant-btn-lg" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">Proceed without updates by SMS</a></p>

                </a-popconfirm>

                <div style="text-align:center">
                    <p v-if="$route.query.via == 'activate'"><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-verify', query: { via: 'activate' } }">Re-send verification code</router-link></p>
                    <p v-else><router-link :to="{ path: '/' + regionId + '/' + websiteId + '/phone-verify' }">Re-send verification code</router-link></p>
                </div>


            </a-col>
        </a-row>
    </div>
</template>

<script>

    import ActivateStep from './ActivateStep.vue'

    export default {
        name: 'PhoneSkipComponent',
        components: {
            ActivateStep
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID
            }
        },
        methods: {
            confirm() {
                
                this.$store.dispatch('setPhone', null);
                
                var nextUrl = '';

                if (this.$store.getters.getActivation.input.product_types.alinity == true && this.$store.getters.getActivation.input.activation_mode != 'key') {
                    nextUrl = 'health-info';
                }
                else {
                    nextUrl = 'setup';
                }

                this.$router.push(nextUrl);
            },
            cancel() {
                // console.log(e);
                // this.$message.error('Click on No');
            },
        },
    }
</script>

<style>
    .phone-form-button {
        display:block;
    }
    .ant-btn-sm {
        height:28px;
    }
    .ant-popover-message {
        padding-bottom:20px;
    }
</style>