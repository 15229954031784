var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Alert'),(_vm.isLoggedIn)?_c('a-page-header',{staticClass:"app-page-title",staticStyle:{"margin":"0 0 24px"},attrs:{"ghost":false,"title":_vm.t('Settings')}}):_vm._e(),_c('a-card',{staticClass:"with-border",attrs:{"bordered":false}},[_c('a-row',[_c('a-col',{staticClass:"form-container"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"general-form",attrs:{"id":"form-general","form":_vm.formGeneral,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleGeneralSubmit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":_vm.t('First Name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'first_name',
                                        {
                                            initialValue: this.user.first_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'first_name',\n                                        {\n                                            initialValue: this.user.first_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large","readonly":"1","disabled":"disabled"}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":_vm.t('Last Name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'last_name',
                                        {
                                            initialValue: this.user.last_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'last_name',\n                                        {\n                                            initialValue: this.user.last_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large","readonly":"1","disabled":"disabled"}})],1)],1)],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.t('Phone Number'),"help":_vm.errors.phone}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'country',
                                    {
                                        initialValue: this.user.phone_country,
                                        rules: [{ required: true, message: _vm.t('Please select a country code.') }] 
                                    }
                                ]),expression:"[\n                                    'country',\n                                    {\n                                        initialValue: this.user.phone_country,\n                                        rules: [{ required: true, message: t('Please select a country code.') }] \n                                    }\n                                ]"}],staticStyle:{"width":"45%"},attrs:{"show-search":"","placeholder":_vm.t('Select one'),"filter-option":_vm.filterOption,"size":"large"}},_vm._l((_vm.codes),function(data){return _c('a-select-option',{key:data.code},[_vm._v(" "+_vm._s(data.name)+" ("+_vm._s(data.dial_code)+") ")])}),1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'phone',
                                    {
                                        initialValue: this.user.phone,
                                        rules: [{ required: true, message: _vm.t('Please enter your phone number.') }] 
                                    }
                                ]),expression:"[\n                                    'phone',\n                                    {\n                                        initialValue: this.user.phone,\n                                        rules: [{ required: true, message: t('Please enter your phone number.') }] \n                                    }\n                                ]"}],staticStyle:{"width":"55%"},attrs:{"placeholder":_vm.t('Phone Number'),"size":"large","max-length":20}})],1)],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formGeneralSubmitting,"loading":_vm.formGeneralSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formGeneralSubmitting ? _vm.t('Submitting...') : _vm.t('Update Info'))+" ")])],1),_c('a-form',{staticClass:"email-form",attrs:{"id":"form-email","form":_vm.formEmail,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleEmailSubmit.apply(null, arguments)}}},[_c('a-form-item',{class:_vm.fieldHasError.email,staticStyle:{"margin":"0"},attrs:{"label":_vm.t('Email'),"help":_vm.errors.email}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                                {
                                    rules: [{ required: true, message: _vm.t('Please enter your Email.') }],
                                    initialValue: this.user.email
                                },
                            ]),expression:"[\n                            'email',\n                                {\n                                    rules: [{ required: true, message: t('Please enter your Email.') }],\n                                    initialValue: this.user.email\n                                },\n                            ]"}],attrs:{"placeholder":"johndoe@mail.com","size":"large"}})],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formEmailSubmitting,"loading":_vm.formEmailSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formEmailSubmitting ? _vm.t('Submitting...') : _vm.t('Update Email'))+" ")])],1),_c('a-form',{staticClass:"password-form",attrs:{"id":"form-password","form":_vm.formPassword,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handlePasswordSubmit.apply(null, arguments)}}},[_c('a-form-item',{class:_vm.fieldHasError.old_password,attrs:{"label":_vm.t('Current Password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'old_password',
                                { rules: [
                                    { required: true, message: _vm.t('Please enter your current password.') },
                                ] },
                            ]),expression:"[\n                            'old_password',\n                                { rules: [\n                                    { required: true, message: t('Please enter your current password.') },\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.old_password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.old_password))]):_vm._e()],1),_c('a-form-item',{class:_vm.fieldHasError.password,attrs:{"label":_vm.t('New Password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'password',
                                { rules: [
                                    { required: true, message: _vm.t('Please enter your new password.') },
                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: 'Use 8 or more characters with a mix of letters and numbers.' }
                                ] },
                            ]),expression:"[\n                            'password',\n                                { rules: [\n                                    { required: true, message: t('Please enter your new password.') },\n                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\\d).{8,}$/), message: 'Use 8 or more characters with a mix of letters and numbers.' }\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.password))]):_vm._e()],1),_c('a-form-item',{class:_vm.fieldHasError.re_password,staticStyle:{"margin":"0"},attrs:{"label":_vm.t('Confirm New Password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            're_password',
                                { rules: [
                                    { required: true, message: _vm.t('Please confirm your new password.') },
                                    { validator: _vm.checkPassword }
                                ] },
                            ]),expression:"[\n                            're_password',\n                                { rules: [\n                                    { required: true, message: t('Please confirm your new password.') },\n                                    { validator: checkPassword }\n                                ] },\n                            ]"}],attrs:{"placeholder":"","size":"large"}}),(_vm.errors.re_password )?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.errors.re_password))]):_vm._e()],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formPasswordSubmitting,"loading":_vm.formPasswordSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formPasswordSubmitting ? _vm.t('Submitting...') : _vm.t('Update Password'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }