<template>
    <div>
        <a-row>
            <a-col class="app-reset">

                <h2>{{ t('Choose a new password') }}</h2>
                <p style="margin-bottom:30px;">{{ t('Please enter your new password.') }}</p>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-reset"
                    :form="form"
                    class="reset-form"
                    layout="vertical"
                    @submit.prevent="handleSubmit"
                >

                    <a-form-item
                      label="Password"
                    >
                        <a-input-password
                            v-decorator="[
                            'password',
                                { rules: [
                                    { required: true, message: t('Please enter your password.') },
                                    { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/), message: t('Use 8 or more characters with a mix of letters and numbers.') }
                                ] },
                            ]"
                            type="password"
                            placeholder=""
                            size="large"
                        >
                        </a-input-password>
                    </a-form-item>
                    <a-form-item
                      label="Confirm Password"
                    >
                        <a-input-password
                            v-decorator="[
                            're_password',
                                { rules: [
                                    { required: true, message: t('Please confirm your password.') },
                                    { validator: checkPassword }
                                ] },
                            ]"
                            type="password"
                            placeholder=""
                            size="large"
                        >
                        </a-input-password>
                    </a-form-item>

                    <a-form-item style="margin-bottom:30px;">
                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="reset-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Submitting...') : t('Confirm Password') }}
                        </a-button>
                    </a-form-item>

                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axiosUserInstance from '../apiUser'
    import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'PasswordResetComponent',
        components: {
            Error
        },
        computed: {
            website : function(){ return this.$store.getters.getWebsite}
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                password: '',
                re_password: '',
                error: '',
                t: t
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            })
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_reset' });
        },
        methods: {

            async handleSubmit() {
                try {

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();  

                    if (firstRes) {
                      
                        await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/passwords/reset', {
                            password: firstRes.password,
                            re_password: firstRes.re_password,
                            code: this.$route.params.code,
                            region_id: this.regionId
                        });

                        this.error = '';
                        this.$router.push('/' + this.regionId + '/' + this.websiteId + '/password/complete');

                    }
                    
                } catch(e) {
                    this.formSubmitting = false;
                    // var err = e.errors.password.errors;
                    this.error = 'Error found. Please correct the form below and try again.';
                }
                
            },
            checkPassword(rule, value, callback) {
                if (value === this.form.getFieldValue('password')) {
                    callback();
                    return;
                }
                callback('Passwords must match.');
            }
        }        
    }
</script>

<style>
.app-reset {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-reset .reset-form {
  max-width: 300px;
}
#form-normal-reset .reset-form-forgot {
  float: right;
}
#form-normal-reset .reset-form-button {
  margin:10px 0 5px;
  width: 100%;
}
</style>