<template>

    <div>
           
        <div data-show="true" class="ant-alert ant-alert-error ant-alert-with-description" v-if="this.consoleMessageData.length > 0">
            <i aria-label="icon: close-circle" class="anticon anticon-close-circle ant-alert-icon"><svg viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></i>
            <span class="ant-alert-message"><strong>{{ t('Alert') }}</strong></span>
            <span class="ant-alert-description" v-html="this.consoleMessageHtml"></span>
        </div>

        <div v-if="owingOrderList.length > 0">

            <div data-show="true" class="ant-alert ant-alert-error ant-alert-with-description" v-for="(data, index) in this.owingOrderList" :key="index">
                <i aria-label="icon: close-circle" class="anticon anticon-close-circle ant-alert-icon"><svg viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></i>
                <span class="ant-alert-message"><strong>{{ t('Alert') }}</strong></span>
                <span class="ant-alert-description">
                    {{ t('There is an outstanding balance of %s on Order ID %s.', data.amount_owing_formatted, data.client_order_full_id) }}
                    <a @click="handlePaymentLink" :data-order_id="data.client_order_id">{{ t('Click here') }}</a> {{ t('to make a payment on this order.') }}
                </span>
            </div>

        </div>

    </div>

</template>

<script>

    import axiosUserInstance from '../apiUser'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'AlertComponent',
        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken}
        },
        mounted: function() {
            this.getMessageListRequest();
            this.getOwingOrderListRequest();
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                consoleMessageHtml: '',
                consoleMessageData: [],
                owingOrderList: [],
                t: t
            }
        },
        methods: {

            async getMessageListRequest() {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/account/console-messages', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.consoleMessageData = res.data.messages;

                if (this.consoleMessageData.length > 0) {
                    var html = '';
                    for (var i = 0; i < this.consoleMessageData.length; i++) {
                        html += '<p' + (i + 1 == this.consoleMessageData.length ? ' style="margin-bottom:0;"' : '') + '><strong>' + this.consoleMessageData[i].order_full_id + ':</strong> ' + this.consoleMessageData[i].message + '</p>';
                    }
                    this.consoleMessageHtml = html;
                }

            },

            async getOwingOrderListRequest() {
                
                let res = await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/outstanding', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.owingOrderList = res.data.orders;

                // console.log('owingOrderList');
                // console.log(this.owingOrderList);

            },

            async handlePaymentLink(e) {
                var orderId = e.target.getAttribute('data-order_id'),
                    uri = '/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/payment/' + orderId;
                // console.log(uri);
                this.$router.push(uri);
            },

        }
    }
</script>

<style scoped>
    .ant-alert-description a:hover {
        text-decoration: underline;
    }
</style>