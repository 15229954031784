<template>

    <div>

        <div v-if="!paymentCompleted">

            <div v-if="orderView.amount_owing > 0">

                <h2>{{ t('Submit Payment') }}</h2>

                <p style="margin-bottom:30px;">
                    {{ t('You are about to pay for an outstanding balance of %s on Order ID %s.', orderView.amount_owing_formatted, orderView.client_order_full_id) }}</p>

                <error v-if="stripeError" :error="stripeError" />

                <label>{{ t('Card Number') }}</label>
                <div id="card-number-element"></div>
                <div class="help-block">
                    {{ cardNumberError }}
                </div>
                
                <label>{{ t('Card Expiration Date') }}</label>
                <div id="card-expiry-element"></div>
                <div class="help-block">
                    {{ cardExpiryError }}
                </div>
                <label>{{ t('CVC') }}</label>
                <div id="card-cvc-element"></div>
                <div class="help-block">
                    {{ cardCvcError }}
                </div>

                <div id="card-error"></div>

                <a-button type="primary" id="btn-generate" :disabled="formSubmitting" :loading="formSubmitting" class="btn-pay" @click="handleSubmit" html-type="submit" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                    <span>
                        {{ formSubmitting ? t('Submitting...') : t('Submit Payment') }}
                    </span>
                </a-button>

                <div style="text-align:center;margin-top:20px;margin-bottom:30px;">
                    <div v-if="isLoggedIn">
                        <router-link :to="'/' + regionId + '/' + websiteId + '/account'">{{ t('Return to your account') }}</router-link>
                    </div>
                    <div v-else>
                        <router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link>
                    </div>
                </div>

            </div>

            <div v-else>

                <div v-if="isPageReady">

                    <h2>Submit Payment</h2>

                    <div style="margin-bottom:30px;">
                        <span v-if="stripeError">
                            <error v-if="stripeError" :error="stripeError" />
                        </span>
                        <span v-else>
                            {{ t('There is currently no outstanding balance for Order ID %s.', orderView.client_order_full_id) }}
                        </span>
                    </div>

                    <div style="margin-bottom:30px;" v-if="isLoggedIn">
                        <router-link :to="'/' + regionId + '/' + websiteId + '/account'">{{ t('Return to your account') }}</router-link>
                    </div>
                    <div v-else>
                        <router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link>
                    </div>

                </div>

            </div>

        </div>

        <div v-else>

            <span class="centered" style="display:block;">

                <div class="icon-success"><font-awesome-icon icon="check-circle" class="fa-8x" /></div>

                <h2>{{ t('Payment Successful!') }}</h2>

                <div class="payment-successful-message">
                    <span v-if="isLoggedIn">{{ t('Please return to your dashboard to view the payment details.') }}</span><span v-else>{{ t('Please log into your account to view the payment details.') }}</span>
                </div>

                <div style="text-align:center;margin-bottom:30px;" v-if="isLoggedIn">
                    <router-link :to="'/' + regionId + '/' + websiteId + '/account'">{{ t('Return to your account') }}</router-link>
                </div>
                <div v-else>
                    <router-link :to="'/' + regionId + '/' + websiteId + '/'">{{ t('Return to home') }}</router-link>
                </div>
            </span>

        </div>

    </div>

</template>

<script>
    
    import axios from 'axios'
    import axiosUserInstance from '../../apiUser'
    import Error from '../Error.vue'
    import moment from 'moment'
    import { loadStripe } from '@stripe/stripe-js'

    // eslint-disable-next-line no-unused-vars
    import t from '../../utils'
    
    let stripe,
        customer,
        paymentIntent,
        paymentMethod,
        elements;

    export default {
        name: 'FormPaymentComponent',
        components: {
            Error
        },
        watch: {
            stripeKey(val) {
                this.stripeKey = val;
            },
            orderView(val) {
                this.orderView = val;
            },
            
        },
        computed: {
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            website : function(){ return this.$store.getters.getWebsite},
            token : function(){ return this.$store.getters.isAuthenticated ? this.$store.getters.getToken : null},

        },
        mounted: function() {
            this.getStripeKey();
        },
        data() {  
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                orderId: this.$route.params.order_id ? this.$route.params.order_id : '',
                stripeKey: null,
                clientSecret: null,
                formSubmitting: false,
                stripeKeyLoaded: false,
                paymentCompleted: false,
                isPageReady: false,
                orderView: {},

                isLive: true,

                // Stripe elements
                stripeToken: null,
                cardNumberElement: null,
                cardExpiryElement: null,
                cardCvcElement: null,

                // Stripe errors
                stripeError: '',
                cardCvcError: '',
                cardExpiryError: '',
                cardNumberError: '',

                card: {
                    cvc: '',
                    number: '',
                    expiry: '',
                },

                error: '',

                t: t
            }   
        },
        methods: {

            setLoading(isLoading) {
                if (isLoading) {
                    // Disable the button and show a spinner
                    document.querySelector("#btn-pay").disabled = true;
                    document.querySelector("#spinner").classList.remove("hidden");
                    document.querySelector("#button-text").classList.add("hidden");
                }
                else {
                    document.querySelector("#btn-pay").disabled = false;
                    document.querySelector("#spinner").classList.add("hidden");
                    document.querySelector("#button-text").classList.remove("hidden");
                }
            },

            async loadStripeLibrary(publishableKey) {
                stripe = await loadStripe(publishableKey);
            },

            async getStripeKey() {

                await axiosUserInstance.get(this.regionId + '/' + this.websiteId + '/orders/' + this.orderId + '/payment-gateway', {
                    headers: { 'Authorization': (this.isLoggedIn ? 'Bearer ' + this.token : null) }
                })
                    .then( res => {

                        // this.stripeKey = res.data.payment_gateway.stripe_test_publishable_key;

                        this.isPageReady = true;

                        if (this.isLive) {
                            this.stripeKey = res.data.payment_gateway.stripe_live_publishable_key;
                        }
                        else {
                            this.stripeKey = res.data.payment_gateway.stripe_test_publishable_key;
                        }

                        this.orderView = res.data.payment_gateway;

                        this.loadStripeLibrary(this.stripeKey);

                        // stripe = window.Stripe(this.stripeKey);

                        /*
                        
                        console.log('token');
                        console.log(this.token);

                        console.log('orderView');
                        console.log(this.orderView);

                        console.log('stripeKey');
                        console.log(this.stripeKey);

                        console.log('stripe');
                        console.log(stripe);

                        */

                        if (this.orderView.amount_owing > 0) {
                            this.generatePaymentIntent();
                        }

                    })
                    .catch( err => {

                        this.stripeError = err;

                        // console.log(err);

                        window.location.href = '/' + this.regionId + '/' + this.websiteId + (this.isLoggedIn ? '/account' : '/');

                    });

            },

            async getPaymentIntent(paymentIntentId) {

                let res = await axios.get('https://www.ssl-status.com/api/stripe/get-payment-intent?region_id=' + this.regionId + '&payment_intent_id=' + paymentIntentId + (this.isLive ? '' : '&live=0'));

                paymentIntent = res.data.item;

                return paymentIntent;

            },

            async getPaymentMethod(paymentMethodId) {

                let res = await axios.get('https://www.ssl-status.com/api/stripe/get-payment-method?region_id=' + this.regionId + '&payment_method_id=' + paymentMethodId + (this.isLive ? '' : '&live=0'));

                paymentMethod = res.data.item;

                return paymentMethod;

            },

            async generateCustomer(paymentMethodId) {

                let res = await axios.get('https://www.ssl-status.com/api/stripe/create-customer?region_id=' + this.regionId + '&payment_method_id=' + paymentMethodId + (this.isLive ? '' : '&live=0'));

                customer = res.data.item;

                console.log('customer orig');
                console.log(customer.id);

                return customer;

            },

            async generatePaymentIntent() {

                let shippingAddress = {};

                if (this.orderView.country_code != '') {
                    shippingAddress.country = this.orderView.country_code;
                    shippingAddress.postalCode = this.orderView.zip_code;
                }

                let res = await axios.get('https://www.ssl-status.com/api/stripe/create-payment-intent?region_id=' + this.regionId + '&amount=' + this.orderView.amount_owing + '&currency=' + this.orderView.currency + (this.isLive ? '' : '&live=0'));

                paymentIntent = res.data.item;

                /*
                console.log('generatePaymentIntent:');
                console.log('paymentIntent');
                console.log(paymentIntent);
                */

                this.clientSecret = paymentIntent.client_secret;

                const options = {
                    clientSecret: this.clientSecret,
                }   

                elements = stripe.elements(options);

                // console.log('elements');
                // console.log(elements);

                var style = {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        lineHeight: '40px',
                        fontWeight: 300,
                        fontFamily: 'Helvetica Neue',
                        fontSize: '15px',
                        '::placeholder': {
                            color: '#CFD7E0',
                        },
                    },
                };

                this.cardNumberElement = elements.create('cardNumber', {
                    style: style
                });
                this.cardNumberElement.mount('#card-number-element');

                this.cardExpiryElement = elements.create('cardExpiry', {
                    style: style
                });
                this.cardExpiryElement.mount('#card-expiry-element');

                this.cardCvcElement = elements.create('cardCvc', {
                    style: style
                });
                this.cardCvcElement.mount('#card-cvc-element');

                this.listenForErrors();

                this.stripeKeyLoaded = true;
                
            },

            listenForErrors() {

                this.cardNumberElement.addEventListener('change', (event) => {
                    this.toggleError(event, 'cardNumber');
                    this.card.number = event.complete ? true : false
                });
                        
                this.cardExpiryElement.addEventListener('change', (event) => {
                    this.toggleError(event, 'cardExpiry');
                    this.card.expiry = event.complete ? true : false
                });
                
                this.cardCvcElement.addEventListener('change', (event) => {
                    this.toggleError(event, 'cardCvc');
                    this.card.cvc = event.complete ? true : false
                });

            },

            toggleError(event, ele) {
                
                if (event.error) {
                    // this.stripeError = event.error.message;
                    switch (ele) {
                        case 'cardNumber':
                            this.cardNumberError = event.error.message;
                            break;
                        case 'cardExpiry':
                            this.cardExpiryError = event.error.message;
                            break;
                        case 'cardCvc':
                            this.cardCvcError = event.error.message;
                            break;
                    }
                }
                else {
                    this.stripeError = '';
                    switch (ele) {
                        case 'cardNumber':
                            this.cardNumberError = '';
                            break;
                        case 'cardExpiry':
                            this.cardExpiryError = '';
                            break;
                        case 'cardCvc':
                            this.cardCvcError = '';
                            break;
                        case 'cardHolder':
                            this.cardHolderError = '';
                            break;
                    }
                }
            },

            clearCardErrors() {
                this.stripeError = '';
                this.cardCvcError = '';
                this.cardExpiryError = '';
                this.cardNumberError = '';
            },

            handleSubmit() {
                this.clearCardErrors();

                this.formSubmitting = true;

                let valid = true;

                if (!this.card.number) {
                    valid = false;
                    this.cardNumberError = "Card Number is invalid.";
                }
                if (!this.card.cvc) {
                    valid = false;
                    this.cardCvcError = "CVC is required.";
                }
                if (!this.card.expiry) {
                    valid = false;
                    this.cardExpiryError = "Expiration Date is invalid.";
                }
                if (!this.card.cardholder) {
                    valid = false;
                    this.cardHolderError = "Cardholder's name is invalid.";
                }
                if (this.stripeError) {
                    valid = false;
                }

                this.createToken();

                if (valid) {
                    // this.formSubmitting = false;
                }
                else {
                    // this.formSubmitting = false;
                }
            },  

            async createToken() {

                await stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                        card: this.cardNumberElement
                    },
                })
                .then(async result => {

                    if (result.error) {
                        switch (result.error.code) {
                            case 'authentication_required':
                            case 'card_declined':
                            case 'incorrect_cvc':
                            case 'processing_error':
                                this.stripeError = result.error.message; 
                                break;
                            default:
                                this.stripeError = 'Please correct the problem(s) below and submit again.';
                        }
                        this.formSubmitting = false;
                    }
                    else if (result.paymentIntent) {

                        // console.log('result');
                        // console.log(result);
                            
                        if (result.paymentIntent.status === 'succeeded') {

                            // console.log('succeeded!');
                            
                            await this.generateCustomer(result.paymentIntent.payment_method)
                                .then(
                                    async customer => {
                                        if (customer.id) {

                                            // console.log('result.paymentIntent');
                                            // console.log(result.paymentIntent);

                                            await this.getPaymentMethod(result.paymentIntent.payment_method)
                                                .then(
                                                    async paymentMethod => {

                                                        // console.log('paymentMethod');
                                                        // console.log(paymentMethod);
                                                        // console.log('paymentMethod.card');
                                                        // console.log(paymentMethod.card);

                                                        await this.getPaymentIntent(result.paymentIntent.id)
                                                            .then(
                                                                async resPaymentIntent => {

                                                                    let paymentObject = {
                                                                        credit_card: {
                                                                            card_number: paymentMethod.card.last4,
                                                                            credit_card_type_caption: paymentMethod.card.brand,
                                                                            card_holder: 'Cardholder for Order ID ' + this.orderView.client_order_full_id,
                                                                            expiry_month: (paymentMethod.card.exp_month < 10 ? '0' : '') + String(paymentMethod.card.exp_month),
                                                                            expiry_year: paymentMethod.card.exp_year,
                                                                            stripe_customer_id: paymentMethod.customer,
                                                                            stripe_payment_intent_id: result.paymentIntent.id,
                                                                            stripe_payment_method_id: paymentMethod.id,
                                                                            token: paymentMethod.id,
                                                                        },
                                                                        transaction: {
                                                                            payment_gateway_account_id: this.orderView.payment_gateway_account_id,
                                                                            payment_method_id: 5, // CM: credit card
                                                                            quoted_amount: parseFloat(this.orderView.amount_owing),
                                                                            quoted_currency_id: this.orderView.currency_id,
                                                                            // actual_amount: parseFloat((resPaymentIntent.charges.data[0].balance_transaction.amount / 100).toFixed(2)),
                                                                            actual_currency_id: this.orderView.payment_gateway_currency_id,
                                                                            // stripe_net_amount: parseFloat((resPaymentIntent.charges.data[0].balance_transaction.net / 100).toFixed(2)),
                                                                            // stripe_fee: parseFloat((resPaymentIntent.charges.data[0].balance_transaction.fee / 100).toFixed(2)),
                                                                            payment_gateway_account_code: 'live',
                                                                            gateway_approval_code: paymentMethod.card.fingerprint,
                                                                            gateway_payment_intent_id: result.paymentIntent.id,
                                                                            // gateway_transaction_id: resPaymentIntent.charges.data[0].balance_transaction.source,
                                                                            // gateway_transaction_start: resPaymentIntent.charges.data[0].balance_transaction.created,
                                                                            // gateway_transaction_end: resPaymentIntent.charges.data[0].balance_transaction.created,
                                                                            paid: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                                                                            is_refund: 0,
                                                                            is_stripe: 1,
                                                                            stripe_payment_intent_id: result.paymentIntent.id,
                                                                            stripe_charge_id: resPaymentIntent.latest_charge,
                                                                            // stripe_balance_transaction_id: resPaymentIntent.charges.data[0].balance_transaction.id,
                                                                            stripe_balance_transaction_required: 1,
                                                                            memo: JSON.stringify(paymentMethod)
                                                                        }
                                                                    }

                                                                    paymentIntent = resPaymentIntent;

                                                                    // console.log('getPaymentIntent');
                                                                    // console.log('paymentIntent');
                                                                    // console.log(paymentIntent);

                                                                    // console.log('paymentObject');
                                                                    // console.log(paymentObject);

                                                                    await axiosUserInstance.post(this.regionId + '/' + this.websiteId + '/orders/' + this.orderId + '/transaction', {
                                                                        payment: paymentObject,     
                                                                    }, {
                                                                        headers: { 'Authorization': (this.isLoggedIn ? 'Bearer ' + this.token : null) } 
                                                                    });

                                                                    // console.log('res final');
                                                                    // console.log(res);

                                                                    this.paymentCompleted = true;

                                                                    
                                                                }
                                                            );

                                                    }
                                                );

                                        }
                                    }
                                );

                        }
                    }
                });

            }

        }
    }
</script>

<style>
button > svg {
    margin-right:10px;
}
.btn-pay {
    margin:20px 0;
    width:100%;
}
.centered {
    text-align:center;
}
.icon-success {
    color:#3e8b27;
    margin:20px 0;
}
.hidden {
    display:none;
}
.help-block {
    color:#df1b41;
    margin-bottom:15px;
}
.payment-successful-message {
    margin:0 0 20px;
}
.StripeElement {
    border: 1px solid #d9d9d9;
    padding: 0 11px;
    border-radius: 4px;
    margin-top: 3px;
    margin-bottom: 15px;
}
.StripeElement--invalid, .has-stripe-error {
    border-color: transparent !important        ;
    box-shadow: 0 0 0 2px #df1b41;  
    margin-bottom:5px !important;
}
.StripeElement--focus {
    outline: 0;
    border-color:hsla(210, 96%, 45%, 50%);
    -webkit-box-shadow: 0px 0px 5px  #4D90FE;
    box-shadow: 0px 0px 5px  #4D90FE;
}
</style>