var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.getters.getActivation.hash)?_c('ActivateStep',{attrs:{"step":"contact"}}):_vm._e(),_c('a-row',[_c('a-col',{staticClass:"app-contact"},[_c('h2',{staticStyle:{"margin-bottom":"30px"}},[_vm._v(_vm._s(_vm.t('Contact Information')))]),(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"contact-form",attrs:{"id":"form-normal-contact","form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleContactSubmit.apply(null, arguments)}}},[(_vm.activation.input.product_types.relationship == false && _vm.activation.input.activation_mode != 'key')?_c('div',[_c('h3',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(_vm._s(_vm.t('Name of person taking the %s', _vm.activation.input.product_labels[0])))]),_c('a-row',{staticStyle:{"margin-bottom":"10px"},attrs:{"gutter":8}},[_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":_vm.t('First Name of Test Taker')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'first_name',
                                        {
                                            initialValue: _vm.isLoggedIn ? _vm.user.first_name : _vm.signup.input.first_name,
                                            rules: [{ required: true, message: _vm.t('Please enter your first name.') }]
                                        },
                                    ]),expression:"[\n                                    'first_name',\n                                        {\n                                            initialValue: isLoggedIn ? user.first_name : signup.input.first_name,\n                                            rules: [{ required: true, message: t('Please enter your first name.') }]\n                                        },\n                                    ]"}],attrs:{"placeholder":"","size":"large","disabled":_vm.inputDisabled}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":_vm.t('Last Name of Test Taker')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'last_name',
                                        {
                                            initialValue: _vm.isLoggedIn ? _vm.user.last_name : _vm.signup.input.last_name,
                                            rules: [{ required: true, message: _vm.t('Please enter your last name.') }]
                                        },
                                    ]),expression:"[\n                                    'last_name',\n                                        {\n                                            initialValue: isLoggedIn ? user.last_name : signup.input.last_name,\n                                            rules: [{ required: true, message: t('Please enter your last name.') }]\n                                        },\n                                    ]"}],attrs:{"placeholder":"","size":"large","disabled":_vm.inputDisabled}})],1)],1)],1),_c('h3',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(_vm._s(_vm.t('Address')))])],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.t('Address Line 1')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'address_1',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.address_1 : '',
                                rules: [{ required: true, message: _vm.t('Please enter your address.') }]
                            },
                        ]),expression:"[\n                        'address_1',\n                            {\n                                initialValue: isLoggedIn ? user.address_1 : '',\n                                rules: [{ required: true, message: t('Please enter your address.') }]\n                            },\n                        ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":_vm.t('Address Line 2')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'address_2',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.address_2 : '',
                                rules: [{ required: false }]
                            },
                        ]),expression:"[\n                        'address_2',\n                            {\n                                initialValue: isLoggedIn ? user.address_2 : '',\n                                rules: [{ required: false }]\n                            },\n                        ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":_vm.t('Country')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'country_id',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.country.name : '226',
                                rules: [
                                    { required: true, message: _vm.t('Please select a country.') },
                                    { validator: _vm.checkCanadaOrUs }
                                ]
                            }
                        ]),expression:"[\n                            'country_id',\n                            {\n                                initialValue: isLoggedIn ? user.country.name : '226',\n                                rules: [\n                                    { required: true, message: t('Please select a country.') },\n                                    { validator: checkCanadaOrUs }\n                                ]\n                            }\n                        ]"}],attrs:{"show-search":"","placeholder":_vm.t('Select one'),"filter-option":_vm.filterOption,"option-filter-prop":"children","size":"large"},on:{"change":_vm.handleCountryChange}},_vm._l((_vm.countries),function(data){return _c('a-select-option',{key:data.key},[_vm._v(" "+_vm._s(data.value)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.t('State'),"data-label":"state_id"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'state_id',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.province.name : '',
                                rules: [
                                    {
                                        required: _vm.checkCountry(this.form.getFieldValue('country_id')),
                                        message: _vm.t('Please select a state/province.')
                                    }
                                ] 
                            }
                        ]),expression:"[\n                            'state_id',\n                            {\n                                initialValue: isLoggedIn ? user.province.name : '',\n                                rules: [\n                                    {\n                                        required: checkCountry(this.form.getFieldValue('country_id')),\n                                        message: t('Please select a state/province.')\n                                    }\n                                ] \n                            }\n                        ]"}],attrs:{"show-search":"","placeholder":"Select one","filter-option":_vm.filterOption,"option-filter-prop":"children","size":"large"}},_vm._l((_vm.states),function(data){return _c('a-select-option',{key:data.key},[_vm._v(" "+_vm._s(data.value)+" ")])}),1),(_vm.errors['state_id'])?_c('div',{staticStyle:{"color":"red","margin-top":"5px"}},[_vm._v(" "+_vm._s(_vm.errors['state_id'])+" ")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.t('State'),"data-label":"state"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'state_caption',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.province.name : '',
                                rules: [{ required: false }]
                            },
                        ]),expression:"[\n                            'state_caption',\n                            {\n                                initialValue: isLoggedIn ? user.province.name : '',\n                                rules: [{ required: false }]\n                            },\n                        ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":_vm.t('City')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'city',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.city : '',
                                rules: [{ required: true, message: _vm.t('Please enter your city.') }]
                            },
                        ]),expression:"[\n                        'city',\n                            {\n                                initialValue: isLoggedIn ? user.city : '',\n                                rules: [{ required: true, message: t('Please enter your city.') }]\n                            },\n                        ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":_vm.t('Zip Code'),"data-label":"zip_code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'zip_code',
                            {
                                initialValue: _vm.isLoggedIn ? _vm.user.postal_code : '',
                                rules: [{ required: true, message: _vm.t('Please enter your zip/postal code.') }]
                            },
                        ]),expression:"[\n                        'zip_code',\n                            {\n                                initialValue: isLoggedIn ? user.postal_code : '',\n                                rules: [{ required: true, message: t('Please enter your zip/postal code.') }]\n                            },\n                        ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('a-button',{staticClass:"contact-form-button",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formSubmitting,"loading":_vm.formSubmitting,"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" "+_vm._s(_vm.formSubmitting ? _vm.t('Submitting...') : _vm.t('Continue'))+" ")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }